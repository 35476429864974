import React, {useState} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import OwnerAccess from "./Components/OwnerAccess";
import {LayoutOne} from "./Components/Layout";
import OwnerAccessCode from "./Components/OwnerAccessCode";
import HostalListar from "./Components/Hostal/Listar";
import Dashboard from "./Components/Dashboard/Info";
import HostalPublicar from "./Components/Hostal/Publicar";
import HostalCrear from "./Components/Hostal/Crear";
import HostalHabitaciones from "./Components/Hostal/Habitaciones";
import AdicionarHabitacion from "./Components/Hostal/AdicionarHabitacion";
import EditarHabitacion from "./Components/Room/EditRoom";
import AdicionarDireccion from "./Components/Hostal/AdicionarDireccion";
import AdicionarUbicacion from "./Components/Hostal/Ubicacion";
import PhotosList from "./Components/Fotos/Listar";
import ReservasHostales from './Components/Reservas/Hostales';
import ReservasCalendario from './Components/Reservas/Calendario';
import ReservasListado from './Components/Reservas/Listado';
import ReservasDetalles from './Components/Reservas/Detalles';
import PreciosPropiedad from './Components/Precios/Propiedad'
import PreciosHabitacion from './Components/Precios/Habitacion'
import SettingsView from "./Components/Ajustes/ver";
import SettingsEdit from "./Components/Ajustes/editar";
import UserLogout from "./Components/Ajustes/Logout";
import Help from "./Components/Help";
import Contact from "./Components/Contact";
import LockUnlockRoom from "./Components/Reservas/Lock";
import EditarReservacion from "./Components/Reservas/Edit";
import CustomCalendar from "./Components/Reservas/Calendar/Calendar"
import Amenities from "./Components/Hostal/Amenities";
import Services from "./Components/Hostal/Services";
import EditHostalName from "./Components/Hostal/EditHostalName";
import EditHostalDescription from "./Components/Hostal/EditHostalDescription";
import EditarEstanciaMinima from "./Components/Hostal/EditEstanciaMinima";
import RoomSelection from "./Components/Importacion/RoomSelection";
import ImpExpCalendario from "./Components/Importacion/ImpExpCalendario";
import BackDoorLogin from "./Components/BackDoor/BackDoorLogin";

function App() {

    const [userData, setUserData] = useState(null)
    const [showNavBar, setShowNavBar] = useState(true)
    const [home, setHome] = useState('Inicio')

    function handleShow(status, component) {
        setShowNavBar(status)
        switch (component) {
            case 'OwnerAccessCode':
                return <OwnerAccessCode/>

            case 'Dashboard':
                return <Dashboard/>

            case 'HostalPublicar':
                return <HostalPublicar/>

            default:
                return <OwnerAccess/>
        }
    }

    function RouteWrapper({
                              component: Component,
                              layout: Layout,
                              title,
                              showNavBar,
                              ...rest
                          }) {
        return (
            <Route {...rest} render={(props) =>
                <Layout title={title} showNavBar={showNavBar} {...props}>
                    <Component {...props} />
                </Layout>
            }/>
        );
    }

    return (
        <React.StrictMode>
            <div className="container-height">
                <Router>
                    <RouteWrapper exact path="/" component={
                        () => {
                            const data = JSON.parse(window.localStorage.getItem('userData'))
                            if (data) {
                                if (!userData) {
                                    setHome('CubaHostal')
                                    setUserData(data)
                                }
                                const hostals = window.localStorage.getItem('hostalsByOwner')
                                switch (parseInt(hostals)) {
                                    case 0:
                                        return <HostalPublicar/>
                                    default:
                                        return <Dashboard/>
                                }
                            } else {
                                return handleShow(false, 'OwnerAccess')
                            }
                        }
                    } layout={LayoutOne} title={home} showNavBar={showNavBar}/>

                    <Route exact path="/codigo" component={() => {
                        return handleShow(false, 'OwnerAccessCode')
                    }}/>

                    <RouteWrapper exact path="/dashboard" component={() => {
                        return handleShow(true, 'Dashboard')
                    }} layout={LayoutOne} title="CubaHostal" showNavBar={showNavBar}/>

                    <RouteWrapper exact path="/hostal/publicar" component={() => {
                        return handleShow(true, 'HostalPublicar')
                    }} layout={LayoutOne} title="CubaHostal" showNavBar={showNavBar}/>

                    <Route exact path="/hostal/listar" component={HostalListar}/>
                    <Route exact path="/hostal/crear" component={HostalCrear}/>
                    <Route exact path="/hostal/habitaciones" component={HostalHabitaciones}/>
                    <Route exact path="/hostal/habitacion/adicionar" component={AdicionarHabitacion}/>
                    <Route exact path="/hostal/habitacion/editar" component={EditarHabitacion}/>
                    <Route exact path="/hostal/direccion/adicionar" component={AdicionarDireccion}/>
                    <Route exact path="/hostal/ubicacion" component={AdicionarUbicacion}/>
                    <Route exact path="/hostal/fotos/listar" component={PhotosList}/>
                    <Route exact path="/hostal/amenities" component={Amenities}/>
                    <Route exact path="/hostal/services" component={Services}/>
                    <Route exact path="/hostal/update/name" component={EditHostalName}/>
                    <Route exact path="/hostal/update/description" component={EditHostalDescription}/>
                    <Route exact path="/hostal/update/minimum/stay" component={EditarEstanciaMinima}/>
                    <Route exact path="/reservas/hostales" component={ReservasHostales}/>
                    <Route exact path="/reservas/calendario" component={ReservasCalendario}/>
                    <Route exact path="/reservas/listado" component={ReservasListado}/>
                    <Route exact path="/reservas/detalles" component={ReservasDetalles}/>
                    <Route exact path="/precios/propiedad" component={PreciosPropiedad}/>
                    <Route exact path="/precios/habitacion" component={PreciosHabitacion}/>
                    {/* Start calendar */}
                    <Route exact path="/reservas/custom/calendar" component={CustomCalendar}/>
                    <Route exact path="/reservas/habitacion/bloquear" component={LockUnlockRoom}/>
                    <Route exact path="/reservas/editar" component={EditarReservacion}/>
                    {/* End calendar */}
                    <Route exact path="/ajustes" component={SettingsView}/>
                    <Route exact path="/ajustes/editar" component={SettingsEdit}/>
                    <Route exact path="/ayuda" component={Help}/>
                    <Route exact path="/contacto" component={Contact}/>
                    <Route exact path="/logout" component={UserLogout}/>
                    <Route exact path="/import/selection" component={RoomSelection}/>
                    <Route exact path="/external/calendar/import" component={ImpExpCalendario}/>
                    <Route exaxt path="/backdoor/login" component={BackDoorLogin}/>

                </Router>
            </div>
        </React.StrictMode>
    );
}

export default App;
