import React from "react"
import ContentLoader from "react-content-loader"

const PhotoSettingsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={150}
    height={100}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="75" cy="50" r="50" />
  </ContentLoader>
)

export default PhotoSettingsLoader

