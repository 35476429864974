import React, {useState, useEffect, useContext} from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import {useHistory} from "react-router-dom";
import {ThemeContext} from "../../State/theme-context";
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
// import * as Icon from "react-bootstrap-icons";
import CommonTitle from "../Utils/CommonTitle";
import LoaderLine from '../../Components/Loaders/Line';

function Crear() {

    const apiUri = useContext(ApiContext)
    const [types, setTypes] = useState(null);
    const [hostalTitle, setHostalTitle] = useState('');
    const [hostalRoomType, setHostalRoomType] = useState(null);
    const [hostalMinimunStay, setHostalMinimunStay] = useState({value: 1, label: '1 día'});
    const [hostalPriceNight, setHostalPriceNight] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [showPrice, setShowPrice] = useState(false)

    let history = useHistory();
    let customStyles = useContext(ThemeContext);

    useEffect(() => {
            window.scrollTo(0, 0)
            // fetch('http://localhost:8008/amortizacion/list/hostal/types')
            fetch(apiUri.functions.listHostalTypes)
                .then(response => response.json())
                .then((data) => {
                    setTypes(data.hostalTypes);
                    setHostalRoomType(data.hostalTypes[0].id);
                });
        },
        []
    );

    function handleRoomType(obj) {
        setShowPrice(!showPrice)
        setHostalRoomType(obj.target.value)
    }

    function handleHostalTitle(obj) {
        setHostalTitle(obj.target.value)
    }

    function handlePriceNight(obj) {
        setHostalPriceNight(obj.target.value)
    }

    function handleMinimumStay(obj) {
        setHostalMinimunStay({value: obj.value, label: obj.label})
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    function handleForm(event) {
        event.preventDefault();
        let isError = false;
        if (hostalTitle.length === 0) {
            isError = true
            setErrorMessage('Debe especificar el nombre del hostal')
            setVariant('warning')
            showAlert()
        } else if (parseInt(hostalRoomType) === 1 && hostalPriceNight.length === 0) {
            isError = true
            setErrorMessage('Debe especificar el precio')
            setVariant('warning')
            showAlert()
        }
        if (!isError) {
            let createHostalRoute = apiUri.functions.ownerCreateHostal(
                userData.owner_id,
                userData.owner_hash,
                hostalTitle,
                hostalMinimunStay.value,
                parseInt(hostalRoomType) === 1
                    ? '1&price=' + hostalPriceNight
                    : 0
            )

            fetch(createHostalRoute)
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        window.localStorage.setItem('hostalsByOwner', parseInt(window.localStorage.getItem('hostalsByOwner')) + 1)

                        history.push(`/hostal/direccion/adicionar`, {
                            hostal_id: data.hostalId,
                            hostal_name: hostalTitle,
                            hostal_type: parseInt(hostalRoomType) === 1 ? 1 : 0
                        })
                    } else {
                        setErrorMessage('Ha ocurrido un error al crear el hostal, intente otra vez')
                        setVariant('danger')
                        showAlert()
                    }
                }, (error) => {
                    console.error('Something bad is happend: ', error);
                    setErrorMessage('Ha ocurrido un error inesperado: ' + error)
                    setVariant('danger')
                    showAlert()
                })
        }
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.push('/hostal/listar')}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold">Crear Hostal</h5>
                </div>
                <div>
                    <Form onSubmit={handleForm}>
                        <Form.Group controlId="formLodging">
                            <Form.Label>Tipo de alojamiento</Form.Label>
                            {types === null
                                ? <LoaderLine/>
                                : types.map(({id, name}, i) => {
                                    return (
                                        <Form.Check defaultChecked={
                                            i === 0
                                        } value={id} type="radio"
                                                    onChange={e => handleRoomType(e)}
                                                    className="custom_radio" id={"types_" + id}
                                                    label={name} name="hostal_types"/>
                                    )
                                })
                            }
                        </Form.Group>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Nombre del hostal</Form.Label>
                            <Form.Control onChange={handleHostalTitle} value={hostalTitle} type="text"/>
                        </Form.Group>
                        <Form.Group controlId="formStay">
                            <Form.Label>Estancia mínima</Form.Label>
                            <Select
                                isSearchable={false}
                                styles={customStyles.react_select_style.border_left}
                                value={hostalMinimunStay}
                                onChange={handleMinimumStay}
                                options={
                                    Array.from(Array(10), (e, i) => {
                                        return {
                                            value: i + 1,
                                            label: (i + 1) + ' día' + (i > 0 ? 's' : '')
                                        }
                                    })
                                }
                            />
                        </Form.Group>
                        {showPrice ?
                            <Form.Group controlId="formPrice">
                                <Form.Label>Precio por noche</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control onChange={handlePriceNight} value={hostalPriceNight} type="number"
                                        onWheel={event => event.currentTarget.blur()}/>
                                </InputGroup>
                            </Form.Group>
                            : null}
                        <div className="row justify-content-center">
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row justify-content-center">
                            {/*<Col style={{marginBottom: 70}}> </Col>*/}
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </LayoutOne>
    );
}

export default Crear;