import React, {useContext, useEffect, useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Col from 'react-bootstrap/Col';
import * as Icon from "react-bootstrap-icons";
import {useHistory, useLocation} from "react-router-dom";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import moment from "moment"
import 'moment/locale/es'
import {ApiContext} from "../../State/api-context";
import {timeInHostal, roomsInReservation, undefinedState} from "../Utils/Utils"
import iconHome from "../../Assets/images/icon_home.png";

function EditReservation() {
    let history = useHistory()
    const [headerTitle, setHeaderTitle] = useState(null)
    const [daysSelected, setDaysSelected] = useState(null)
    const [reservations, setReservations] = useState(null)
    const {state} = useLocation()
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [hostalName, setHostalName] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        const selection = JSON.parse(state.dates_selected)
        setDaysSelected(selection)
        setHostalName(state.hostal_name)
        const titleText = `${selection.length} día${selection.length > 1 ? 's' : ''} seleccionado${selection.length > 1 ? 's' : ''}`
        setHeaderTitle(
            <CommonTitle goTo={() => history.go(-1)} title={titleText}/>
        )
        getReservations(state.hostal_id)
    }, [])

    const getReservations = (id) => {
        fetch(apiUri.functions.ownerListReservations(userData.owner_id, userData.owner_hash, '', `&hostalId=${id}`))
            // fetch('http://127.0.0.1:8008/amortizacion/reservations/json')
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setReservations(data.reservations)
                    } else {
                        setReservations([])
                    }
                }
            }, (error) => {

            })
    }

    const printReservations = () => {
        const reservationsInRange = []
        reservations.map(reservation => {
            if (!reservation.daysBlocked) {
                daysSelected.map(selection => {
                    if (moment(selection).isBetween(moment(reservation.checkinDate, 'Y-M-D'), moment(reservation.ckeckoutDate, 'Y-M-D'), null, '[)')) {
                        if (!reservationsInRange.find(inst => {
                            return inst.id === reservation.id
                        })) {
                            reservationsInRange.push(reservation)
                        }
                    }
                })
            }
        })
        return reservationsInRange.length > 0 ? reservationsInRange.map(reservation => {
            return <ListGroup.Item className="wideList">
                <div>
                    <Col xs={{span: 12}} style={{marginBottom: 7}}>
                        <Icon.Calendar2Week size={20}/> {timeInHostal(reservation)}
                    </Col>
                    <Col xs={{span: 12}} style={{marginBottom: 7}}>
                        {roomsInReservation(reservation, true)}
                    </Col>
                    <Col xs={{span: 12}} style={{marginBottom: 7}}>
                        <img src={iconHome} width={17} className=""
                             alt="Icon Hostal"/> <span>{hostalName ? hostalName : 'Cargando datos...'}</span>
                    </Col>
                    {/*<Col xs={{span: 12}}>*/}
                    {/*    <li className="list-unstyled text-info">Editar reserva</li>*/}
                    {/*</Col>*/}
                </div>
            </ListGroup.Item>
        }) : <ListGroup.Item className="wideList"><span className="text-muted">No existen reservas en los días seleccionados</span></ListGroup.Item>
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} title={headerTitle} bG={false}>
            <div className="container-fluid">
                <div className="pt-2">
                    <h5><strong>Reservas</strong></h5>
                </div>
                <ListGroup variant="flush">
                    {
                        reservations
                            ? printReservations()
                            : 'Cargando datos...'
                    }
                </ListGroup>
            </div>
        </LayoutOne>
    )
}

export default EditReservation