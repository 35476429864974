import React, {useContext, useEffect, useState} from "react";
import {ApiContext} from "../../State/api-context";
import * as Icon from "react-bootstrap-icons";

function ColorsFromHostalRooms({hostal}) {
    const [rooms, setRooms] = useState(null)
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    useEffect(() => {
        fetch(apiUri.functions.ownerListRooms(userData.owner_id, userData.owner_hash, hostal))
            .then((resp) => resp.json())
            .then((data) => {
                !data.error && data.count > 0 ? setRooms(data.rooms) : setRooms([])
            }, (error) => {

            })
    }, [])

    return rooms ? rooms.map(({color, isSuite, name}) => {
        return <li className="list-unstyled list-inline-item ml-2 mr-3 mb-2">
            <Icon.DoorOpenFill color={color ? color : 'currentColor'} size={20}
                               className="mr-1"/>
            {name + (parseInt(isSuite) === 1
                ? ' (Suite)'
                : '')}
        </li>
    }) : 'Cargando datos...'
}

export default ColorsFromHostalRooms