import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import CommonTitle from "../Utils/CommonTitle";
import {LayoutOne} from "../Layout";
import {ListGroup, Col, Row, Form, Button, Alert} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import {undefinedState} from "../Utils/Utils";
import {ApiContext} from "../../State/api-context";
import Loader from "../Loaders/Line"

function ImpExpCalendario() {
    const {state} = useLocation();
    const [room, setRoom] = useState(null)
    const [hostal, setHostal] = useState(null)
    let history = useHistory()
    const [toImport, setToImport] = useState([])
    const [url, setURL] = useState(null)
    const [textToClipboard, setTextToClipboard] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [show, setShow] = useState(false)
    const [variant, setVariant] = useState('warning')
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostal({id: state.hostal_id, tittle: state.hostal_name})
        const tmpRoom = JSON.parse(state.room)
        setRoom(tmpRoom)
        getCalendars(state.hostal_id, tmpRoom ? `&roomId=${tmpRoom.id}` : '')
    }, [])

    const getCalendars = (hostalId, roomId) => {
        fetch(apiUri.functions.ownerListIcalendars(userData.owner_id, userData.owner_hash, hostalId, roomId))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    setTextToClipboard(data.myIcalendarURL)
                    data.iCalendar ? setToImport(data.iCalendar) : setToImport([])
                }
            }, (error) => {

            })
    }

    const showAlert = () => {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    const setNewCalendarURL = () => {
        if (url === null || url.length < 1) {
            setErrorMessage('Debe insertar una URL')
            setVariant('warning')
            showAlert()
        } else {
            fetch(apiUri.functions.ownerInsertIcalendar(userData.owner_id, userData.owner_hash, hostal.id, room ? `&roomId=${room.id}` : '', url))
                .then((resp) => resp.json())
                .then((data) => {
                    if (!data.error) {
                        setURL('')
                        getCalendars(hostal.id, room ? `&roomId=${room.id}` : '')
                    }
                }, (error) => {

                })
        }
    }

    const removeURL = calendarId => {
        fetch(apiUri.functions.ownerDeleteIcalendar(userData.owner_id, userData.owner_hash, calendarId))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    getCalendars(hostal.id, room ? `&roomId=${room.id}` : '')
                }
            }, (error) => {

            })
    }

    const copyToClipboard = () => {
        const el = document.createElement('textarea')
        el.value = textToClipboard
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        setCopied(true)
    }

    return (
        <LayoutOne showIcon={false} showCopy={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)}
                                    title={hostal === null ? 'Cargando hostal...' : hostal.tittle}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <Row>
                        <Col className='my-2' xs={{span: 12}}>
                            <h5 className="font-weight-bold">Calendarios externos</h5>
                        </Col>
                        {
                            room !== null
                                ? (room !== false
                                ? <Col xs={{span: 12}} className="my-2">
                                    <Icon.DoorOpenFill className="mt-n2 mr-2" size={20} color={room.color}/>
                                    <strong>{room.name + (parseInt(room.isSuite) === 1 ? ' (Suite)' : '')}</strong>
                                </Col>
                                : '')
                                : ''
                        }
                        <Col xs={{span: 12}}>
                            <hr/>
                            <h5><strong>Exportar</strong></h5>
                            <label className="text-muted">Copia y pega el siguiente link en otras plataformas como
                                Airbnb para exportar tu
                                calendario de Cubahostal.</label>
                            <div className="py-3 px-3 my-3" style={{border: '1px solid grey', borderRadius: 5}}>
                                <Row>
                                    <Col className="text-info text-left mb-3 text-truncate" xs={{span: 12}}>
                                        {textToClipboard ? <a style={{color:'#0076CC'}} target="_blank" href={textToClipboard}>{textToClipboard}</a> : <Loader/>}
                                    </Col>
                                    <Col style={{cursor: "pointer"}} onClick={copyToClipboard}
                                         xs={{span: 6, offset: 6}}>
                                        <div style={copied ? {
                                            backgroundColor: 'grey',
                                            borderRadius: 25,
                                            color: 'white'
                                        } : null}
                                             className="text-center p-2">
                                            <Icon.Files className="mr-1"
                                                        size={20}/>{copied ? 'Enlace copiado' : 'Copiar enlace'}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={{span: 12}}>
                            <hr/>
                            <h5><strong>Importar</strong></h5>
                            <label className="text-muted">A&ntilde;ade calendarios de otras plataformas como Airbnb para
                                importar a tu
                                calendario de Cubahostal.</label>
                        </Col>
                        {
                            toImport.length > 0
                                ? toImport.map(({id, url}, i) => {
                                    return <>
                                        <Col xs={{span: 10}}>
                                            <li className="list-unstyled mt-2 text-truncate">{url}</li>
                                        </Col>
                                        <Col className="mt-2" xs={{span: 2}}>
                                            <Icon.Trash style={{cursor: 'pointer'}} onClick={() => removeURL(id)} size={18}
                                                        className="text-danger"/>
                                        </Col>
                                        <Col xs={{span: 12}}>
                                            <hr/>
                                        </Col>
                                    </>
                                })
                                : ''
                        }
                        <Col xs={{span: 12}} className="mt-2">
                            <Form.Group>
                                <Form.Label><span>Escribe o pega la URL del calendario a importar</span></Form.Label>
                                <Form.Control value={url} type="text" onChange={e => setURL(e.target.value)}/>
                            </Form.Group>
                            <div className="row fixed-bottom justify-content-center" style={{bottom: 5 + 'rem'}}>
                                <Alert variant={variant} show={show}>
                                    {errorMessage}
                                </Alert>
                            </div>
                            <div className="row justify-content-center pb-4">
                                <Button variant="primary" onClick={setNewCalendarURL}>
                                    A&ntilde;adir calendario
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ListGroup>
            </div>
        </LayoutOne>
    )
}

export default ImpExpCalendario