import React, {useContext, useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FlagIcon from './FlagIcon.js';
import Select from 'react-select';
import {useHistory} from "react-router-dom";
import {ThemeContext} from '../State/theme-context'
import {ApiContext} from "../State/api-context";
import imageHome from "../Assets/images/home.png"
import Loader from "./Loaders/Line";

function OwnerAccess() {

    let history = useHistory();

    const customStyles = useContext(ThemeContext);
    const apiUri = useContext(ApiContext)
    const [countryPhoneCodes, setCountryPhoneCodes] = useState(null);
    const [phoneCode, setPhoneCode] = useState('+53');
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('cu');
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');

    useEffect(
        () => {
            fetch(apiUri.functions.listCountryPhoneCodes)
                // fetch('http://localhost:8008/amortizacion/country/phone/code')
                .then(response => response.json())
                .then((data) => {
                    setCountryPhoneCodes(data.country);
                });
        },
        []
    );

    function selectOptionsBuilder(data) {
        let options = [
            {
                value: '+53',
                label: 'Cuba',
                code: 'cu'
            },
            {
                value: '+1',
                label: 'Estados Unidos',
                code: 'us',
                className: 'second-child'
            }]
        data.map(item => (
            item.code.toLowerCase() !== 'an' ?
                options.push(
                    {
                        value: item.phoneCode,
                        label: item.name,
                        code: item.code.toLowerCase()
                    }
                ) : ''
        ))
        return (
            <InputGroup>
                <InputGroup.Prepend className="custom_width_owner_access">
                    <InputGroup.Text><FlagIcon code={countryCode}/></InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                    styles={customStyles.react_select_style.no_border_left}
                    className="custom_select"
                    placeholder="Seleccione su pa&iacute;s..."
                    // isClearable={true}
                    value={options.find(op => {
                        return op.value.toString() === phoneCode.toString() && op.code.toLowerCase() === countryCode.toLowerCase()
                    })}
                    onChange={setIndividualData} options={options}/>
            </InputGroup>
        );
    }

    function setIndividualData(obj) {
        setPhoneCode(obj.value);
        setCountryCode(obj.code);
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    function handleSubmit(event) {
        event.preventDefault();
        let isError = false;
        if (phone.length === 0) {
            isError = true
            setErrorMessage('Debe insertar su número telefónico')
            setVariant('warning')
            showAlert()
        } else if (countryCode === 'cu') {
            if (phone.length !== 8) {
                isError = true
                setErrorMessage('Por favor, corrija su número, debe tener 8 dígitos')
                setVariant('warning')
                showAlert()
            } else {
                if (parseInt(phone.slice(0, 1)) !== 5) {
                    isError = true
                    setErrorMessage('Por favor, corrija su número, debe empezar con 5')
                    setVariant('warning')
                    showAlert()
                }
            }
        }
        if (!isError) {
            let validatePhoneRoute = apiUri.functions.ownerAccess(
                phoneCode.split('+')[1],
                phone
            )
            fetch(validatePhoneRoute, {mode: "cors"})
                .then((response) => response.json())
                .then(
                    (result) => {
                        if (!result.error) {
                            history.push(`/codigo`, {
                                phone: phone,
                                phoneCode: phoneCode,
                                countryCode: countryCode
                            })
                        } else {
                            setErrorMessage('Por favor, corrija su número')
                            setVariant('warning')
                            showAlert()
                        }
                    },
                    (error) => {
                        console.error('Something bad is happend: ', error);
                        setErrorMessage('Ha ocurrido un error inesperado: ' + error)
                        setVariant('danger')
                        showAlert()
                    }
                );
        }
    }

    function handlePhoneChange(obj) {
        setPhone(obj.target.value);
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <h6 className="text-muted  text-center px-5 pt-2">Recibe reservas de todas partes del mundo sin pagar
                    comisiones.</h6>
                <div className="py-4 col-12 text-center">
                    <img
                        src={imageHome}
                        width="150"
                        height="130"
                        alt="Card Bookmark"/>
                </div>

                <h5 className="font-weight-bold">Regístrate o accede</h5>
            </div>
            <div>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formCountry" id="countryCode">
                        <Form.Label>País:</Form.Label>
                        {
                            countryPhoneCodes === null
                                ? <Loader style={{width: 100 + "%"}}/>
                                : selectOptionsBuilder(countryPhoneCodes)
                        }
                    </Form.Group>

                    <Form.Group controlId="formPhone">
                        <Form.Label>Tel&eacute;fono:</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend className="custom_width_owner_access">
                                <InputGroup.Text>{phoneCode}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={phone} type="tel" onChange={handlePhoneChange}/>
                        </InputGroup>
                    </Form.Group>
                    <div className="row justify-content-center">
                        <Alert variant={variant} show={show}>
                            {errorMessage}
                        </Alert>
                    </div>
                    <div className="row justify-content-center">
                        <Button variant="primary" type="submit" size="lg">
                            Continuar
                        </Button>
                    </div>

                </Form>
            </div>
        </div>
    );
}

export default OwnerAccess;