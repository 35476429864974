import React from "react"
import moment from 'moment'
import 'moment/locale/es'

function DayNames() {
    return (
        <div className="row day-names">
            {
                moment.weekdaysMin().map((day, i) => {
                    return <span key={i} className="day">{day}</span>
                })
            }
        </div>
    )
}

export default DayNames