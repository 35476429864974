import React from "react"
import {Row, Col} from "react-bootstrap"

class Day extends React.Component {
    render() {
        const {
            day,
            day: {
                date,
                isCurrentMonth,
                isToday,
                number
            },
            select,
            selected,
            reservations,
            dayStyle
        } = this.props;

        const reservationDays = (date) => {
            const columns = []

            reservations.map((rsrvtn) => {
                const results = rsrvtn.days.filter(inst => date.format('Y-M-D') === inst.split('_')[0])
                if (results.length > 0) {
                    results.map(state => {
                        if (state.indexOf('external') !== -1) {
                            if (state.indexOf('start') !== -1 || state.indexOf('end') !== -1) {
                                if (state.indexOf('start') !== -1) {
                                    columns.push(
                                        <Col className="p-0" hostal={rsrvtn.hostal}
                                             room={rsrvtn.room ? rsrvtn.room : null}
                                             xs={{span: 6, offset: results.length > 1 ? 3 : 6}}
                                             sm={{span: 6, offset: results.length > 1 ? 3 : 6}}
                                             style={{
                                                 height: 10,
                                                 backgroundColor: `${rsrvtn.color}`,
                                                 marginBottom: 6,
                                                 borderRadius: 25,
                                                 boxShadow: '1.5px 0px 0px 0px ' + `${rsrvtn.color}`,
                                                 marginTop: 0
                                             }}
                                        ><span
                                            style={{
                                                width: 100 + '%',
                                                transform: 'translate(-50%, -50%)',
                                                top: 5.2,
                                                position: 'absolute',
                                                fontSize: 9,
                                                textAlign: 'center'
                                            }}
                                            className="text-white">
                                            {state.split('external_')[1]}
                                        </span>
                                        </Col>
                                    )
                                }
                                if (state.indexOf('end') !== -1) {
                                    columns.push(
                                        <Col className="p-0 mt-1" hostal={rsrvtn.hostal}
                                             room={rsrvtn.room ? rsrvtn.room : null}
                                             xs={{span: 3}}
                                             style={{
                                                 height: '2px',
                                                 backgroundColor: `${rsrvtn.color}`,
                                                 marginBottom: 10,
                                                 boxShadow: '-1.5px 0px 0px 0px ' + `${rsrvtn.color}`,
                                                 borderTopRightRadius: 10,
                                                 borderBottomRightRadius: 10
                                             }}
                                        />
                                    )
                                    if (results.length === 1) {
                                        columns.push(
                                            <Col className="p-0 mt-1" sm={{span: 4, offset: 5}}
                                                 xs={{span: 4, offset: 5}}
                                                 style={{
                                                     height: '2px',
                                                     marginBottom: 10,
                                                     opacity: 0
                                                 }}
                                            />
                                        )
                                    }
                                }
                            } else
                                columns.push(
                                    <Col className='mt-1' hostal={rsrvtn.hostal} room={rsrvtn.room ? rsrvtn.room : null}
                                         xs={{span: 12}}
                                         style={{
                                             height: '2px',
                                             backgroundColor: `${rsrvtn.color}`,
                                             marginBottom: 10,
                                             boxShadow: '1.5px 0px 0px 0px ' + `${rsrvtn.color}` + ', -1.5px 0px 0px 0px' + `${rsrvtn.color}`,
                                         }}/>
                                )
                        } else {
                            if (state.indexOf('start') !== -1 || state.indexOf('end') !== -1 || state.indexOf('blocked') !== -1) {
                                if (state.indexOf('blocked') !== -1) {
                                    columns.push(
                                        <Col className="p-0" hostal={rsrvtn.hostal}
                                             room={rsrvtn.room ? rsrvtn.room : null}
                                             xs={{span: 6, offset: results.length > 1 ? 3 : 6}}
                                             sm={{span: 6, offset: results.length > 1 ? 3 : 6}}
                                             style={{
                                                 height: '10px',
                                                 border: `1.5px solid black`,
                                                 backgroundColor: `${rsrvtn.color}`,
                                                 marginBottom: 5,
                                                 borderTopLeftRadius: 5,
                                                 borderBottomLeftRadius: 5,
                                             }}
                                        >
                                            <div className="blocked-day-line"/>
                                        </Col>
                                    )
                                } else {
                                    if (state.indexOf('start') !== -1) {
                                        columns.push(
                                            <Col className="p-0" hostal={rsrvtn.hostal}
                                                 room={rsrvtn.room ? rsrvtn.room : null}
                                                 xs={{span: 6, offset: results.length > 1 ? 3 : 6}}
                                                 sm={{span: 6, offset: results.length > 1 ? 3 : 6}}
                                                 style={{
                                                     height: '10px',
                                                     backgroundColor: `${rsrvtn.color}`,
                                                     marginBottom: 5,
                                                     borderTopLeftRadius: 5,
                                                     borderBottomLeftRadius: 5,
                                                     boxShadow: '1.5px 0px 0px 0px ' + `${rsrvtn.color}`,
                                                 }}
                                            />
                                        )
                                    }
                                    if (state.indexOf('end') !== -1) {
                                        columns.push(
                                            <Col className="p-0" hostal={rsrvtn.hostal}
                                                 room={rsrvtn.room ? rsrvtn.room : null}
                                                 xs={{span: 3}}
                                                 style={{
                                                     height: '10px',
                                                     backgroundColor: `${rsrvtn.color}`,
                                                     marginBottom: 5,
                                                     borderTopRightRadius: 5,
                                                     borderBottomRightRadius: 5,
                                                     boxShadow: '-1.5px 0px 0px 0px ' + `${rsrvtn.color}`,
                                                 }}
                                            />
                                        )
                                        if (results.length === 1) {
                                            columns.push(
                                                <Col className="p-0" sm={{span: 4, offset: 5}} xs={{span: 4, offset: 5}}
                                                     style={{
                                                         height: '10px',
                                                         marginBottom: 5,
                                                         opacity: 0
                                                     }}
                                                />
                                            )
                                        }
                                    }
                                }
                            } else
                                columns.push(
                                    <Col hostal={rsrvtn.hostal} room={rsrvtn.room ? rsrvtn.room : null} xs={{span: 12}}
                                         style={{
                                             height: '10px',
                                             backgroundColor: `${rsrvtn.color}`,
                                             marginBottom: 5,
                                             boxShadow: '1.5px 0px 0px 0px ' + `${rsrvtn.color}` + ', -1.5px 0px 0px 0px' + `${rsrvtn.color}`,
                                         }}/>
                                )
                        }
                    })
                } else {
                    columns.push(
                        <Col xs={{span: 12}} style={{
                            height: '10px', marginBottom: 5, opacity: 0
                        }}/>
                    )
                }
            })

            return (
                <Row style={{width: 100 + '%'}}>
                    <Col xs={{span: 12}} className="month-day-number" style={{
                        right: 30 + '%', bottom: 5 + 'px'
                    }}><span>{number}</span></Col>
                    {columns}
                </Row>
            )
        }

        return (
            <div style={dayStyle}
                 key={date.toString()}
                 className={"border day" + (isToday ? " today" : "") + (isCurrentMonth ? "" : " different-month") + (
                     selected.find(obj => {
                         return date.isSame(obj)
                     })
                         ? " selected"
                         : ""
                 )}
                 onClick={() => select(day)}>
                {
                    reservationDays(date)
                }
            </div>
        )
    }
}

export default Day