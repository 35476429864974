import React, {Component} from 'react';
import {Map, Marker, InfoWindow, GoogleApiWrapper} from 'google-maps-react';
import {Row, Form, Alert, Button} from "react-bootstrap";
import {ApiContext} from "../../State/api-context";
import Loader from '../../Components/Loaders/Photo'
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState} from "../Utils/Utils";

const mapStyles = {
    width: '100%',
};

export class MapContainer extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    constructor(props) {
        super(props);
        undefinedState(props.location.state)
        this.state = {
            activeMarker: {},
            showingInfoWindow: false,
            hostalName: props.location.state.hostal_name,
            hostalId: props.location.state.hostal_id,
            hostalType: props.location.state.hostal_type,
            isEdit: !!props.location.state.is_edit,
            locateIn: JSON.parse(props.location.state.locateIn),
            variant: 'warning',
            show: false,
            errorMessage: '',
            markers: [
                {
                    name: props.location.state.hostal_name,
                    title: props.location.state.hostal_name,
                }
            ]
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.onMapClicked = this.onMapClicked.bind(this);
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (t, map, coord) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
        const {latLng} = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const z = latLng.z

        this.state.locateIn.lat = lat
        this.state.locateIn.lng = lng
        this.state.locateIn.z = 14

        this.setState({
            markers: [
                {
                    title: this.state.hostalName,
                    name: this.state.hostalName,
                    position: {lat, lng, z}
                }
            ]
        });
    };

    handleSubmit(event) {
        event.preventDefault()
        const apiUri = this.context
        const userData = JSON.parse(window.localStorage.getItem('userData'))
        const hostalMapLocation = apiUri.functions.updateHostalMap(
            userData.owner_id,
            userData.owner_hash,
            this.state.hostalId,
            this.state.locateIn.lat,
            this.state.locateIn.lng,
        )
        fetch(hostalMapLocation, {mode: "cors"})
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    fetch(apiUri.functions.ownerListRooms(userData.owner_id, userData.owner_hash, this.state.hostalId))
                        .then((response) => response.json())
                        .then((data) => {
                            if (!data.error) {
                                if (data.count > 0) {
                                    fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${this.state.hostalId}`))
                                        .then((response) => response.json())
                                        .then((data) => {
                                            if (!data.error && data.count > 0) {
                                                const _hostal_ = data.hostals[0]
                                                this.props.history.push(`/hostal/habitaciones`, {
                                                    hostal: JSON.stringify(_hostal_)
                                                })
                                            }
                                        }, (error) => {

                                        })
                                } else {
                                    this.props.history.push(`/hostal/habitacion/adicionar`, {
                                        hostal_id: this.state.hostalId,
                                        hostal_name: this.state.hostalName,
                                        hostal_type: this.state.hostalType,
                                        is_edit: this.state.isEdit
                                    })
                                }
                            }
                        }, (error) => {

                        })
                } else {
                    console.log('error')
                }

            }, (error) => {

            })
    }

    render() {
        return (
            <LayoutOne showNavBar={false} bG={false} showIcon={false} showCopy={false} title={
                this.state.isEdit
                    ? <CommonTitle goTo={() => this.props.history.go(-1)} title={this.state.hostalName}/>
                    : <CommonTitle goTo={() => this.props.history.push('/')} title={this.state.hostalName}/>
            }>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <h5 className="font-weight-bold">{this.state.isEdit ? 'Editar ubicación ' : 'Ubicar '}en el
                            mapa</h5>
                    </div>
                    <p className="text-muted">Mueve el mapa hasta lograr ubicar tu hostal en el marcador, puedes acercar
                        y alejar el mapa.</p>

                    <Row className="map-wrapper">
                        <Form onSubmit={this.handleSubmit}>
                            <Map google={this.props.google}
                                 className="map-selector"
                                 onClick={this.onMapClicked}
                                 style={{maxWidth: 600, width: '100%', height: 'calc(100vh - 70px)'}}
                                 streetViewControl={false}
                                 options={{zoomControlOptions: {position: 7}}}
                                 mapTypeControl={true}
                                 gestureHandling="greedy" //"cooperative", "auto", "none"
                                 mapType={this.props.google.maps.MapTypeId.HYBRID}
                                 fullscreenControl={false}
                                 initialCenter={
                                     {
                                         lat: this.state.locateIn.lat,
                                         lng: this.state.locateIn.lng
                                     }
                                 }>
                                {this.state.markers.map((marker, index) => (
                                    <Marker onClick={this.onMarkerClick}
                                            style={mapStyles}
                                            key={index}
                                        // icon={blackIconMarker}
                                            title={marker.title}
                                            name={marker.name}
                                            position={marker.position}
                                            initialCenter={
                                                {
                                                    lat: this.state.locateIn.lat,
                                                    lng: this.state.locateIn.lng
                                                }
                                            }
                                    />

                                ))}

                                <InfoWindow
                                    // google={this.props.google}
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}>
                                    <div>
                                        {this.state.markers.map((marker) => (
                                            <h6>{marker.name}</h6>
                                        ))}
                                    </div>
                                </InfoWindow>
                            </Map>
                            <div className="row fixed-bottom justify-content-center" style={{bottom: 5 + 'rem'}}>
                                <Alert variant={this.state.variant} show={this.state.show}>
                                    {this.state.errorMessage}
                                </Alert>
                            </div>
                            <div className="submit-button-align">
                                <Button variant="primary" type="submit" size="lg">
                                    Guardar
                                </Button>
                            </div>
                        </Form>
                    </Row>
                </div>
            </LayoutOne>
        )
    }
}

MapContainer.contextType = ApiContext

const LoadingContainer = () => {
    return <LayoutOne showIcon={false} bG={false} showNavBar={false}>
        <div className="container-fluid"><span
            className="text-muted">Cargando información del mapa...</span><br/><Loader
            style={{height: 300}}/></div>
    </LayoutOne>
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDIuBvChNdRHaY3pxTEcwwDQM_98pPK4-g',
    LoadingContainer: LoadingContainer
})(MapContainer);