import React, {useContext, useEffect, useState} from 'react';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import FlagIcon from './FlagIcon.js';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ApiContext} from "../State/api-context";
import {LayoutOne} from "./Layout";
import CommonTitle from "./Utils/CommonTitle";
import {undefinedState} from "./Utils/Utils";

function OwnerAccess() {

    const apiUri = useContext(ApiContext)
    const [phoneCode, setPhoneCode] = useState(null);
    const [phone, setPhone] = useState(null);
    const [countryCode, setCountryCode] = useState('cu');
    const [show, setShow] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [variant, setVariant] = useState('warning');
    const {state} = useLocation();

    let history = useHistory();

    useEffect(() => {
        undefinedState(state)
        setPhone(state.phone)
        setPhoneCode(state.phoneCode)
        setCountryCode(state.countryCode)
    }, [state])

    function handleSubmit(event) {
        event.preventDefault();
        if (accessCode.length === 0) {
            setErrorMessage('Debe insertar el código de acceso recibido a través de SMS')
            setVariant('warning')
            showAlert()
        } else {
            let ownerVerifyRoute = apiUri.functions.ownerVerifyCode(
                phoneCode.split('+')[1],
                phone,
                accessCode
            )
            // let ownerVerifyRoute = 'http://localhost:8008/amortizacion/verify/code/owner/c/__countryCode__/p/__phone__/a/__accessCode__';
            // ownerVerifyRoute = ownerVerifyRoute
            //     .replace(/__countryCode__/g, phoneCode.split('+')[1])
            //     .replace(/__phone__/g, phone)
            //     .replace(/__accessCode__/g, accessCode);

            fetch(ownerVerifyRoute, {mode: "cors"})
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        window.localStorage.setItem('userData', JSON.stringify({
                            'owner_hash': data.ownerHash,
                            'owner_phone_code': phoneCode.split('+')[1],
                            'owner_phone': phone,
                            'owner_country_code': countryCode,
                            'owner_access_code': accessCode,
                            'owner_id': data.id
                        }))
                        let hostalListByOwnerRoute = apiUri.functions.ownerListHostals(
                            data.id,
                            data.ownerHash
                        )
                        // let hostalListByOwnerRoute = 'http://localhost:8008/amortizacion/owner/list/hostals/__ownerId__/__ownerHash__';
                        // hostalListByOwnerRoute = hostalListByOwnerRoute
                        //     .replace(/__ownerId__/g, data.id)
                        //     .replace(/__ownerHash__/g, data.ownerHash);
                        fetch(hostalListByOwnerRoute)
                            .then((response) => response.json())
                            .then((data) => {
                                if (!data.error) {
                                    window.localStorage.setItem('hostalsByOwner', parseInt(data.count))
                                    if (data.count > 0) {
                                        history.push(`/dashboard`)
                                    } else {
                                        history.push(`/hostal/publicar`)
                                    }
                                }
                            }, (error) => {
                                console.error('Error inesperado: ' + error)
                            })
                    } else {
                        setErrorMessage(data.errorText === 'Codigo incorrecto...' ? 'Código incorrecto, intente de nuevo' : data.errorText)
                        setVariant('danger')
                        showAlert()
                    }
                }, (error) => {
                    console.error('Something bad is happend: ', error);
                    setErrorMessage('Ha ocurrido un error inesperado: ' + error)
                    setVariant('danger')
                    showAlert()
                })
        }
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    function handleAccessCode(obj) {
        setAccessCode(obj.target.value);
    }

    return (
        <LayoutOne showNavBar={false} bG={false} showIcon={false} title={
            <CommonTitle goTo={() => history.go(-1)}/>
        }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold py-3">Acceso propietarios</h5>
                </div>
                <div>
                    <h6 className="phone_info col-12 text-muted">Hemos enviado un código de acceso por medio de SMS a su teléfono.</h6>
                    <Row>
                        <Col className="phone_info col-md-6">
                            <FlagIcon code={countryCode}/> {phoneCode} {phone}
                        </Col>
                        <Col className="col-12">
                            <Link to="/" className="text-decoration-underline pl-4">
                                Corregir número
                            </Link>
                        </Col>
                    </Row>
                    <br/>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formCode">
                            <Form.Label>Código de acceso</Form.Label>
                            <Form.Control value={accessCode} onChange={handleAccessCode} type="tel"/>
                        </Form.Group>
                        <div className="row justify-content-center">
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row justify-content-center">
                            <Button variant="primary" type="submit" size="lg">
                                Acceder
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </LayoutOne>
    );
}

export default OwnerAccess;