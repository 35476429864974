import React, {useContext, useEffect, useState} from 'react';
import {Nav, Navbar, Button, Modal, Container, ListGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import logo_brand from '../Assets/images/icons/4x/path_494.png';
import logo_modal from '../Assets/images/icons/4x/path_493.png';
// import header_bottom_corner from '../Assets/images/header_bottom_corner.png';
import {ApiContext} from "../State/api-context";
import {ThemeContext} from "../State/theme-context";

function Header({title, showNavBar, showIcon, bG}) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const IconSize = 34
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const apiUri = useContext(ApiContext)
    const [hostals, setHostals] = useState(null)
    const customStyles = useContext(ThemeContext)

    useEffect(() => {
        if (userData) {
            fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash))
                .then((rsp) => rsp.json())
                .then((data) => {
                    if (!data.error) {
                        if (data.count > 0) {
                            setHostals(data.hostals)
                            window.localStorage.setItem('hostalsByOwner', parseInt(data.count))
                        } else {
                            setHostals([])
                        }
                    }
                }, (error) => {

                })
        }
    }, [])

    return (
        <Container fluid>
            <div className="HeaderNav customize">
                <Navbar style={bG ? {height: 65} : {
                    height: 65,
                    borderBottom: `${customStyles.texts_color.others} solid 1px`
                }}
                        className="d-flex justify-content-between" fixed="top"
                        bg={bG ? 'primary' : 'custom'}
                        variant="primary">
                    {
                        showIcon ?
                            <Navbar.Brand>
                                <Link to="/">
                                    <img src={logo_brand} width="46" height="28" className="d-inline-block align-top"
                                         alt="Cubahostal logo"/>
                                </Link>
                            </Navbar.Brand>
                            : ''
                    }
                    <Nav.Link style={showIcon ? {} : {width: 100 + '%'}} to="/123"><h5
                        className="text-white pt-1">{title}</h5></Nav.Link>
                    {
                        showNavBar ?
                            <Button variant="primary" onClick={handleShow}><Icon.List size="32px"/></Button>
                            : (showIcon ? <Nav.Item style={{paddingLeft: 58}}/> : '')
                    }
                </Navbar>
                {/*{*/}
                {/*    bG ? <div>*/}
                {/*        <img alt="header_bottom_corner_left" src={header_bottom_corner}*/}
                {/*             className="header_bottom_corner_left"/>*/}
                {/*        <img alt="header_bottom_corner_right" src={header_bottom_corner}*/}
                {/*             className="header_bottom_corner_right"/>*/}
                {/*    </div> : ''*/}
                {/*}*/}

            </div>
            <Modal show={show} onHide={handleClose} id="modalHeader" className="app-full-menu">
                <Modal.Header className="justify-content-end mb-n4">
                    <button className="customClose" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <ListGroup.Item>
                        <Link to="/" onClick={handleClose}>
                            <img src={logo_modal} width={IconSize + 5} height={IconSize - 4} className="d-inline-block align-top"
                                         alt="Cubahostal Home"/>
                            <span className="ml-1 mt-4"> Inicio</span>
                        </Link>
                    </ListGroup.Item>
                    <hr/>
                    <ListGroup.Item>
                        <Link onClick={handleClose} to={
                            hostals && hostals.length ?
                                (hostals.length > 1 ? "/reservas/hostales" : {
                                    pathname: '/reservas/listado',
                                    state: {
                                        hostal_id: hostals[0].id,
                                        hostal_name: hostals[0].tittle,
                                        entire_property: hostals[0].entireProperty
                                    }
                                }) : ''
                        }>
                            <Icon.Calendar2Check size={IconSize}/>
                            <span className="ml-2"> Reservas</span>
                        </Link>
                    </ListGroup.Item>
                    <hr/>
                    <ListGroup.Item>
                        <Link onClick={handleClose} to={
                            hostals && hostals.length ?
                                (hostals.length > 1 ? "/reservas/calendario" : {
                                    pathname: '/reservas/custom/calendar',
                                    state: {
                                        hostal_id: hostals[0].id,
                                        hostal_name: hostals[0].tittle,
                                        entire_property: hostals[0].entireProperty
                                    }
                                }) : ''
                        }>
                            <Icon.Calendar3Week size={IconSize}/>
                            <span className="ml-2"> Calendario</span>
                        </Link>
                    </ListGroup.Item>
                    <hr/>
                    {/* <ListGroup> */}
                    <ListGroup.Item>
                        <Link to={hostals && hostals.length > 0
                            ? "/hostal/listar" : ''} onClick={handleClose}>
                            <Icon.House size={IconSize}/>
                            <span className="ml-2 mt-4"> Mis hostales</span>
                        </Link>
                    </ListGroup.Item>
                    {/* </ListGroup> */}
                    <hr/>
                    {/* <ListGroup> */}
                    <ListGroup.Item>
                        <Link to="/ajustes" onClick={handleClose}><Icon.Person size={IconSize}/>
                            <span className="ml-2 mt-4"> Mi cuenta</span>
                        </Link>
                    </ListGroup.Item>
                    {/* </ListGroup> */}
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default Header;