import React from "react";

const uri = 'https://api.chostal.com/',
// const uri = 'https://api.cubahostal.com/',
    uriFunctions = `${uri}?f=`

export const API = {
    functions: {
        root: `${uri}`,
        htmlContactHelp: `${uriFunctions}htmlContactHelp`,
        listCountryPhoneCodes: `${uriFunctions}listCountryPhoneCodes`,
        ownerAccess: (code, phone) => {
            return `${uriFunctions}ownerAccess&countryCode=${code}&phoneNumber=${phone}`
        },
        ownerVerifyCode: (countryCode, phone, accessCode) => {
            return `${uriFunctions}ownerVerifyCode&countryCode=${countryCode}&phoneNumber=${phone}&accessCode=${accessCode}`
        },
        ownerListHostals: (ownerId, ownerHash, hostalId = '') => {
            return `${uriFunctions}ownerListHostals&ownerId=${ownerId}&ownerHash=${ownerHash}${hostalId}`
        },
        listHostalTypes: `${uriFunctions}listHostalTypes`,
        ownerCreateHostal: (ownerId, ownerHash, hostalTitle, minimumStay, roomType) => {
            return `${uriFunctions}ownerCreateHostal&ownerId=${ownerId}&ownerHash=${ownerHash}&tittle=${hostalTitle}&minStay=${minimumStay}&entireProperty=${roomType}`
        },
        listProvinces: `${uriFunctions}listProvinces`,
        listDestinations: (provinceId = '') => {
            return `${uriFunctions}listDestinations${provinceId}`
        },
        updateHostalAddress: (ownerId, ownerHash, hostalId, destinationId, address) => {
            return `${uriFunctions}updateHostalAddress&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&destinationId=${destinationId}&address=${address}`
        },
        updateHostalMap: (ownerId, ownerHash, hostalId, lat, lng) => {
            return `${uriFunctions}updateHostalMap&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&lat=${lat}&lng=${lng}`
        },
        ownerInsertHostalPhoto: (ownerId, ownerHash, hostalId) => {
            return `${uriFunctions}ownerInsertHostalPhoto&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}`
        },
        listRoomsSpecialFeatures: `${uriFunctions}listRoomsSpecialFeatures`,
        listBedTypes: `${uriFunctions}listBedTypes`,
        listOwnerNotices: (ownerId, ownerHash) => {
            return `${uriFunctions}listOwnerNotices&ownerId=${ownerId}&ownerHash=${ownerHash}`
        },
        listHostals: (hostalId) => {
            return `${uriFunctions}listHostals&hostalId=${hostalId}`
        },
        ownerSaveRoom: (ownerId, ownerHash, roomId, hostalId, isSuite, maxGuests, specialFeature, bedList) => {
            return `${uriFunctions}ownerSaveRoom&ownerId=${ownerId}&ownerHash=${ownerHash}${roomId}&hostalId=${hostalId}&isSuite=${isSuite}&maxGuests=${maxGuests}&specialFeature=${specialFeature}&bedList=${bedList}`
        },
        ownerInfo: (ownerId, ownerHash) => {
            return `${uriFunctions}ownerInfo&ownerId=${ownerId}&ownerHash=${ownerHash}`
        },
        ownerListRooms: (ownerId, ownerHash, hostalId) => {
            return `${uriFunctions}ownerListRooms&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}`
        },
        ownerActivateRoom: (ownerId, ownerHash, roomId, status) => {
            return `${uriFunctions}ownerActivateRoom&ownerId=${ownerId}&ownerHash=${ownerHash}&roomId=${roomId}&activate=${status}`
        },
        ownerListHostalPhotos: (ownerId, ownerHash, hostalId) => {
            return `${uriFunctions}ownerListHostalPhotos&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}`
        },
        ownerDeleteHostalPhoto: (ownerId, ownerHash, photoId) => {
            return `${uriFunctions}ownerDeleteHostalPhoto&ownerId=${ownerId}&ownerHash=${ownerHash}&photoId=${photoId}`
        },
        ownerUpdatePrices_hostal: (ownerId, ownerHash, hostalId, prices) => {
            return `${uriFunctions}ownerUpdatePrices&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}${prices}`
        },
        ownerUpdatePrices_room: (ownerId, ownerHash, roomId, prices) => {
            return `${uriFunctions}ownerUpdatePrices&ownerId=${ownerId}&ownerHash=${ownerHash}&roomId=${roomId}${prices}`
        },
        ownerUpdateInfo: (ownerId, ownerHash, name, email, email2nd, landline) => {
            return `${uriFunctions}ownerUpdateInfo&ownerId=${ownerId}&ownerHash=${ownerHash}&name=${name}&email=${email}&email2nd=${email2nd}&landline=${landline}`
        },
        ownerUpdatePhoto: (ownerId, ownerHash) => {
            return `${uriFunctions}ownerUpdatePhoto&ownerId=${ownerId}&ownerHash=${ownerHash}`
        },
        ownerListReservations: (ownerId, ownerHash, reservationId = '', hostalId = '', reservationDate = '', checkinDate = '', unread = '') => {
            return `${uriFunctions}ownerListReservations&ownerId=${ownerId}&ownerHash=${ownerHash}${reservationId}${hostalId}${reservationDate}${checkinDate}${unread}`
        },
        ownerListMessages: (ownerId, ownerHash, pagination = '', reservationId = '') => {
            return `${uriFunctions}ownerListMessages&ownerId=${ownerId}&ownerHash=${ownerHash}${pagination}${reservationId}`
        },
        ownerInsertMessage: (ownerId, ownerHash, reservationId) => {
            return `${uriFunctions}ownerInsertMessage&ownerId=${ownerId}&ownerHash=${ownerHash}&reservationId=${reservationId}`
        },
        ownerBlockUnblockDates: (ownerId, ownerHash, hostalId, action, startDate = '', endDate = '', roomList = '', reservationId = '') => {
            return `${uriFunctions}ownerBlockUnblockDates&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&action=${action}${startDate}${endDate}${roomList}${reservationId}`
        },
        listHostalAmenities: (hostalId = '') => {
            return `${uriFunctions}listHostalAmenities${hostalId}`
        },
        updateHostalAmenities: (ownerId, ownerHash, hostalId, amenityList) => {
            return `${uriFunctions}updateHostalAmenities&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&amenityList=${amenityList}`
        },
        updateHostalName: (ownerId, ownerHash, hostalId, name) => {
            return `${uriFunctions}updateHostalName&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&name=${name}`
        },
        updateHostalMinStay: (ownerId, ownerHash, hostalId, minStay) => {
            return `${uriFunctions}updateHostalMinStay&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&minStay=${minStay}`
        },
        listHostalServices: (hostalId = '') => {
            return `${uriFunctions}listHostalServices${hostalId}`
        },
        updateHostalServices: (ownerId, ownerHash, hostalId, serviceList, freeOrNotList) => {
            return `${uriFunctions}updateHostalServices&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}&serviceList=${serviceList}&freeOrNotList=${freeOrNotList}`
        },
        listRoomColors: `${uriFunctions}listRoomColors`,
        ownerInsertIcalendar: (ownerId, ownerHash, hostalId, roomId, url) => {
            return `${uriFunctions}ownerInsertIcalendar&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}${roomId}&url=${url}`
        },
        ownerListIcalendars: (ownerId, ownerHash, hostalId, roomId) => {
            return `${uriFunctions}ownerListIcalendars&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}${roomId}`
        },
        ownerDeleteIcalendar: (ownerId, ownerHash, calendarId) => {
            return `${uriFunctions}ownerDeleteIcalendar&ownerId=${ownerId}&ownerHash=${ownerHash}&id=${calendarId}`
        },
        updateHostalDescription: (ownerId, ownerHash, hostalId) => {
            return `${uriFunctions}updateHostalDescription&ownerId=${ownerId}&ownerHash=${ownerHash}&hostalId=${hostalId}`
        }
    }
}

export const ApiContext = React.createContext(
    API
)