import React, {useContext, useEffect, useState} from "react";
import {ListGroup, Col, Row, Form} from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import {useHistory, useLocation} from "react-router-dom";
import {LayoutOne} from "../Layout";
import moment from 'moment'
import 'moment/locale/es'
import {ApiContext} from "../../State/api-context";
import CommonTitle from "../Utils/CommonTitle";
import iconHome from "../../Assets/images/icon_home.png";
import Loader from '../../Components/Loaders/Reserva'
import FirstPhoto from "../Utils/FirstPhoto";
import {undefinedState, timeInHostal} from "../Utils/Utils";

function LockRoom() {
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    let history = useHistory()
    const [headerTitle, setHeaderTitle] = useState(null)
    const [daysSelected, setDaysSelected] = useState(null)
    const [hostal, setHostal] = useState(null)
    const [rooms, setRooms] = useState(null)
    const [reservations, setReservations] = useState(null)
    const {state} = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        const selection = JSON.parse(state.dates_selected)
        const sortedDates = selection.sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
        setDaysSelected(sortedDates)
        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${state.hostal_id}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setHostal(data.hostals[0])
                        const titleText = `${selection.length} día${selection.length > 1 ? 's' : ''} seleccionado${selection.length > 1 ? 's' : ''}`
                        setHeaderTitle(
                            <CommonTitle goTo={() => history.push('/reservas/custom/calendar', {
                                hostal_id: data.hostals[0].id,
                                hostal_name: data.hostals[0].tittle,
                                entire_property: data.hostals[0].entireProperty,
                                last_month: sortedDates[sortedDates.length - 1]
                            })} title={titleText}/>
                        )
                    } else
                        setHostal(null)
                }
            }, (error) => {

            })
        fetch(apiUri.functions.ownerListRooms(userData.owner_id, userData.owner_hash, state.hostal_id))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setRooms(data.rooms)
                    } else {
                        setRooms([])
                    }
                }
            }, (error) => {

            })
        getReservations(state.hostal_id)
    }, [state])

    const getReservations = (id) => {
        fetch(apiUri.functions.ownerListReservations(userData.owner_id, userData.owner_hash, '', `&hostalId=${id}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setReservations(data.reservations)
                    } else {
                        setReservations([])
                    }
                }
            }, (error) => {

            })
    }

    const lockUnlockAction = (date, roomId = null, blocked = false, reservationId = null) => {
        let route
        if (!blocked) {
            if (roomId) {
                route = apiUri.functions.ownerBlockUnblockDates(userData.owner_id, userData.owner_hash, hostal.id, 1, `&startDate=${date.format('Y-M-D')}`, `&endDate=${date.format('Y-M-D')}`, `&roomList=${roomId}`)
            } else {
                route = apiUri.functions.ownerBlockUnblockDates(userData.owner_id, userData.owner_hash, hostal.id, 1, `&startDate=${date.format('Y-M-D')}`, `&endDate=${date.format('Y-M-D')}`)
            }
        } else {
            route = apiUri.functions.ownerBlockUnblockDates(userData.owner_id, userData.owner_hash, hostal.id, 2, '', '', '', `&reservationId=${reservationId}`)
        }
        fetch(route)
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    getReservations(hostal.id)
                }
            }, (error) => {

            })
    }

    const printDates = () => {
        return daysSelected.map((day, pos) => {
            const date = moment(day)
            if (parseInt(hostal.entireProperty)) {
                let blocked = false,
                    reservationId = null,
                    reserved = false,
                    _external = false,
                    info = ''
                for (let i = 0; i < reservations.length; i++) {
                    const reservation = reservations[i]
                    const checkin = moment(reservation.checkinDate, 'Y-M-D'),
                        checkout = moment(reservation.ckeckoutDate, 'Y-M-D')
                    if (reservation.daysBlocked && !reservation.iCalendarHost) {
                        if (checkin.format('YYYYMMDD') <= date.format('YYYYMMDD') && checkout.format('YYYYMMDD') >= date.format('YYYYMMDD')) {
                            blocked = true
                            reservationId = reservation.id
                        }
                    } else {
                        if (date.isBetween(checkin, checkout, null, '[)')) {
                            reserved = true
                            if (reservation.iCalendarHost) {
                                _external = true
                                info = <ul style={{marginLeft: 1.8 + 'rem'}} className="list-unstyled mb-0">
                                            <li className="list-unstyled mt-1">Calendario externo: <strong>{reservation.iCalendarHost}</strong></li>
                                        </ul>
                            } else {
                                info = <ul style={{marginLeft: 1.8 + 'rem'}} className="list-unstyled mb-0">
                                            <li className="list-unstyled my-1">{reservation.guestName} {reservation.guestLastname} &middot; {reservation.code}</li>
                                            <li className="list-unstyled">{timeInHostal(reservation)}</li>
                                       </ul>
                                reservationId = reservation.id
                            }
                            break
                        }
                    }
                }
                return (
                    <ListGroup.Item className="wideList" style={pos + 1 === daysSelected.length ? {borderBottom: '1px solid rgba(0,0,0,.125)'} : null}>
                        <Row style={{marginTop: 13, marginBottom: 5}}>
                            <Col xs={{span: 12}} className="font-weight-bold"
                                 style={{fontSize: 20, marginBottom: 12}}>
                                <Icon.Calendar2Week className="mr-2 mt-n2"/>{date.format('D MMMM, Y')}
                            </Col>
                            <Col onClick={() => reserved && reservationId ? history.push('/reservas/detalles', {
                                hostal_id: hostal.id,
                                reservation_id: reservationId
                            }) : null} xs={reserved ? {span: 8} : {span: 10}} className={reserved && reservationId ? "link-li" : null}>
                                <img src={iconHome} width="20" height="20" className="mt-n2 mr-1"
                                     alt="Icon Hostal"/> {hostal.tittle}
                                {blocked ? <span
                                    className="text-danger float-right mr-n3"><Icon.LockFill
                                    size={20}/></span> : ''}
                            </Col>
                            <Col xs={reserved ? {span: 4} : {span: 2}} className="text-right">
                                {
                                    reserved ? (_external ? "" : <span onClick={() => reserved && reservationId ? history.push('/reservas/detalles', {
                                        hostal_id: hostal.id,
                                        reservation_id: reservationId
                                    }) : null} style={{fontSize: 18}} 
                                                                       className="text-success pr-2 link-li">Reservada</span>) :
                                        <Form.Check
                                            onClick={() => lockUnlockAction(date, null, blocked, reservationId)}
                                            label=''
                                            id={`lock_unlock_hostal_${hostal.id}_date_${date.toString()}`}
                                            defaultChecked={!blocked}
                                            type="switch"/>
                                }
                            </Col>
                                {   
                                    reserved ? <Col onClick={() => reserved && reservationId ? history.push('/reservas/detalles', {
                                        hostal_id: hostal.id,
                                        reservation_id: reservationId
                                    }) : null} xs={{span: 12}}
                                        style={{
                                            fontSize: 17,
                                            color: 'grey',
                                        }} className={reserved && reservationId ? "link-li" : null}>{info}</Col> : ''
                                }
                        </Row>
                    </ListGroup.Item>
                )
            } else {
                if (rooms.length > 0) {
                    const components = [
                        <Row style={{marginTop: 25}}>
                            <Col xs={{span: 12}} className="font-weight-bold"
                                 style={{fontSize: 20}}>
                                <Icon.Calendar2Week className="mr-2 mt-n2"/>{date.format('D MMMM, Y')}
                            </Col>
                        </Row>
                    ]
                    let contHab = 1,
                        contSuit = 1
                    rooms.map((room, index) => {
                        let blocked = false,
                            reservationId = null,
                            reserved = false,
                            _external = false,
                            info = ''
                        for (let i = 0; i < reservations.length; i++) {
                            const reservation = reservations[i]
                            const checkin = moment(reservation.checkinDate, 'Y-M-D'),
                                checkout = moment(reservation.ckeckoutDate, 'Y-M-D')
                            if (reservation.daysBlocked && !reservation.iCalendarHost) {
                                if (checkin.format('YYYYMMDD') <= date.format('YYYYMMDD') && checkout.format('YYYYMMDD') >= date.format('YYYYMMDD')) {
                                    if (reservation.rooms && reservation.rooms.find((obj) => {
                                        if (parseInt(obj.id) === parseInt(room.id)) {
                                            reservationId = reservation.id
                                            return true
                                        }
                                    })) {
                                        blocked = true
                                    }
                                }
                            } else {
                                if (date.isBetween(checkin, checkout, null, '[)') && reservation.rooms.find(obj => parseInt(obj.id) === parseInt(room.id))) {
                                    reserved = true
                                    if (reservation.iCalendarHost) {
                                        _external = true
                                        info = <ul className="list-unstyled ml-4 mb-0">
                                                    <li className="list-unstyled mt-1">Calendario externo: <strong>{reservation.iCalendarHost}</strong></li>
                                                </ul>
                                    } else {
                                        info = <ul className="list-unstyled ml-4 mb-0">
                                                    <li className="list-unstyled my-1">{reservation.guestName} {reservation.guestLastname} &middot; {reservation.code}</li>
                                                    <li className="list-unstyled">{timeInHostal(reservation)}</li>
                                               </ul>
                                        reservationId = reservation.id
                                    }
                                    break
                                }
                            }
                        }
                        components.push(<ListGroup.Item className="wideList" style={index + 1 === rooms.length ? {borderBottom: '1px solid rgba(0,0,0,.125)'} : null}>
                            <Row onClick={() => reserved && reservationId ? history.push('/reservas/detalles', {
                                hostal_id: hostal.id,
                                reservation_id: reservationId
                            }) : null} className={reserved && reservationId ? "link-li" : null}>
                                <Col xs={reserved ? {span: 8} : {span: 10}}>
                                    <Icon.DoorOpenFill className="mt-n2"
                                        color={parseInt(hostal.entireProperty) === 1 ? 'green' : (room.color ? room.color : 'currentColor')}
                                        size={20}/> {room.name + (parseInt(room.isSuite) ? ' (Suite)' : '')}
                                    {blocked ? <span
                                        className="text-danger float-right mr-n3"><Icon.LockFill
                                        size={20}/></span> : ''}
                                </Col>
                                <Col xs={reserved ? {span: 4} : {span: 2}} className="text-right">
                                    {
                                        reserved ? (_external ? "" : <span style={{fontSize: 18, right: 0}}
                                                                           className="text-success pr-2">Reservada</span>)
                                            : <Form.Check
                                                onClick={() => lockUnlockAction(date, room.id, blocked, reservationId)}
                                                label=''
                                                id={`lock_unlock_hostal_${hostal.id}_room_${room.id}_date_${date.toString()}`}
                                                defaultChecked={!blocked}
                                                type="switch"/>
                                    }
                                </Col>
                                {
                                    reserved ? <Col xs={{span:12}}
                                        style={{
                                            fontSize: 17,
                                            color: 'grey'
                                        }}>{info}</Col> : ''
                                }
                            </Row>
                        </ListGroup.Item>)
                    })
                    return components
                }
            }
        })
    }

    return (
        <LayoutOne title={headerTitle} showNavBar={false} showIcon={false} bG={false}>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <ListGroup.Item className="ListGroupNoBorders wideList" style={{paddingBottom: 0}}>
                        <Row className="align-items-center msgPhoto">
                            {
                                hostal ? <FirstPhoto hostalId={hostal.id} lockView={true} /> : ''
                            }
                            <Col xs={{span: 8}} className="pl-2 pt-2">
                                <h5 className="font-weight-bold">{hostal ? hostal.tittle : 'Cargando datos...'}</h5>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        daysSelected && rooms && reservations && hostal
                            ? printDates()
                            : <div className="pt-4">
                                <Loader style={{width: 100 + '%'}}/>
                            </div>
                    }
                </ListGroup>
            </div>
        </LayoutOne>
    )
}

export default LockRoom