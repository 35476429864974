import React from "react"
import ContentLoader from "react-content-loader"

const PhotoLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height="100%"
    backgroundColor="#eef4f9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect rx="9" ry="9" width="100%" height="100%" />
  </ContentLoader>
)

export default PhotoLoader

