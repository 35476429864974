import React, {useContext, useEffect, useState} from 'react';
import moment from "moment"
import 'moment/locale/es'
import {Form, Button, InputGroup, Alert, ListGroup} from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import {useHistory, useLocation} from "react-router-dom";
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState} from "../Utils/Utils";
import iconHome from "../../Assets/images/icon_home.png";

function Propiedad() {

    const {state} = useLocation();
    const [hostal, setHostal] = useState(null)
    const [hostalPrices, setHostalPrices] = useState([])
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    let history = useHistory()
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        const _hostal = JSON.parse(state.hostal)
        const _prices = _hostal.prices
        setHostal(_hostal)
        let arrPrices = []
        Array.from(Array(12), (e, i) => {
            arrPrices.push({month: i + 1, price: _prices[i + 1]})
        })
        setHostalPrices(arrPrices)
    }, [state])

    function handlePrices(month, value) {
        const list = [...hostalPrices]
        let index = null
        list.find((ob, pos) => {
            if (ob.month === month) {
                index = pos
            }
        })
        list[index]['price'] = value
        setHostalPrices(list)
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    function handleSubmit(e) {
        e.preventDefault()
        let empty = false,
            output = ''
        hostalPrices.map(({month, price}) => {
            price.length === 0
                ? empty = true
                : output += `&price${month}=${price}`
        })
        if (empty) {
            setErrorMessage('No pueden quedar campos vacíos')
            setVariant('warning')
            showAlert()
        } else {
            fetch(apiUri.functions.ownerUpdatePrices_hostal(userData.owner_id, userData.owner_hash, hostal.id, output))
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash))
                            .then((response) => response.json())
                            .then((data) => {
                                if (!data.error) {
                                    const _hostal_ = data.hostals.find(ob => {
                                        return ob.id === hostal.id
                                    })
                                    history.push(`/hostal/habitaciones`, {
                                        hostal: JSON.stringify(_hostal_)
                                    })
                                }
                            }, (error) => {

                            })
                    }
                }, (error) => {

                })
        }
    }

    return (
        <LayoutOne showIcon={false} showCopy={false} bG={false} showNavBar={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)}
                                    title={hostal === null ? 'Cargando hostal...' : hostal.tittle}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <div className="row justify-content-center">
                        <h4>Precio por noche</h4>
                    </div>
                    <div className="align-items-center">
                        <div className="mb-3 mt-1"><img src={iconHome} width="20" height="20" className="mt-n1"
                                                        alt="Icon Hostal"/> Propiedad Entera
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit} style={{paddingBottom: 5 + 'rem'}}>
                        {
                            hostalPrices === null
                                ? 'Cargando precios...'
                                :
                                hostalPrices.map(({month, price}) => {
                                    return (
                                        <Form.Group
                                            controlId={`formMonth_${moment(month, 'M').locale('es').format('MMMM')}`}>
                                            <Form.Label
                                                className="text-capitalize">{moment(month, 'M').locale('es').format('MMMM')}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend className="input-group-prepend">
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control onChange={e => handlePrices(month, e.target.value)}
                                                              style={{borderLeft: 0}} defaultValue={price}
                                                              type="number" onWheel={event => event.currentTarget.blur()}/>
                                            </InputGroup>
                                        </Form.Group>
                                    )
                                })
                        }
                        <div className="row fixed-bottom justify-content-center" style={{bottom: 5 + 'rem'}}>
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row fixed-bottom justify-content-center pb-4"
                             style={{backgroundColor: 'white'}}>
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default Propiedad;