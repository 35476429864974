import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width='100%'
    height={130}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="78" y="147" rx="3" ry="3" width="178" height="6" />
    <rect x="0" y="13" rx="0" ry="0" width="55" height="52" />
    <rect x="64" y="22" rx="0" ry="0" width="159" height="13" />
    <rect x="64" y="43" rx="0" ry="0" width="159" height="13" />
    <rect x="64" y="78" rx="0" ry="0" width="239" height="13" />
    <rect x="64" y="111" rx="0" ry="0" width="159" height="13" />
  </ContentLoader>
)

export default MyLoader

