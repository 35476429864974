import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col'
// import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
// import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button'
import {useHistory, useLocation} from "react-router-dom";
import {ApiContext} from "../../State/api-context";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import {LayoutOne} from "../Layout";
// import iconHome from "../../Assets/images/icon_home.png";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState} from "../Utils/Utils";

function EditHostalName() {

    const {state} = useLocation();
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    let history = useHistory()
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalId(state.hostal_id)
        setHostalName(state.hostal_name)
    }, [state])

    function handleSubmit(e) {
        e.preventDefault()
        if (hostalName.length === 0) {
            setErrorMessage('Debe especificar un nombre para su hostal')
            setVariant('warning')
            showAlert()
        } else {
            fetch(apiUri.functions.updateHostalName(userData.owner_id, userData.owner_hash, hostalId, hostalName))
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                            .then((response) => response.json())
                            .then((data) => {
                                if (!data.error) {
                                    history.push(`/hostal/habitaciones`, {
                                        hostal: JSON.stringify(data.hostals[0])
                                    })
                                }
                            }, (error) => {

                            })
                    }
                }, (error) => {

                })
        }
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)} title={hostalName ? hostalName : ''}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <Col className='text-center pt-2'>
                        <h5 className="font-weight-bold">Editar nombre</h5>
                    </Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label><span>Nombre del hostal</span></Form.Label>
                            <Form.Control type="text" onChange={(e) => setHostalName(e.target.value)}
                                          value={hostalName === null ? 'Cargando datos...' : hostalName}/>
                        </Form.Group>
                        <div className="row justify-content-center">
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row justify-content-center">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default EditHostalName;