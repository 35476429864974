import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Link, useHistory} from 'react-router-dom';
import ListGroup from "react-bootstrap/ListGroup";
import {ApiContext} from "../../State/api-context";
import Loader from '../../Components/Loaders/ItemList/ItemList'
import {LayoutOne} from "../Layout";
import * as Icon from "react-bootstrap-icons";
import FirstPhoto from "../Utils/FirstPhoto";
import CommonTitle from '../Utils/CommonTitle';
import settingsHostalIcon from "../../Assets/images/icons/1x/sliders.png"

function Listar() {

    const apiUri = useContext(ApiContext)
    const [hostals, setHostals] = useState(null);
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    let history = useHistory()
    // const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
            window.scrollTo(0, 0)
            let hostalListByOwnerRoute = apiUri.functions.ownerListHostals(
                userData.owner_id,
                userData.owner_hash
            )
            // let hostalListByOwnerRoute = 'http://localhost:8008/amortizacion/owner/list/hostals/__ownerId__/__ownerHash__';
            // hostalListByOwnerRoute = hostalListByOwnerRoute
            //     .replace(/__ownerId__/g, userData.owner_id)
            //     .replace(/__ownerHash__/g, userData.owner_hash);
            fetch(hostalListByOwnerRoute)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data.hostals)
                    data.count > 0 ? setHostals(data.hostals) : setHostals([])
                    // setIsLoading(!isLoading);
                }, (error) => {

                })
        },
        []
    )

    return (
        <LayoutOne title={
            <CommonTitle goTo={() => history.push('/')} whiteImage={true}/>
        } showIcon={false}>
            <div className="container-fluid">
                <div className="row justify-content-center my-1">
                    <h6 className="text-muted">Selecciona un hostal</h6>
                </div>
                <ListGroup variant="flush">
                    {
                        hostals === null
                            ?
                            <>
                                <Loader/>
                                <Loader/>
                                <Loader/>
                                <Loader/>
                                <Loader/>
                            </>
                            : hostals.length > 0 ? hostals.map((hostal) => {
                                return (
                                    <ListGroup.Item key={hostal.id} className="wideList list-group-end-border">
                                        <Row className="align-items-center link-li"
                                             onClick={() => history.push('/hostal/habitaciones', {
                                                 hostal: JSON.stringify(hostal)
                                             })}>
                                            <FirstPhoto hostalId={hostal.id}/>
                                            <Col xs={{span: 8}} className="pl-0">
                                                <h5 className="font-weight-bold">{hostal.tittle}</h5>
                                            </Col>
                                            <Col xs={{span: 4}}/>
                                            <Col xs={{span: 8}} className="mt-n4 pl-0">
                                                <li className="list-unstyled text-info messages-hostal-list">
                                                    <Icon.Sliders style={{marginRight: 5, marginTop: -4}} size={18}/>
                                                    {/* <img src={settingsHostalIcon} width="18" height="18"
                                                        style={{marginRight: 5, marginTop: -4}} alt="Hostal settings"
                                                        /> */}
                                                        {/* <span> */}
                                                            Administrar
                                                        {/* </span> */}
                                                </li>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                )
                            }) : <ListGroup.Item className="wideList">
                                <span className="text-muted">No hay hostales</span>
                            </ListGroup.Item>
                    }

                    <ListGroup.Item className="wideList">
                        <Row>
                            <Col className="pt-2">
                                <Link className="text-info" to="/hostal/crear">
                                    A&ntilde;adir hostal
                                </Link>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {/*<ListGroup.Item>*/}
                    {/*    <Row className="align-items-center">*/}
                    {/*        <Col xs={{ span: 2 }} className="mediumIconCol" ><Icon.House size="38px"/></Col>*/}
                    {/*        <Col xs={{ span: 10 }} className="text-left">MALECON 360º Stuning view over "Malecon habanero"</Col>*/}
                    {/*    </Row>*/}
                    {/*<Row>*/}
                    {/*    <Col className="pt-2" >*/}
                    {/*        <Link className="text-muted" to="/mensajes/listado">Sin mensajes nuevos</Link>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*</ListGroup.Item>*/}
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default Listar;