import React from "react"
import moment from "moment"
import 'moment/locale/es'
import * as Icon from "react-bootstrap-icons"

export const LOCALIDAD = 'localidad'
export const COMODIDAD = 'comodidad'
export const SERVICIO = 'servicio'
export const TELEFONO = 'telefono'

export function timeInHostal(reservation) {
    const ini = moment(reservation.checkinDate, 'Y-M-D'),
        end = moment(reservation.ckeckoutDate, 'Y-M-D')

    if (ini.month() === end.month()) {
        return (
            `${ini.format('D')} - ${end.format('D')} ${end.format('MMMM')}, ${end.format('Y')} (${reservation.totalNights} ${reservation.totalNights > 1 ? 'noches' : 'noche'})`
        )
    } else {
        return (
            `${ini.format('D')} ${ini.format('MMMM')} - ${end.format('D')} ${end.format('MMMM')}, ${end.format('Y')} (${reservation.totalNights} ${reservation.totalNights > 1 ? 'noches' : 'noche'})`
        )
    }
}

export function roomsInReservation(reservation, withColor = false) {
    // console.log(reservation)
    if (reservation.daysBlocked || !reservation.rooms) {
        return []
    } else {
        let outP = []
        reservation.rooms.map((room) => {
            outP.push(
                <>
                    <Icon.DoorOpenFill color={withColor && room.color ? room.color : 'currentColor'}
                                        size="14px"
                                        className="mt-n1"/><span
                    className="pt-2 pl-2">{room.name + (parseInt(room.isSuite) ? ' (Suite)' : '')}</span><br/>
                </>
            )
        })
        return outP
    }
}

export function undefinedState(state) {
    if (state === undefined) {
        window.location.href = '/'
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }
}
