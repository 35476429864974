import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Form, Button} from "react-bootstrap";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";

function Logout() {

    let history = useHistory()
    const [disabled, isDisabled] = useState(true)

    function handleSubmit(e) {
        e.preventDefault()
        window.localStorage.removeItem('userData')
        window.localStorage.removeItem('hostalsByOwner')
        history.push('/')
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.push('/ajustes')}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold">Sesi&oacute;n de usuario</h5>
                </div>
                <div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <p className="text-justify">
                                En <Link style={{color: '#0076CC'}} to="/">CubaHostal.com</Link> por comodidad, muchos
                                usuarios prefieren mantener la sesi&oacute;n de usuario
                                abierta
                                una vez acceden a su cuenta. Es m&aacute;s f&aacute;cil acceder cada vez, sin tener que acordarse de
                                su
                                usuario y clave.
                            </p>
                            <p className="text-justify">
                                No obstante, damos la posibilidad de cerrar sesi&oacute;n por seguridad o por la necesidad de
                                acceder con otro n&uacute;mero de tel&eacute;fono.
                            </p>
                            <p className="text-justify">
                                Para cerrar sesi&oacute;n de manera segura, marque a continuaci&oacute;n la casilla de verificaci&oacute;n y
                                haga
                                click en el bot&oacute;n "Cerrar sesi&oacute;n".
                            </p>
                        </Form.Group>
                        <Form.Group controlId="formGuests">
                            <Form.Check onChange={() => isDisabled(!disabled)}
                                        type="checkbox" label="Entendido"
                                        className="sessionClose"/>
                        </Form.Group>
                        <div className="row justify-content-center">
                            <Button disabled={disabled} variant="danger" type="submit" size="lg">
                                Cerrar sesi&oacute;n
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </LayoutOne>
    );
}

export default Logout;