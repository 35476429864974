import React from "react";

function Copy() {
    return (
        <div className="text-center p-3">
            <span className="navbar-text text-muted">&copy; Cubahostal.com</span>
        </div>
    )
}

export default Copy