import React from 'react';
import {LayoutOne} from "./Layout";
import CommonTitle from "./Utils/CommonTitle";
import {Link, useHistory} from "react-router-dom";

function Help() {
    let history = useHistory()

    return (
        <LayoutOne bG={false} showNavBar={false} showIcon={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold">Servicio CubaHostal</h5>
                    <p className="text-justify pl-3 pr-3">
                        <Link style={{color: '#0076CC'}} to="/">CubaHostal.com</Link> es gratis para todos los
                        propietarios registrados. No
                        cobramos comisiones en
                        ning&uacute;n momento por las gestiones de las reservas.
                    </p>
                    <p className="text-justify pl-3 pr-3">
                        Cobramos de forma online a los hu&eacute;spedes una tarifa a la hora de realizar la reserva. Es
                        un importe m&iacute;nimo que ofrece una garant&iacute;a de la reserva, tanto al propietario como
                        al cliente.
                    </p>
                    <p className="text-justify pl-3 pr-3">
                        Los montos cobrados ayudan al mantenimiento, gesti&oacute;n y promoci&oacute;n de la plataforma.
                    </p>
                </div>

            </div>
        </LayoutOne>
    );
}

export default Help;