import React from "react"
import ContentLoader from "react-content-loader"

const LineLoader = (props) => (
  <ContentLoader 
    speed={2}
    // width="200px"
    width="100%"
    height="15px"
    backgroundColor="#eef4f9"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* <rect x="0" y="0" rx="3" ry="3" width="200px" height="15px" /> */}
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="15px" />

  </ContentLoader>
)

export default LineLoader
