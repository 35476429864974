import React from "react"
import ContentLoader from "react-content-loader"

const Reserva = (props) => (
  <ContentLoader 
    speed={2}
    width={550}
    height={200}
    backgroundColor="#eef4f9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="5" rx="3" ry="3" width="88" height="20" />
    <rect x="20" y="50" rx="3" ry="3" width="410" height="18" />
    <rect x="50" y="80" rx="3" ry="3" width="376" height="18" />
    <rect x="50" y="120" rx="3" ry="3" width="178" height="18" />
    <rect x="20" y="80" rx="0" ry="0" width="18" height="19" />
    <rect x="20" y="120" rx="0" ry="0" width="17" height="18" />
  </ContentLoader>
)

export default Reserva

