import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";
import {useHistory} from "react-router-dom";

function SubMenuHostal({hostalId, hostalName, entireProperty, active, month}) {
    let history = useHistory()
    const borderRadius = 75;
    const minHeight = 45;
    const minWidth = '30%';
    const maxWidth = '48%'
    const fontSize = 18

    const goTo = (route) => {
        history.push(route, {
            hostal_id: hostalId,
            hostal_name: hostalName,
            entire_property: entireProperty,
            selected_month: month
        })
    }

    const [scroll, setScroll] = useState(0)

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 0
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })
    })

    return (
        <div className="d-flex justify-content-between fixed-top mt-7 px-3 hostalLinkButtons borderBottomGradient"
             id={(scroll ? 'borderBottomGradient' : '')}>
            <Button style={{borderRadius: borderRadius, minHeight: minHeight, minWidth: minWidth, maxWidth: maxWidth}}
                    className={active === 'reservas' ? "active col-6" : "col-6"}
                    variant="light-custom"
                    onClick={() => goTo('/reservas/listado')}>
                <Icon.Calendar2Check size={fontSize}/><span style={{fontSize: fontSize, verticalAlign: 'middle'}}> Reservas</span></Button>
            <Button style={{borderRadius: borderRadius, minHeight: minHeight, minWidth: minWidth, maxWidth: maxWidth}}
                    className={active === 'calendario' ? "active col-6" : "col-6"}
                    variant="light-custom"
                    onClick={() => goTo('/reservas/custom/calendar')}>
                <Icon.Calendar2Week size={fontSize}/><span style={{fontSize: fontSize, verticalAlign: 'middle'}}> Calendario</span></Button>
        </div>
    )
}

export default SubMenuHostal