import React from "react"
import ContentLoader from "react-content-loader"

const ListarLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="95%"
    height="100%"
    backgroundColor="#eef4f9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="85" y="23" rx="3" ry="3" width="100%" height="25" />
    <rect x="85" y="58" rx="3" ry="3" width="40%" height="25" />
    <rect x="18" y="23" rx="3" ry="3" width="40" height="40" />
    <rect x="18" y="109" rx="3" ry="3" width="150" height="15" />
  </ContentLoader>
)

export default ListarLoader

