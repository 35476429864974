import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import {useHistory, useLocation} from "react-router-dom";
import Select from "react-select";
import {ThemeContext} from "../../State/theme-context";
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState, LOCALIDAD} from "../Utils/Utils";
import ContactUs from "../Utils/ContactUs"
// import LoaderLine from '../../Components/Loaders/Line';

function AdicionarDireccion() {

    const apiUri = useContext(ApiContext)
    const [hostalId, setHostalId] = useState(null);
    const [hostalName, setHostalName] = useState('');
    const [hostalType, setHostalType] = useState('');
    const [locations, setLocations] = useState([]);
    const [hostalLocation, setHostalLocation] = useState(null);
    const [provinces, setProvinces] = useState([])
    const [hostalProvince, setHostalProvince] = useState(null)
    const [hostalAddress, setHostalAddress] = useState('');
    const {state} = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [isEdit, setIsEdit] = useState(false)
    let editData_ = null

    let customStyles = useContext(ThemeContext);
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        if (state.edit) {
            setIsEdit(true)
            editData_ = JSON.parse(state.edit)
        }
        setHostalId(state.hostal_id);
        setHostalName(state.hostal_name);
        setHostalType(state.hostal_type);

        fetch(apiUri.functions.listProvinces)
            .then((rsp) => rsp.json())
            .then((data) => {
                if (data.count > 0 && !data.error) {
                    setProvinces(data.provinces)
                    if (state.edit) {
                        let provinceData = null
                        data.provinces.find(obj => {
                            if (parseInt(obj.id) === parseInt(JSON.parse(state.edit).hostal_province)) {
                                provinceData = {
                                    value: obj.id,
                                    label: obj.name,
                                }
                                return
                            }
                        })
                        changeProvince(provinceData)
                    }
                } else {
                    setProvinces([])
                }
            }, (error) => {

            })
    }, [state])

    const changeProvince = (e) => {
        setHostalProvince({
            value: e.value,
            label: e.label,
        })

        fetch(apiUri.functions.listDestinations(`&provinceId=${e.value}`))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error && data.count > 0) {
                    setLocations(data.destinations)
                    let value = data.destinations[0].id,
                        label = data.destinations[0].name,
                        lat = data.destinations[0].lat,
                        lng = data.destinations[0].lng,
                        zoom = data.destinations[0].z

                    if (editData_) {
                        const dst = data.destinations.find(ob => {
                            return ob.id === editData_.hostal_destination
                        })
                        value = dst.id
                        label = dst.name
                        lat = dst.lat
                        lng = dst.lng
                        zoom = dst.z
                        setHostalAddress(editData_.hostal_address)
                        editData_ = null
                    }
                    setHostalLocation({
                        value: value,
                        label: label,
                        lat: lat,
                        lng: lng,
                        z: zoom
                    })
                } else {
                    setLocations([])
                    setHostalLocation(null)
                }
            })
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    function handleSubmit(event) {
        event.preventDefault()
        if (!hostalLocation) {
            setErrorMessage('Debe especificar la localidad del hostal')
            setVariant('warning')
            showAlert()
        } else if (hostalAddress.length === 0) {
            setErrorMessage('Debe especificar la dirección del hostal')
            setVariant('warning')
            showAlert()
        } else {
            let hostalAddressRoute = apiUri.functions.updateHostalAddress(
                userData.owner_id,
                userData.owner_hash,
                hostalId,
                hostalLocation.value,
                hostalAddress
            )

            fetch(hostalAddressRoute, {mode: "cors"})
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        if (isEdit) {
                            fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                                .then((response) => response.json())
                                .then((data) => {
                                    if (!data.error && data.count > 0) {
                                        const _hostal_ = data.hostals[0]
                                        if (_hostal_.lat && _hostal_.lng) {
                                            history.push(`/hostal/habitaciones`, {
                                                hostal: JSON.stringify(_hostal_)
                                            })
                                        } else {
                                            createAction()
                                        }
                                    }
                                }, (error) => {

                                })
                        } else {
                            createAction()
                        }
                    } else {
                        setErrorMessage('Ha ocurrido un error al guardar la dirección, intente otra vez')
                        setVariant('danger')
                        showAlert()
                    }
                }, (error) => {
                    // console.error('Something bad is happend: ', error);
                    setErrorMessage('Ha ocurrido un error inesperado: ' + error)
                    setVariant('danger')
                    showAlert()
                })
        }
    }

    function createAction() {
        history.push(`/hostal/ubicacion`, {
            hostal_id: hostalId,
            hostal_name: hostalName,
            hostal_type: hostalType,
            locateIn: JSON.stringify({
                lat: hostalLocation.lat,
                lng: hostalLocation.lng,
                z: hostalLocation.z
            })
        })
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={isEdit ? <CommonTitle goTo={() => history.go(-1)} title={hostalName}/>
                           : <CommonTitle goTo={() => history.push('/')} title={hostalName}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center pt-2">
                    <h5 className="font-weight-bold">{isEdit ? 'Editar' : 'Añadir'} dirección</h5>
                </div>
                <div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formProvince">
                            <Form.Label>Provincia</Form.Label>
                            {
                                /*provinces === null
                                    ? <Select isSearchable={false} placeholder="Buscando provincias disponibles..."
                                        styles={customStyles.react_select_style.border_left}/>
                                    :*/ <Select
                                        placeholder="Seleccione"
                                        isSearchable={false}
                                        value={hostalProvince}
                                        onChange={e => changeProvince(e)}
                                        styles={customStyles.react_select_style.border_left}
                                        options={provinces.map(({id, name}) => {
                                            return {value: id, label: name}
                                        })}/>
                            }
                        </Form.Group>
                        <Form.Group controlId="formPlace">
                            <Form.Label>Localidad</Form.Label>
                            {
                                /*locations !== null && locations.length > 0
                                    ? */<Select
                                        placeholder="Seleccione"
                                        isSearchable={false}
                                        value={hostalLocation}
                                        onChange={e => setHostalLocation({
                                            value: e.value,
                                            label: e.label,
                                            lat: e.lat,
                                            lng: e.lng,
                                            z: e.z
                                        })}
                                        styles={customStyles.react_select_style.border_left}
                                        options={locations.map(({id, name, lat, lng, z}) => {
                                            return {value: id, label: name, lat: lat, lng: lng, z: z}
                                        })}/>
                                    /*: <Select isSearchable={false} placeholder="Localidades"
                                        styles={customStyles.react_select_style.border_left}/>*/
                            }
                            <ContactUs type={LOCALIDAD}/>
                        </Form.Group>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Direcci&oacute;n</Form.Label>
                            <textarea onChange={e => setHostalAddress(e.target.value)} value={hostalAddress}
                                      className="form-control"/>
                        </Form.Group>
                        <div className="row justify-content-center">
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row justify-content-center">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </LayoutOne>
    );
}

export default AdicionarDireccion;