import React, {useContext, useEffect, useState} from 'react';
import {Col, Row, Image, ListGroup, Form, Toast, Button} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import Loader from '../../Components/Loaders/Room';
import LoaderPhoto from '../../Components/Loaders/Photo';
import LoaderLine from '../../Components/Loaders/Line';
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import FirstPhoto from "../Utils/FirstPhoto";
import {undefinedState} from "../Utils/Utils";
import editDescriptionImg from "../../Assets/images/icons/1x/group_109.png"
import addRoomImg from "../../Assets/images/icons/1x/group_110.png"

function Listado() {

    const apiUri = useContext(ApiContext)
    const {state} = useLocation();
    const [hostal, setHostal] = useState(null)
    const [rooms, setRooms] = useState(null)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [features, setFeatures] = useState(null)
    const [publishRoom, setPublishRoom] = useState([])
    const [photos, setPhotos] = useState(null)
    const [amenitiesList, setAmenitiesList] = useState(null)
    const [servicesList, setServicesList] = useState(null)
    const [hostalAmenities, setHostalAmenities] = useState(null)
    const [hostalServices, setHostalServices] = useState(null)
    const [back, setBack] = useState(null)
    let history = useHistory()
    let contHab = 1,
        contSuit = 1

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        const hostalData = JSON.parse(state.hostal)
        if (state && state.back_on) {
            setBack(state.back_on)
        }
        setHostal(hostalData)
        fetch(apiUri.functions.ownerListRooms(userData.owner_id, userData.owner_hash, hostalData.id))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setRooms(data.rooms)
                        let states = []
                        data.rooms.map(({id, published}) => {
                            states.push({'id': id, 'published': parseInt(published) === 1})
                        })
                        setPublishRoom(states)
                    } else {
                        setRooms([])
                    }
                }
            }, (error) => {

            })

        fetch(apiUri.functions.listRoomsSpecialFeatures)
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    setFeatures(data.roomsSpecialFeatures)
                }
            })

        fetch(apiUri.functions.ownerListHostalPhotos(userData.owner_id, userData.owner_hash, hostalData.id))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    data.count > 0 ? setPhotos(data.hostalPhotos) : setPhotos([])
                }
            }, (error) => {

            })

        fetch(apiUri.functions.listHostalAmenities())
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    data.count > 0 ? setAmenitiesList(data.count) : setAmenitiesList(0)
                    fetch(apiUri.functions.listHostalAmenities(`&hostalId=${hostalData.id}`))
                        .then((response) => response.json())
                        .then((info) => {
                            if (!info.error) {

                                info.count > 0 ? setHostalAmenities(info.amenities) : setHostalAmenities([])
                            }
                        }, (fail) => {

                        })
                }
            }, (error) => {

            })
        fetch(apiUri.functions.listHostalServices())
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    data.count > 0 ? setServicesList(data.count) : setServicesList(0)
                    fetch(apiUri.functions.listHostalServices(`&hostalId=${hostalData.id}`))
                        .then((response) => response.json())
                        .then((info) => {
                            if (!info.error) {
                                info.count > 0 ? setHostalServices(info.services) : setHostalServices([])
                            }
                        }, (fail) => {

                        })
                }
            }, (error) => {

            })
    }, [state])

    function publishRoomHandler(j) {
        const list = [...publishRoom]
        list[j]['published'] = !list[j]['published']
        fetch(apiUri.functions.ownerActivateRoom(userData.owner_id, userData.owner_hash, list[j]['id'], list[j]['published'] ? 1 : 0))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    setPublishRoom(list)
                }
            }, (error) => {

            })
    }

    function printFeature(features, room) {
        if (features === null) {
            return 'Cargando características...'
        } else {
            const _feat = features.find(op => {
                return parseInt(op.id) === parseInt(room.specialFeature)
            })
            if (_feat)
                return _feat.name
            else
                return 'Habitación estándar'
        }
    }

    function editRoom(room) {
        history.push(`/hostal/habitacion/editar`, {
            hostal_id: hostal.id,
            hostal_name: hostal.tittle,
            hostal_type: hostal.entireProperty,
            room_data: JSON.stringify(room)
        })
    }

    function renderPhotosList(hostal) {
        history.push(`/hostal/fotos/listar`, {
            hostal_id: hostal.id,
            hostal_name: hostal.tittle
        })
    }

    function loadPhotos(photos) {
        if (photos.length > 0) {
            return (
                <ListGroup.Item className="ListGroupNoBorders wideList">
                    <Row className="d-flex">
                        <Col>
                            <Toast className="text-left text-sm-center">
                                <Toast.Body className="p-0">
                                    <Image className="uploadedPicture link-li" onClick={e => renderPhotosList(hostal)}
                                           src={apiUri.functions.root + photos[0].photoLarge} fluid rounded/>
                                    <Button onClick={e => renderPhotosList(hostal)} variant="dark"
                                            size="lg"
                                            className="embedButton" rounded>
                                        {photos.length} Foto{photos.length > 1 ? 's' : ''}
                                    </Button>
                                </Toast.Body>
                            </Toast>
                            <li onClick={e => renderPhotosList(hostal)}
                                className="list-unstyled text-info link-li">Actualizar fotos
                            </li>
                        </Col>
                    </Row>
                    <hr/>
                </ListGroup.Item>
            )
        } else {
            return (
                <ListGroup.Item className="ListGroupNoBorders wideList" style={{paddingTop: 0}}>
                    <Row>
                        <Col className="text-center">
                            <span className="text-muted">No existen fotos para este hostal</span>
                        </Col>
                    </Row>
                    <li className="list-unstyled text-info link-li"
                        onClick={e => renderPhotosList(hostal)}>Añadir fotos
                    </li>
                    <hr/>
                </ListGroup.Item>
            )
        }
    }

    const minMaxPrices = (prices) => {
        let min = prices[1],
            max = prices[1]
        for (let i = 2; i < 13; i++) {
            if (parseInt(prices[i]) > parseInt(max)) {
                max = prices[i]
            }
            if (parseInt(prices[i]) < parseInt(min)) {
                min = prices[i]
            }
        }
        return {min: min, max: max}
    }

    function propertyPrice(hostal) {
        let price
        if (hostal.prices) {
            const values = minMaxPrices(hostal.prices)
            if (parseInt(values.min) === parseInt(values.max)) {
                price = `$${values.min} / noche`
            } else
                price = `$${values.min} - $${values.max} / noche`
        } else {
            price = 'No definido'
        }
        if (parseInt(hostal.entireProperty)) {
            return (<ListGroup.Item className="wideList">
                <Row>
                    <Col><h5><b>Propiedad entera</b></h5></Col>
                </Row>
                <Row>
                    <Col><span className="text-muted">{price}</span></Col>
                    <Col className="text-right">
                        <li onClick={() => history.push(`/precios/propiedad`, {
                            hostal: JSON.stringify(hostal)
                        })} className="list-unstyled text-info link-li text-right">Editar precios
                        </li>
                    </Col>
                </Row>
            </ListGroup.Item>)
        }
    }

    function roomPrices(room) {
        let price
        if (room.prices) {
            const values = minMaxPrices(room.prices)
            if (parseInt(values.min) === parseInt(values.max)) {
                price = `$${values.min} / noche`
            } else
                price = `$${values.min} - $${values.max} / noche`
        } else {
            price = 'No definido'
        }
        return price
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={back !== null ? () => history.go(-1) : () => history.push('/hostal/listar')}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row className="align-items-center">
                            {hostal ? <FirstPhoto hostalId={hostal.id}/> : ''}
                            <Col xs={{span: 8}} className="pl-0"><h5 className="font-weight-bold"> {
                                hostal === null
                                    ? 'Cargando datos...'
                                    : hostal.tittle
                            }</h5>
                            </Col>
                            <Col xs={{span: 4}}/>
                            <Col xs={{span: 8}} className="mt-n4 pl-0">
                                <li onClick={() => history.push('/hostal/update/name', {
                                    hostal_id: hostal.id,
                                    hostal_name: hostal.tittle
                                })} className="list-unstyled text-info link-li">Editar nombre
                                </li>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        hostal === null
                            ? 'Cargando datos...'
                            : propertyPrice(hostal)
                    }
                    <ListGroup.Item className="wideList">
                        <Row>
                            <Col xs={{span: 6}} className="mt-2"><h5><b>Descripci&oacute;n</b></h5></Col>
                            <Col xs={{span: 6}} className="text-right">
                                <span onClick={() => history.push('/hostal/update/description', {
                                    hostal_id: hostal.id,
                                    hostal_name: hostal.tittle,
                                    hostal_description: hostal.description
                                })} className="link-li span-add-edit-btn">    
                                    {/* <Icon.PencilFill style={{marginRight: 6, marginLeft: 6, marginTop: -4}}
                                                    size={20}/> */}
                                    <Image src={editDescriptionImg} width={24} height={24} style={{padding: 1, marginRight: 6}}/>
                                    <a>
                                        Editar
                                    </a>
                                </span>
                            </Col>
                            <Col className="text-muted">
                                <span style={{ whiteSpace: 'pre-line' }}>{hostal && hostal.description ? hostal.description : <>No se ha agregado una descripci&oacute;n para este hostal.</>}</span>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row>
                            <Col xs={{span: 6}} className="mt-2"><h5><b>Habitaciones</b></h5></Col>
                            <Col xs={{span: 6}} className="text-right">
                                <span onClick={() => history.push('/hostal/habitacion/adicionar', {
                                        hostal_id: hostal.id,
                                        hostal_name: hostal.tittle,
                                        hostal_type: hostal.entireProperty,
                                        is_edit: true
                                    })} className="link-li span-add-edit-btn"> 
                                    {/* <Icon.PlusCircleFill style={{marginRight: 6, marginTop: -4}}
                                                    size={24}/> */}
                                    <Image src={addRoomImg} width={24} height={24} style={{padding: 1, marginRight: 6}}/>
                                    <a>
                                        Añadir
                                    </a>
                                </span>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        rooms === null
                            ?
                            <>
                                <ListGroup.Item className="px-0 mx-0 wideList">
                                    <Loader/>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 mx-0 wideList">
                                    <Loader/>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 mx-0 wideList">
                                    <Loader/>
                                </ListGroup.Item>
                            </>
                            :
                            rooms.length > 0 && publishRoom.length > 0 ? rooms.map((room, j) => {
                                return (
                                    <ListGroup.Item key={j} className="ListGroupNoBorders wideList pl-4">
                                        <Row>
                                            <Col xs={{span: 7}} className="float-left ml-n3">
                                                <span><Icon.DoorOpenFill size={20}
                                                                         color={room.color && room.color.length > 1 ? room.color : 'currentColor'}
                                                                         className="mt-n2 ml-n2 mr-1"/> {room.name} {parseInt(room.isSuite) ? '(Suite)' : ''}</span>
                                                <br/>
                                            </Col>
                                            <Col xs={{span: 5}} className="text-right">
                                                <div>
                                                    <Form.Check onClick={() => publishRoomHandler(j)} label=''
                                                                id={"status" + room.id}
                                                        // defaultChecked={parseInt(room.published) === 1}
                                                                checked={publishRoom[j]['published']}
                                                                type="switch"/>
                                                </div>
                                            </Col>
                                            {
                                                !parseInt(hostal.entireProperty) ?
                                                    <>
                                                        <Col md={{span: 6}} xs={{span: 7}} style={{marginTop: 5}}><span
                                                            className="text-muted">{roomPrices(room)}</span>
                                                        </Col>
                                                        <Col style={{marginTop: 5}}
                                                             xs={{span: 5}} md={{span: 6}}>
                                                            <li
                                                                className="list-unstyled text-info link-li text-right mr-4"
                                                                onClick={() => history.push(`/precios/habitacion`, {
                                                                    room: JSON.stringify(room),
                                                                    hostal: JSON.stringify(hostal)
                                                                })}>Editar precios
                                                            </li>
                                                        </Col>
                                                    </>
                                                    : ''
                                            }
                                            <Col xs={{span: 12}} className="text-muted py-1">
                                                <Image className="mt-n2 ml-n1" width={25} height={25}
                                                        src={apiUri.functions.root + 'images/iconsBed/1.Single.svg'}/>
                                                <span className="pr-4 ml-n1"> {room.beds.length} cama(s)</span>
                                                <Icon.PersonFill/>&nbsp;
                                                <span>{room.maxGuests} persona(s) máx</span>
                                            </Col>
                                            <Col xs={{span: 12}} className="text-muted">
                                                <Icon.HandThumbsUp/> <span>{
                                                printFeature(features, room)
                                            }</span>
                                                {
                                                    publishRoom.length > 0 && !(publishRoom[j]['published']) ?
                                                        <div className="text-center alert-danger my-2">
                                                            <Col xs={{span: 12}}
                                                                 className="text-danger"><span>Esta habitación no está
                                                                publicada</span>
                                                            </Col>
                                                            <Col xs={{span: 12}} className="text-info">
                                                                <span className="link-li"
                                                                    onClick={() => publishRoomHandler(j)}>Publicar</span>
                                                            </Col>
                                                        </div>
                                                        :
                                                        <div className="text-center my-n2">
                                                        <span
                                                            className="text-hide">Habitación publicada
                                                        </span>
                                                        </div>
                                                }
                                                <li onClick={() => editRoom(room)}
                                                    className="list-unstyled text-info link-li">Editar habitaci&oacute;n
                                                </li>
                                                <hr/>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                )
                            }) : <span className="text-muted text-center">No ha insertado datos de habitaciones</span>
                    }
                    <ListGroup.Item style={{paddingBottom: 0}} className="ListGroupNoBorders wideList">
                        <Row>
                            <Col><h5><b>Fotos</b></h5></Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        photos === null
                            ?
                            <ListGroup.Item className="ListGroupNoBorders wideList">
                                <Row className="d-flex">
                                    <Col sm={{span: 6}} md={{span: 4}} lg={{span: 4}} className="text-center">
                                        <LoaderPhoto/>
                                    </Col>
                                </Row>
                                <hr/>
                            </ListGroup.Item>
                            :
                            loadPhotos(photos)
                    }
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row className="mb-n3">
                            <Col><h5><b>Comodidades</b></h5></Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        hostalAmenities ?
                            (hostalAmenities.length > 0 ?
                                <ListGroup.Item className="ListGroupNoBorders wideList">
                                    {
                                        hostalAmenities.map(({name, image}, key) => {
                                            return (
                                                <li style={{fontSize: 14}} className={key + 1 !== hostalAmenities.length
                                                    ? "list-unstyled text-muted pb-3" : "list-unstyled text-muted"}>
                                                    {/* <Icon.HandThumbsUp size={18} style={{marginRight: 7}}/> */}
                                                    <Image style={{marginRight: 2}} className="mt-n2" width={35} height={35}
                                                        src={apiUri.functions.root + image}/>
                                                    {name}
                                                </li>
                                            )
                                        })
                                    }
                                </ListGroup.Item> :
                                <ListGroup.Item className="ListGroupNoBorders wideList" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <Row>
                                        <Col className="text-center">
                                            <span
                                                className="text-muted">No hay comodidades definidas para este hostal</span>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>)
                            : <span style={{paddingLeft: 0.75 + 'rem'}}>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        </span>
                    }
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <li onClick={() => history.push('/hostal/amenities', {
                            hostal_id: hostal.id,
                            hostal_name: hostal.tittle
                        })}
                            className="list-unstyled text-info link-li">Editar {amenitiesList} comodidades
                        </li>
                        <hr/>
                    </ListGroup.Item>
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row className="mb-n3">
                            <Col><h5><b>Servicios</b></h5></Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        hostalServices ?
                            (hostalServices.length > 0 ?
                                <ListGroup.Item className="ListGroupNoBorders wideList servicios">
                                    {
                                        hostalServices.map(({name, image, free}, key) => {
                                            return (
                                                <li style={{fontSize: 14}} className={key + 1 !== hostalServices.length
                                                    ? "list-unstyled text-muted pb-3" : "list-unstyled text-muted"}>
                                                    {/* <Icon.ClipboardCheck size={18} style={{marginRight: 7}}/> */}
                                                    <Image style={{marginRight: 2}} className="mt-n2" width={35} height={35}
                                                        src={apiUri.functions.root + image}/>
                                                    {name}
                                                    <span style={{
                                                        borderTopLeftRadius: 1.5 + 'rem',
                                                        borderTopRightRadius: 1.5 + 'rem',
                                                        borderBottomLeftRadius: 1.5 + 'rem',
                                                        borderBottomRightRadius: 1.5 + 'rem',
                                                        border: '1px solid grey',
                                                        fontSize: 12
                                                    }}
                                                          className="float-right">
                    {parseInt(free) === 1
                        ? <>&nbsp;&nbsp;Gratis&nbsp;&nbsp;</>
                        : <>&nbsp;&nbsp;Pago&nbsp;&nbsp;</>}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ListGroup.Item> :
                                <ListGroup.Item className="ListGroupNoBorders wideList"
                                                style={{paddingTop: 0, paddingBottom: 0}}>
                                    <Row>
                                        <Col className="text-center">
                        <span
                            className="text-muted">No hay servicios definidos para este hostal</span>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>)
                            : <span style={{paddingLeft: 0.75 + 'rem'}}>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        <div className="py-2">
                        <LoaderLine style={{width: 200}}/>
                        </div>
                        </span>
                    }
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <li onClick={() => history.push('/hostal/services', {
                            hostal_id: hostal.id,
                            hostal_name: hostal.tittle
                        })}
                            className="list-unstyled text-info link-li">Editar {servicesList} servicios
                        </li>
                        <hr/>
                    </ListGroup.Item>
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row className="mb-n3">
                            <Col><h5><b>Ubicación</b></h5></Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row>
                            <Col xs={{span: 2}} className="mediumIconCol">
                                <Icon.SignpostSplitFill size="45px"/>
                            </Col>
                            <Col xs={{span: 9}} className="text-left">
                        <span className="d-block">{
                            hostal === null
                                ? 'Cargando datos...'
                                :
                                hostal.addess ? hostal.addess : 'Dirección de la casa'
                        }</span>
                                {
                                    hostal === null
                                        ? 'Cargando datos...'
                                        :
                                        hostal.addess ?
                                            <li onClick={e => history.push('/hostal/direccion/adicionar', {
                                                hostal_id: hostal.id,
                                                hostal_name: hostal.tittle,
                                                hostal_type: hostal.entireProperty,
                                                edit: JSON.stringify({
                                                    hostal_destination: hostal.destinationId,
                                                    hostal_address: hostal.addess,
                                                    hostal_province: hostal.provinceId
                                                })
                                            })} className="list-unstyled text-info link-li">Editar
                                                dirección</li> :
                                            <li onClick={e => history.push('/hostal/direccion/adicionar', {
                                                hostal_id: hostal.id,
                                                hostal_name: hostal.tittle,
                                                hostal_type: hostal.entireProperty
                                            })} className="list-unstyled text-info link-li">Añadir
                                                dirección</li>
                                }
                            </Col>
                        </Row>
                        <br/>
                        <Row className="align-items-center">
                            <Col xs={{span: 2}} className="mediumIconCol"><Icon.GeoAltFill
                                size="45px"/></Col>
                            <Col xs={{span: 9}} className="text-left">
                                <span className="d-block">Marcador en el mapa</span>
                                {
                                    hostal === null
                                        ? 'Cargando datos...'
                                        :
                                        editMapLocationManage(hostal)
                                }
                            </Col>
                        </Row>
                        <hr/>
                    </ListGroup.Item>
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row className="link-li" onClick={() => history.push(`/hostal/update/minimum/stay`, {
                                        hostal_id: hostal.id,
                                        hostal_name: hostal.tittle,
                                        min_stay: hostal.minStay
                                    })}>
                            <Col>
                                <h5><b>Estancia m&iacute;nima</b></h5>
                                <p style={{textAlign: 'justify'}}>{hostal ? `El hostal solo admite estancias por un mínimo de ${hostal.minStay} noche${hostal.minStay > 1 ? 's' : ''}.` : 'Cargando datos...'}</p>
                                <li className="list-unstyled text-info">
                                    Editar estancia m&iacute;nima
                                </li>
                            </Col>
                        </Row>
                        <hr/>
                    </ListGroup.Item>
                </ListGroup>
                <ListGroup.Item className="ListGroupNoBorders wideList">
                    <Row className="link-li" onClick={() => parseInt(hostal.entireProperty) === 1
                                    ? history.push(`/external/calendar/import`, {
                                        hostal_id: hostal.id,
                                        hostal_name: hostal.tittle,
                                        room: false,
                                    }) : history.push(`/import/selection`, {
                                        hostal_id: hostal.id,
                                        hostal_name: hostal.tittle,
                                        hostal_rooms: rooms
                                    })}>
                        <Col>
                            <h5><b>Calendarios externos</b></h5>
                            <p style={{textAlign: 'justify'}}>Sincroniza tus calendarios con otras plataformas como Airbnb, Expedia, VRBO, etc. y evita reservas dobles.
                            </p>
                            <li className="list-unstyled text-info">
                                Editar calendarios externos
                            </li>
                        </Col>
                    </Row>
                </ListGroup.Item>
            </div>
        </LayoutOne>
    );

    function editMapLocationManage(hostal) {
        if (hostal.addess) {
            return (
                hostal.lat && hostal.lng
                    ? <div><span className="text-success">Hostal ubicado</span>
                        <li onClick={() => manageUbication(hostal, 'editar')}
                            className="list-unstyled text-info link-li">Editar
                            ubicación
                        </li>
                    </div>
                    : <li onClick={() => manageUbication(hostal, 'ubicar')}
                          className="list-unstyled text-info link-li">Ubicar hostal</li>
            )
        } else {
            return (
                <li onClick={() => history.push('/hostal/direccion/adicionar', {
                    hostal_id: hostal.id,
                    hostal_name: hostal.tittle,
                    hostal_type: hostal.entireProperty
                })}
                    className="list-unstyled text-info">Ubicar hostal</li>
            )
        }
    }

    function manageUbication(hostal, action) {
        if (action === 'editar') {
            history.push('/hostal/ubicacion', {
                hostal_name: hostal.tittle,
                hostal_id: hostal.id,
                hostal_type: hostal.entireProperty,
                is_edit: true,
                locateIn: JSON.stringify({
                    lat: hostal.lat,
                    lng: hostal.lng,
                    z: 14
                })
            })
        } else {
            fetch(apiUri.functions.listDestinations)
                // fetch('http://localhost:8008/amortizacion/list/destinations')
                .then((response) => response.json())
                .then((data) => {
                    data.destinations.map(dest => {
                        if (parseInt(dest.id) === parseInt(hostal.destinationId)) {
                            history.push('/hostal/ubicacion', {
                                hostal_name: hostal.tittle,
                                hostal_id: hostal.id,
                                hostal_type: hostal.entireProperty,
                                locateIn: JSON.stringify({
                                    lat: dest.lat,
                                    lng: dest.lng,
                                    z: dest.z
                                })
                            })
                        }
                    })
                })
        }
    }
}

export default Listado;