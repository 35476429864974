import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, ListGroup, Form, Button } from 'react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { ApiContext } from "../../State/api-context";
import moment from "moment"
import 'moment/locale/es'
import { LayoutOne } from "../Layout";
// import iconHome from "../../Assets/images/icon_home_blue.png";
import iconHome from "../../Assets/images/icons/1x/path_493.png";
import CommonTitle from "../Utils/CommonTitle";
import TextareaAutosize from 'react-autosize-textarea';
import { undefinedState } from "../Utils/Utils";
import LineLoader from "../Loaders/Line"
import ReservationLoader from "../Loaders/Reserva"
import "../../Assets/css/smooth.css"

function Detalles() {
    const [reservation, setReservation] = useState(null)
    const { state } = useLocation()
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [hostal, setHostal] = useState(null)
    // const [countries, setCountries] = useState(null)
    const [messages, setMessages] = useState(null)
    const [ownerMessage, setOwnerMessage] = useState(null)
    const [sendButtonDisabled, setSendButtonDisabled] = useState(true)
    const loadData = <LineLoader style={{ maxWidth: 50 + "%" }} />
    const messagesSection = useRef(null)
    let history = useHistory()
    const [showRoomReservationDetails, setShowRoomReservationDetails] = useState(null)
    const [showHostalReservationDetails, setShowHostalReservationDetails] = useState(false)

    const [messageBtnVisible, setMessageBtnVisible] = useState(false)
    const writeSMSArea = useRef(null)
    const textSize = '1rem'
    const divContainerRef = useRef(null)

    const executeScroll = () => {
        setTimeout(() => {
            messagesSection.current.scrollIntoView({ behavior: "smooth" })
        }, 500)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        fetch(apiUri.functions.ownerListReservations(userData.owner_id, userData.owner_hash, `&reservationId=${state.reservation_id}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error && data.count > 0) {
                    const reservation = data.reservations[0]
                    if (!reservation.daysBlocked)
                        setReservation(reservation)

                    const roomReservationShowStatus = []
                    if (reservation.rooms && reservation.rooms.length > 0) {
                        reservation.rooms.map((room) => {
                            roomReservationShowStatus.push({id: room.id, show: false})
                        })
                    }
                    setShowRoomReservationDetails(roomReservationShowStatus)
                }
            }, (error) => {

            })
        // fetch(apiUri.functions.listCountryPhoneCodes)
        //     .then(response => response.json())
        //     .then((data) => {
        //         setCountries(data.country);
        //     })
        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${state.hostal_id}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error && data.count > 0) {
                    setHostal(data.hostals[0])
                }
            }, (error) => {

            })
        fetchMessages(state.reservation_id, state.messages_section)
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [state])

    function handleScroll() {
        (window.innerHeight + window.scrollY) < (document.body.offsetHeight)
        // document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop) > document.documentElement.offsetHeight / 20
            // window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight
            ? setMessageBtnVisible(true)
            : setMessageBtnVisible(false)
    }

    function fetchMessages(id, messagesSection = false, newMessage = false) {
        fetch(apiUri.functions.ownerListMessages(userData.owner_id, userData.owner_hash, '', `&reservationId=${id}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setMessages(data.messages)
                        setMessageBtnVisible(data.messages.length > 3 ? true : false)
                        if (messagesSection)
                            executeScroll()
                        if (newMessage) {
                            setMessageBtnVisible(false)
                            window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })
                            // endPageRef.current.scrollIntoView({behavior: "smooth"})
                        }
                    } else {
                        setMessages([])
                    }
                    // setTimeout(() => {
                    //     fetchMessages(id)
                    // }, 60000)
                }
            }, (error) => {

            })
    }

    const goToBottom = () => {
        // window.scrollTo({
        //     top: document.body.scrollHeight,
        //     behavior: "smooth"
        // })
        // divContainerRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        divContainerRef.current.scrollIntoView(false)
        const focusInput = setInterval(() => {
            if ((window.window.innerHeight + window.window.scrollY) >= (document.body.offsetHeight - 50)) {
                writeSMSArea.current.focus()
                clearInterval(focusInput)
            }
        }, 250)
    }

    const handleShowDetails = index => {
        const list = [...showRoomReservationDetails]
        list[index]['show'] = !list[index]['show']
        setShowRoomReservationDetails(list)
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false} showCopy={false}
            title={
                <CommonTitle goTo={() => history.go(-1)} title={hostal ? hostal.tittle : loadData} />
            }>
            <div className="container-fluid" ref={divContainerRef}>
                {
                    reservation/* && countries*/ ?
                        <ListGroup variant="flush">
                            <ListGroup.Item className="wideList">
                                <Row>
                                    <Col
                                        xs={{ span: 6 }}><h4 className="font-weight-bold">
                                            <b>{`${reservation.guestName} ${reservation.guestLastname}`}</b>
                                        </h4></Col>
                                    <Col xs={{ span: 6 }} className="text-right">
                                        <span style={{
                                            backgroundColor: `#${reservation.statusColor}`,
                                            borderRadius: 5,
                                            padding: 3
                                        }}
                                            className="text-white"><b
                                                style={{ margin: 3 }}>{reservation.statusText}</b></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 12 }}
                                        className="text-muted mb-1"><span
                                            style={{ fontSize: textSize }}>{reservation.guestCountryLanguageText}</span></Col>
                                    <Col xs={{ span: 12 }} className="mb-1">
                                        <Icon.UpcScan size={textSize} className="mt-n1" />
                                        <span style={{ fontSize: textSize }}
                                            className="pt-2 pl-1"> Id de reserva: <strong>{reservation.code}</strong></span>
                                    </Col>
                                    <Col xs={{ span: 12 }} className="mb-1">
                                        <span className="align-items-center">
                                            <Icon.Calendar2Week size={textSize} className="mt-n1" />
                                            <span style={{ fontSize: textSize }}
                                                className="pt-2 pl-2">{timeInHostal(reservation)} ({reservation.totalNights} {reservation.totalNights > 1 ? 'noches' : 'noche'})</span>
                                        </span>
                                    </Col>
                                </Row>
                                {
                                    reservation.rooms && showRoomReservationDetails ? reservation.rooms.map((room, key) => {
                                        return (
                                            <>
                                                <Row className="mb-1">
                                                    <Col xs={{ span: 6 }}>
                                                        <span className="align-items-center">
                                                            <Icon.DoorOpenFill
                                                                color={room.color ? room.color : 'currentColor'}
                                                                size={textSize} className="mt-n1 mr-2" />
                                                            <span className="pt-2" style={{ fontSize: textSize }}>
                                                                {room.name + (parseInt(room.isSuite) ? ' (Suite)' : '')}
                                                            </span>
                                                        </span>
                                                    </Col>
                                                    {/*<Col xs={{ span: 4 }}
                                                        className="text-right"><span
                                                            style={{ fontSize: textSize }}>{`$${room.price}`}&nbsp;/&nbsp;noche</span></Col>*/}
                                                    <Col xs={{ span: 6 }}
                                                        className="text-right"><span
                                                            style={{ fontSize: textSize }}>{`$${(room.price * reservation.totalNights).toFixed(2)}`}</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{fontSize: textSize}} className="col-12 link-li text-info" onClick={() => handleShowDetails(key)}>
                                                        {
                                                            showRoomReservationDetails[key]['show'] ? 'Ocultar detalles' : 'Ver detalles'
                                                        }
                                                    </Col>
                                                </Row>
                                                <div className={showRoomReservationDetails[key]['show'] ? 'fadeIn' : 'fadeOut'}>
                                                    {
                                                        room.details.map(({description, price}) => {
                                                            return (
                                                                <Row className="price-detail-row mb-1">
                                                                    <Col className="col-6 text-left">{description}</Col>
                                                                    <Col className="col-6 text-right">{`$${price.toFixed(2)}`}</Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                    <hr/>
                                                </div>
                                            </>
                                        )
                                    }) : entireHostalPrice()
                                }
                                <Row className="mt-1">
                                    <Col xs={{ span: 9 }} className="text-right mb-1"><span style={{ fontSize: textSize }}>Servicio CubaHostal</span></Col>
                                    <Col xs={{ span: 3 }}
                                        className="text-right mb-1"><span
                                            style={{ fontSize: textSize }}>{`$${reservation.cubahostalCost}`}</span></Col>
                                    <Col xs={{ span: 9 }} className="text-right"><span style={{ fontSize: textSize }}>Pago online</span></Col>
                                    <Col xs={{ span: 3 }}
                                        className="text-right"><span
                                            style={{ fontSize: textSize }}>{`- $${reservation.cubahostalCost}`}</span></Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className="wideList">
                                <Row>
                                    <Col xs={{ span: 9 }} className="text-right"><span
                                        style={{ fontSize: textSize }}>* Total:</span></Col>
                                    <Col xs={{ span: 3 }} className="text-right"><span
                                        style={{ fontSize: textSize }}><b>{`$${reservation.hostalCost}`}</b></span></Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        : <ReservationLoader style={{ maxWidth: 100 + "%" }} />
                }
                <Row className="pb-3">
                    <Col xs={{ span: 12 }}><span
                        style={{ fontSize: textSize }}>* Total a cobrar en efectivo en Cuba en USD.</span></Col>
                </Row>
                <Card className="mb-3" ref={messagesSection}>
                    <Card.Body>
                        <Row>
                            <Col xs={{ span: 2 }} className="mediumIconColDashboard">
                                <img src={iconHome} width="50" height="50" className="py-2" alt="Icon Hostal" />
                            </Col>
                            <Col xs={{ span: 10 }}>
                                <Row>
                                    <Col xs={{ span: 12 }} className="pl-4">
                                        <span
                                            style={{ fontSize: textSize }}>Servicio CubaHostal: {reservation ? `$${reservation.cubahostalCost}` : loadData} usd</span><br />
                                        <span style={{ fontSize: textSize }}>El huésped ya pagó esa cantidad.
                                            <Link className="text-info" to="/ayuda"
                                                style={{
                                                    textDecoration: "underline",
                                                    marginLeft: 3,
                                                    fontSize: textSize
                                                }}>Leer más</Link>
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div>
                    <ListGroup variant="flush" className="mt-4">
                        {
                            messages ?
                                messages.map((message) => {
                                    if (message.section === 1) {
                                        return <ListGroup.Item className="ListGroupNoBorders wideList"><Row>
                                            <Col style={{ fontSize: textSize }} xs={{ span: 12 }}
                                                className='text-center text-capitalize'>
                                                {moment(message.text_1, 'Y-M-D').locale('es').format('MMMM D')}
                                            </Col>
                                        </Row></ListGroup.Item>
                                    }
                                    if (message.section === 2) {
                                        return <Row><Col xs={{ span: 12 }}>
                                            <div style={{ border: 0, borderRadius: 50, backgroundColor: '#e9ecef' }}
                                                className="alert alert-dark"
                                                role="alert">
                                                <Icon.Chat size={textSize} className="mr-2 mt-n1" /><span style={{fontSize: textSize}}>Alg&uacute;n texto</span>
                                            </div>
                                        </Col></Row>
                                    }
                                    if (message.section === 3) {
                                        return (
                                            <ListGroup.Item className="ListGroupNoBorders wideList">
                                                <Row className="align-items-center">
                                                    <Col xs={{ span: 2 }}
                                                        className="mediumIconCol mt-n2 mr-n1"><Icon.PersonCircle
                                                            size="35px" /></Col>
                                                    <Col xs={{ span: 10 }} className="pl-0 mt-n2">
                                                        <span style={{ fontSize: textSize }}>{message.text_1}</span>
                                                        <span style={{ fontSize: textSize }}
                                                            className="text-muted"> &middot; {message.text_2}</span>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center chatBubbleLeft">
                                                    <Col className="ml-2" xs={{ span: 12 }}><p className="text-muted"
                                                        style={{ fontSize: textSize }}>{message.text_3}</p>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )
                                    }
                                })
                                : loadData
                        }
                    </ListGroup>
                </div>
                <Form>
                    <div className={messageBtnVisible
                        ? "row fixed-bottom justify-content-end mb-5 mr-4 visible"
                        : "row fixed-bottom justify-content-end mb-5 mr-4 invisible"}>
                        <Button onClick={() => goToBottom()}
                            style={{ borderRadius: 25, padding: ".7rem 1rem" }} variant="secondary">
                            Escribir mensaje
                        </Button>
                    </div>
                    <div className={messages
                        ? "row justify-content-center px-3 pt-1 mb-3 visible"
                        : "row justify-content-center px-3 pt-1 mb-3 invisible"}>
                        <div className="input-group" style={{ maxWidth: 600 }}>
                            <TextareaAutosize
                                ref={writeSMSArea}
                                style={{
                                    borderTopLeftRadius: 25,
                                    borderBottomLeftRadius: 25,
                                    borderRight: "none",
                                    minHeight: 54,
                                    paddingTop: 13
                                }}
                                value={ownerMessage}
                                onChange={e => manageOwnerMessage(e.target.value)}
                                aria-label="Escribe un mensaje" aria-describedby="basic-addon2"
                                maxRows={6}
                                className="form-control"
                                placeholder="Escribe un mensaje" />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2"
                                    style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25, borderLeft: "none" }}>
                                    <Button variant="" disabled={sendButtonDisabled} onClick={sendMessage}
                                        className="msgSendButton">
                                        <Icon.ChevronRight className="sendBttnIco"
                                            style={{ border: "1px solid #525252", borderRadius: 50 + "%", padding: 3 }}
                                            color="#525252" size={24} />
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </LayoutOne>
    )

    function manageOwnerMessage(value) {
        value.length > 0 && value.length !== '' ? setSendButtonDisabled(false) : setSendButtonDisabled(true)
        setOwnerMessage(value)
    }

    function sendMessage() {
        writeSMSArea.current.focus()
        let fd = new FormData()
        fd.append('message', ownerMessage)
        fetch(apiUri.functions.ownerInsertMessage(userData.owner_id, userData.owner_hash, reservation.id), {
            method: 'POST',
            body: fd
        }).then((resp) => resp.json()).then((data) => {
                if (!data.error) {
                    setOwnerMessage('')
                    setSendButtonDisabled(true)
                    fetchMessages(reservation.id, false, true)
                }
            }, (error) => {

            })
    }

    function entireHostalPrice() {
        return (
            <>
                <Row>
                    <Col style={{fontSize: textSize}} className="col-12 link-li text-info" onClick={() => setShowHostalReservationDetails(!showHostalReservationDetails)}>
                        {
                            showHostalReservationDetails ? 'Ocultar detalles' : 'Ver detalles'
                        }
                    </Col>
                </Row>
                <div className={showHostalReservationDetails ? 'fadeIn' : 'fadeOut'}>
                    {
                        reservation.details && reservation.details.map(({description, price}) => {
                            return (
                                <Row className="price-detail-row mb-1">
                                    <Col className="col-6 text-left">{description}</Col>
                                    <Col className="col-6 text-right">{`$${price.toFixed(2)}`}</Col>
                                </Row>
                            )
                        })
                    }
                    <hr/>
                </div>
                <Row className="mb-1">
                    <Col xs={{ span: 9 }}
                        className="text-right"><span style={{ fontSize: textSize }}>Costo del hostal:</span></Col>
                    <Col xs={{ span: 3 }}
                        className="text-right"><span
                            style={{ fontSize: textSize }}>{reservation ? `$${reservation.hostalCost}` : loadData}</span></Col>
                </Row>
            </>
        )
    }

    // function getCountry(reservation) {
    //     const country = countries.find(obj => {
    //         return obj.code.toLowerCase() === reservation.guestCountry.toLowerCase()
    //     })
    //     return country ? country.name : 'Desconocido'
    // }

    function timeInHostal(reservation) {
        const ini = moment(reservation.checkinDate, 'Y-M-D')
        const end = moment(reservation.ckeckoutDate, 'Y-M-D')
        if (ini.month() === end.month()) {
            return (
                `${ini.format('D')} - ${end.format('D')} ${end.format('MMMM')}, ${end.format('Y')}`
            )
        } else {
            return (
                `${ini.format('D')} ${ini.format('MMMM')} - ${end.format('D')} ${end.format('MMMM')}, ${end.format('Y')}`
            )
        }
    }
}

export default Detalles;