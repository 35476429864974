import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Image, ListGroup, Row} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import moment from "moment"
import 'moment/locale/es'
import Loader from '../../Components/Loaders/Info'
import FirstPhoto from "../Utils/FirstPhoto";
import imageHome from "../../Assets/images/home.png"

function Home() {

    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [notices, setNotices] = useState(null)
    const apiUri = useContext(ApiContext)
    const [reservations, setReservations] = useState(null)
    const [unreadMessages, setUnreadMessages] = useState(null)
    const [hostals, setHostals] = useState(null)
    // const [countries, setCountries] = useState(null)
    let history = useHistory()
    const [scroll, setScroll] = useState(0)
    const borderRadius = 75
    const minHeight = 45
    const minWidth = '30%'
    const maxWidth = '48%'
    const fontSize = 18
    const [isFetching, setIsFetching] = useState(false)
    const [pagination, setPagination] = useState(1)
    // const [emptyBoard, setEmptyBoard] = useState([{notice: false}, {reservation: false}, {message: false}])
    const [moreSMS, setMoreSMS] = useState(true)

    function handleScroll() {
        const scrollCheck = window.scrollY > 0
        if (scrollCheck !== scroll) {
            setScroll(scrollCheck)
        }
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return
        setIsFetching(true)
    }

    useEffect(() => {
        if (!isFetching) return
        moreMessages()
    }, [isFetching])

    const moreMessages = () => {
        getMessages()
    }

    const getMessages = () => {
        fetch(apiUri.functions.ownerListMessages(userData.owner_id, userData.owner_hash, `&pagination=${pagination}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    // if (data.messages) {
                    if (data.count > 0) {
                        if (unreadMessages) {
                            setUnreadMessages(unreadMessages.concat(data.messages))
                        } else {
                            setUnreadMessages(data.messages)
                        }
                        setPagination(pagination + 1)
                        setMoreSMS(true)
                    } else {
                        if (pagination === 1) {
                            setUnreadMessages([])
                        }
                        setMoreSMS(false)
                    }
                    // }
                    setIsFetching(false)
                }
            }, (error) => {

            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetch(apiUri.functions.listOwnerNotices(userData.owner_id, userData.owner_hash))
            .then((response) => response.json())
            .then((data) => {
                data.count > 0 ? setNotices(data.notices) : setNotices([])
            }, (error) => {

            })
        fetch(apiUri.functions.ownerListReservations(userData.owner_id, userData.owner_hash))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    const unblocked = []
                    if (data.count > 0) {
                        data.reservations.map((reservation) => {
                            if (!reservation.daysBlocked && parseInt(reservation.unread) === 1) {
                                unblocked.push(reservation)
                            }
                        })
                        setReservations(unblocked)
                    } else {
                        setReservations(unblocked)
                    }
                }
            }, (error) => {

            })
        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    data.count > 0 ? setHostals(data.hostals) : setHostals([])
                }
            }, (error) => {

            })
        // fetch(apiUri.functions.listCountryPhoneCodes)
        //     .then(response => response.json())
        //     .then((data) => {
        //         setCountries(data.country);
        //     })
        getMessages()
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const accessConstructor = (type, link) => {
        const hostalId = link.split('id=')[1]

        const hostal = hostals.find(obj => {
            return parseInt(hostalId) === parseInt(obj.id)
        })
        if (hostal) {
            if (type === 'photo') {
                history.push(`/hostal/fotos/listar`, {
                    hostal_id: hostal.id,
                    hostal_name: hostal.tittle
                })
            } else {
                fetch(apiUri.functions.listDestinations())
                    .then((response) => response.json())
                    .then((data) => {
                        const dest = data.destinations.find(obj => {
                            return obj.id === hostal.destinationId
                        })
                        if (dest) {
                            history.push('/hostal/ubicacion', {
                                hostal_name: hostal.tittle,
                                hostal_id: hostal.id,
                                hostal_type: hostal.entireProperty,
                                is_edit: true,
                                locateIn: JSON.stringify({
                                    lat: dest.lat,
                                    lng: dest.lng,
                                    z: dest.z
                                })
                            })
                        } else {
                            history.push('/hostal/direccion/adicionar', {
                                hostal_id: hostal.id,
                                hostal_name: hostal.tittle,
                                hostal_type: hostal.entireProperty
                            })
                        }
                    })
            }
        }
    }

    const houseDetails = (reservation) => {
        return <>{hostalInReservation(reservation)}<br/>{roomsInReservation(reservation)}</>
    }

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between fixed-top mt-7 px-3 hostalLinkButtons borderBottomGradient"
                 id={(scroll ? 'borderBottomGradient' : '')}>
                <Link className="btn btn-light-custom pt-2 col-6"
                      style={{borderRadius: borderRadius, minHeight: minHeight, minWidth: minWidth, maxWidth: maxWidth}}
                      to={
                          hostals && hostals.length ?
                              (hostals.length > 1 ? "/reservas/hostales" : {
                                  pathname: '/reservas/listado',
                                  state: {
                                      hostal_id: hostals[0].id,
                                      hostal_name: hostals[0].tittle,
                                      entire_property: hostals[0].entireProperty
                                  }
                              }) : ''
                      }>
                    <Icon.Calendar2Check size={fontSize}/><span style={{fontSize: fontSize, verticalAlign: 'middle'}}> Reservas</span>
                </Link>
                <Link className="btn btn-light-custom pt-2 col-6"
                      style={{borderRadius: borderRadius, minHeight: minHeight, minWidth: minWidth, maxWidth: maxWidth}}
                      to={
                          hostals && hostals.length ?
                              (hostals.length > 1 ? "/reservas/calendario" : {
                                  pathname: '/reservas/custom/calendar',
                                  state: {
                                      hostal_id: hostals[0].id,
                                      hostal_name: hostals[0].tittle,
                                      entire_property: hostals[0].entireProperty
                                  }
                              }) : ''
                      }>
                    <Icon.Calendar2Week size={fontSize}/><span style={{fontSize: fontSize, verticalAlign: 'middle'}}> Calendario</span>
                </Link>
            </div>
            <div style={{marginTop: 3.5 + 'rem'}}>
                {
                    notices === null
                        ?
                        <Card className="mb-3">
                            <Card.Body>
                                <Row>
                                    <Loader/>
                                </Row>
                            </Card.Body>
                        </Card>
                        :
                        notices.map(({link, linkText, text, tittle}) => {
                            let icon, type
                            if (tittle.split('fotos').length > 1) {
                                icon = <Icon.Image size="35px"
                                                    className="borderedIcon mt-1 backgroundIconDefault"/>
                                type = 'photo'
                            } else {
                                icon = <Icon.GeoAltFill size="35px"
                                                       className="borderedIcon mt-1 backgroundIconDefault"/>
                                type = 'ubication'
                            }
                            return (
                                <Card className="mb-3 link-li" onClick={e => accessConstructor(type, link)}>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={{span: 2}} className="mediumIconColDashboard">
                                                {icon}
                                            </Col>
                                            <Col xs={{span: 10}}>
                                                <Row>
                                                    <Col xs={{span: 12}}><h5 className="font-weight-bold"> {tittle}</h5>
                                                    </Col>
                                                    <Col xs={{span: 12}}><p className="text-muted">{text}</p></Col>
                                                    <Col xs={{span: 12}}>
                                                        <li className="list-unstyled text-info">{linkText}</li>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )
                        })
                }
                {
                    reservations
                        ? (reservations.length > 0 ? <Card className="mb-3">
                            <Card.Body>
                                <Row><Col>{
                                    reservations.map((reservation, i) => {
                                        return (
                                            <><Row onClick={() => goToReservation(reservation)}>
                                                {
                                                    i === 0 ? <Col xs={{span: 8}}><h5 className="font-weight-bold">
                                                        Reservas nuevas</h5>
                                                    </Col> : null
                                                }
                                                <Col
                                                    xs={{span: 6}}><span><b>{`${reservation.guestName} ${reservation.guestLastname}`}</b></span></Col>
                                                <Col xs={{span: 6}} className="text-right">
                                                            <span style={{
                                                                backgroundColor: "#00CB00",
                                                                borderRadius: 5,
                                                                padding: 3
                                                            }}
                                                                  className="text-white">Nueva</span>
                                                </Col>
                                                <Col className="text-muted"
                                                     xs={{span: 12}}><span>{reservation.guestCountryLanguageText}</span></Col>
                                                <Col xs={{span: 12}}>
                                                    <Icon.UpcScan size="14px" className="mt-n1"/>
                                                    <span className="pt-2 pl-1"> Id de reserva: <strong>{reservation.code}</strong></span>
                                                </Col>
                                                <Col xs={{span: 12}}>
                                                        <span className="align-items-center">
                                                            <Icon.Calendar2Week size="14px" className="mt-n1"/><span
                                                            className="pt-2 pl-2">{
                                                            timeInHostal(reservation)
                                                        } ({reservation.totalNights} {reservation.totalNights > 1 ? 'noches' : 'noche'})</span>
                                                        </span>
                                                </Col>
                                                <FirstPhoto hostalId={reservation.hostalId} smallImage={true}/>
                                                <Col xs={{span: 9}} className="text-left pl-0">
                                                        <span>{hostals ?
                                                            houseDetails(reservation) :
                                                            <Loader/>
                                                        }</span>
                                                </Col>
                                                <Col xs={{span: 12}} className="text-right"><span>Total:<b
                                                    className="pl-3">${reservation.totalCost}</b></span></Col>
                                            </Row>
                                                <hr/>
                                            </>
                                        )
                                    })
                                }
                                </Col>
                                </Row>
                            </Card.Body>
                        </Card> : "") : <Row>
                            <Loader/>
                        </Row>
                }
                {
                    unreadMessages ?
                        (unreadMessages.length > 0 ?
                            <>
                                <h5 className="font-weight-bold">Todos los mensajes</h5>
                                <ListGroup variant="flush">
                                    {
                                        unreadMessages.map((message, index) => {
                                            const now = moment().locale('es'),
                                                smsDate = moment(message.text_4, 'Y-M-D H:m:i').locale('es')

                                            let _format
                                            if (now.diff(smsDate, 'days') >= 1) {
                                                if (now.diff(smsDate, 'days') === 1) {
                                                    _format = "Ayer"
                                                } else
                                                    _format = smsDate.format('MMMM D')
                                            } else {
                                                if (now.diff(smsDate, 'hours') >= 1) {
                                                    _format = smsDate.format(`[Hace] ${now.diff(smsDate, 'hours')} [hora(s)]`)
                                                } else {
                                                    _format = smsDate.format(`[Hace] ${now.diff(smsDate, 'minutes')} [minuto(s)]`)
                                                }
                                            }
                                            return (
                                                <ListGroup.Item onClick={() => history.push('/reservas/detalles', {
                                                    hostal_id: message.hostalId,
                                                    reservation_id: message.reservationId,
                                                    messages_section: true
                                                })} className="wideList link-li"
                                                                style={index + 1 === unreadMessages.length ? {borderBottom: '1px solid rgba(0,0,0,.125)'} : null}>
                                                    <Row>
                                                        <Col md={{span: 8}} xs={{span: 7}} className="text-left">
                                                        <span
                                                            className="font-weight-bold"> {message.text_1}</span>
                                                        </Col>
                                                        <Col md={{span: 4}} xs={{span: 5}} className="text-right">
                                                            <span className="text-capitalize">{_format}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={message.text_5 ? {span: 9} : {span: 12}}
                                                             className="my-2">
                                                            <p style={{
                                                                marginBottom: 0,
                                                                lineHeight: 1.3
                                                            }}
                                                               className={message.text_2_bold ? "font-weight-bold" : ""}>
                                                                {message.text_2}
                                                            </p>
                                                        </Col>
                                                        {
                                                            message.text_5
                                                                ? <Col xs={{span: 3}} className="text-right">
                                                            <span style={{
                                                                backgroundColor: message.color,
                                                                borderRadius: 5,
                                                                padding: 3
                                                            }}
                                                                  className="text-white">{message.text_5}</span>
                                                                </Col> : ""
                                                        }
                                                    </Row>
                                                    {
                                                        hostals && hostals.length > 1
                                                            ? <Row className="msgPhoto mt-1">
                                                                <FirstPhoto hostalId={message.hostalId} smallImage={true}/>
                                                                <Col xs={{span: 9}}
                                                                     className="text-left pl-1 mt-1 text-muted">
                                                                    {/* <span>{message.reservationId}</span> */}
                                                                    {/* <span className="px-2">&middot;</span> */}
                                                                    <span>{message.text_3}</span>
                                                                </Col>
                                                                {/* <Col sm={12}><hr/></Col> */}
                                                            </Row>
                                                            : <Row className="mt-1">
                                                                <Col xs={{span: 12}}>
                                                                    <span className="text-muted">{message.text_3}</span>
                                                                </Col>
                                                            </Row>

                                                    }
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                    {
                                        !moreSMS ?
                                            <span className="text-muted text-center mt-3">No hay m&aacute;s mensajes para mostrar</span> : null
                                    }
                                </ListGroup>
                            </> : "")
                        : <Row>
                            <Loader/>
                        </Row>
                }
                {
                    notices && notices.length === 0 && reservations && reservations.length === 0 && unreadMessages && unreadMessages.length === 0
                        ? <div className="py-4 col-12 text-center">
                            <img
                                src={imageHome}
                                className="grayscale-img-0"
                                // width="150"
                                // height="130"
                                alt="Card Bookmark"/>
                        </div>
                        : null
                }
            </div>
        </div>
    )

    function timeInHostal(reservation) {
        const ini = moment(reservation.checkinDate, 'Y-M-D')
        const end = moment(reservation.ckeckoutDate, 'Y-M-D')
        if (ini.month() === end.month()) {
            return (
                `${ini.format('D')} - ${end.format('D')} ${end.format('MMMM')}, ${end.format('Y')}`
            )
        } else {
            return (
                `${ini.format('D')} ${ini.format('MMMM')} - ${end.format('D')} ${end.format('MMMM')}, ${end.format('Y')}`
            )
        }
    }

    function hostalInReservation(reservation) {
        const hostal = hostals.find(obj => {
            return parseInt(reservation.hostalId) === parseInt(obj.id)
        })
        if (hostal) {
            return <span>{hostal.tittle}</span>
        }
    }

    // function getCountry(reservation) {
    //     const country = countries.find(obj => {
    //         return obj.code.toLowerCase() === reservation.guestCountry.toLowerCase()
    //     })
    //     return country ? country.name : 'Desconocido'
    // }

    function roomsInReservation(reservation) {
        const hostal = hostals.find(obj => {
            return parseInt(reservation.hostalId) === parseInt(obj.id)
        })
        if (parseInt(hostal.entireProperty) === 0) {
            let outP = [],
                contHab = 1,
                contSuit = 1
            if (reservation.rooms) {
                reservation.rooms.map((room) => {
                    outP.push(
                        <>
                            <Icon.DoorOpenFill color={room.color ? room.color : 'currentColor'}
                                                size="14px" className="mt-n1"/>
                            <span className="pt-2 pl-2">{room.name + (parseInt(room.isSuite) ? ' (Suite)' : '')}</span><br/>
                        </>
                    )
                })
            }
            return outP
        }
    }

    function goToReservation(reservation) {
        history.push('/reservas/detalles', {
            reservation_id: reservation.id,
            hostal_id: reservation.hostalId
        })
    }
}

export default Home;