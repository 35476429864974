import React, {useContext, useEffect, useState} from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import {Form, Image, Alert, Button} from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import {useHistory, useLocation} from "react-router-dom";
import {ApiContext} from "../../State/api-context";
import Select from "react-select";
import {ThemeContext} from "../../State/theme-context";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import ColorsFromHostalRooms from "../Utils/ColorFromHostalRooms";
import {undefinedState} from "../Utils/Utils";
import Loader from "../Loaders/Line"

function CrearHabitacion() {

    const customStyles = useContext(ThemeContext)
    const apiUri = useContext(ApiContext)
    const {state} = useLocation()
    const [hostalName, setHostalName] = useState(null)
    const [hostalId, setHostalId] = useState(null)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [showPrice, setShowPrice] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [roomType, setRoomType] = useState({value: 0, label: 'Habitación normal'})
    const [guests, setGuests] = useState({value: 2, label: '2 huéspedes'})

    //recibe un json con los tipos de camas
    const [bedTypes, setBedTypes] = useState(null)
    //recibe un json con las caracteristicas de la habitacion
    const [cualities, setCualities] = useState(null)

    const [bedsList, setBedsList] = useState([{number: 1, type: 1}])
    const defaultCualit = {id: 0, name: 'Habitación estándar'}
    const [roomCualities, setRoomCualities] = useState(defaultCualit)
    const [price, setPrice] = useState('')

    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    let history = useHistory()
    const [colors, setColors] = useState(null)
    const [roomColor, setRoomColor] = useState(null)

    //handle radio button change
    const handleRadioChange = (e, index) => {
        const {value} = e.target
        const list = [...bedsList]
        list[index]['number'] = index + 1
        list[index]['type'] = parseInt(value)
        setBedsList(list)
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...bedsList];
        list.splice(index, 1);
        setBedsList(list);
    }

    // handle click event of the Add button
    const handleAddClick = (i) => {
        setBedsList([...bedsList, {number: i + 2, type: 1}]);
    }

    function handleSubmit(event) {
        event.preventDefault()
        if (showPrice && price.length === 0) {
            setErrorMessage('Debe especificar el precio')
            setVariant('warning')
            showAlert()
        } else {
            let _beds = ''
            bedsList.map(({number, type}, i) => {
                _beds += type + (i + 1 < bedsList.length ? ',' : '')
            })
            const uri = apiUri.functions.ownerSaveRoom(
                userData.owner_id,
                userData.owner_hash,
                '',
                hostalId,
                roomType.value,
                guests.value,
                parseInt(roomCualities.id) === 0 ? '' : roomCualities.id,
                _beds + (showPrice ? `&price=${price}&color=${roomColor.split('#')[1]}` : '')
            )
            fetch(uri)
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                            .then((resp) => resp.json())
                            .then((data) => {
                                if (!data.error) {
                                    if (data.count > 0) {
                                        history.push('/hostal/habitaciones', {
                                            hostal: JSON.stringify(data.hostals[0])
                                        })
                                    }
                                }
                            }, (error) => {

                            })
                    } else {
                        setErrorMessage('Ha ocurrido un error al guardar la habitación, intente otra vez')
                        setVariant('danger')
                        showAlert()
                    }
                }, (error) => {

                })
        }
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalName(state.hostal_name)
        setHostalId(state.hostal_id)
        setShowPrice(!(parseInt(state.hostal_type) === 1))
        setIsEdit(state.is_edit)

        fetch(apiUri.functions.listRoomsSpecialFeatures)
            .then((response) => response.json())
            .then((data) => {
                setCualities(data.roomsSpecialFeatures)
            }, (error) => {

            })

        fetch(apiUri.functions.listRoomColors)
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error && data.count > 0) {
                    setColors(data.roomColors)
                    setRoomColor(data.roomColors[0].color)
                } else {
                    setColors([])
                }
            }, (error) => {

            })

        fetch(apiUri.functions.listBedTypes)
            .then((response) => response.json())
            .then((data) => {
                setBedTypes(data.bedTypes)
            }, (error) => {

            })
    }, [state])

    function handleQualities(id, name) {
        setRoomCualities({id: id, name: name})
    }

    const handleColorChange = (colorSelected) => {
        setRoomColor(colorSelected)
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={isEdit ? <CommonTitle goTo={() => history.go(-1)} title={hostalName}/> :
                       <CommonTitle goTo={() => history.push('/')} title={hostalName}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold">Añadir habitación</h5>
                </div>
                <div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formRoom">
                            <Form.Label>Tipo</Form.Label>
                            <Select
                                isSearchable={false}
                                styles={customStyles.react_select_style.border_left}
                                value={roomType}
                                onChange={e => setRoomType({
                                    value: e.value, label: e.label
                                })}
                                options={[
                                    {value: 0, label: 'Habitación normal'},
                                    {value: 1, label: 'Suite'}
                                ]}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGuests">
                            <Form.Label>Cantidad de hu&eacute;spedes</Form.Label>
                            <Select
                                isSearchable={false}
                                styles={customStyles.react_select_style.border_left}
                                value={guests}
                                onChange={e => setGuests({
                                    value: e.value, label: e.label
                                })}
                                options={
                                    Array.from(Array(10), (e, i) => {
                                        return {
                                            value: i + 1,
                                            label: (i + 1) + ' huésped' + (i > 0 ? 'es' : '')
                                        }
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="formBeds">
                            <Form.Label>Camas</Form.Label>
                            {
                                bedTypes === null
                                    ? <Loader style={{width: 100 + "%"}}/>
                                    :
                                    <div style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: 0.25 + 'rem',
                                        padding: 1 + 'rem'
                                    }}>
                                        {bedsList.map((x, i) => {
                                            return (
                                                <div style={{marginBottom: 15 + 'px'}} className="box">
                                                    <div className="btn-box">
                                                        <label className="font-weight-bold">Cama {i + 1}</label>
                                                        {(bedsList.length !== 1 && i > 0) && <li
                                                            className="text-info list-unstyled pb-2 float-right"
                                                            onClick={() => handleRemoveClick(i)}><Icon.Trash
                                                            size={18} className="text-danger"
                                                            style={{cursor: 'pointer'}}/>
                                                        </li>}
                                                    </div>
                                                    {
                                                        bedTypes.map(({id, name, imageOver}, j) => {
                                                            return (
                                                                <label className="bedType">
                                                                    <input onChange={e => handleRadioChange(e, i)}
                                                                           defaultChecked={j === 0}
                                                                           name={"beds_types_for_bed_" + i} type="radio"
                                                                           value={id}
                                                                           id={"bed_" + i + "_type_" + id}
                                                                    />
                                                                    <span style={{
                                                                        border: '2px solid #66cc99',
                                                                        marginRight: 4,
                                                                        borderRadius: 0.25 + 'rem'
                                                                    }}>
                                                                   <span className="radioLabel">{name}</span>
                                                                    <Image width={96} height={96}
                                                                           src={apiUri.functions.root + imageOver}
                                                                           className="radioImg mx-1"/>
                                                               </span>
                                                                </label>
                                                            )
                                                        })
                                                    }
                                                    <hr/>
                                                    <div className="btn-box">
                                                        {bedsList.length - 1 === i &&
                                                        <li className="text-info list-unstyled link-li"
                                                            onClick={() => handleAddClick(i)}><Icon.PlusCircle size={18}
                                                                                                               className="text-muted"/><span
                                                            className="pl-1">Añadir cama {i + 2}</span>
                                                        </li>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                            }
                        </Form.Group>
                        <Form.Group controlId="formCualities">
                            <Form.Label>Cualidades</Form.Label>
                            {
                                cualities === null
                                    ? <Loader style={{width: 100 + "%"}}/>
                                    :
                                    <div style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: 0.25 + 'rem',
                                        padding: 1 + 'rem'
                                    }}>
                                        <label className="text-muted">¿Qué hace esta habitación diferente a las
                                            demás?</label>
                                        <label className="text-muted">Seleccione la opción que más destaque:</label>
                                        <Form.Check defaultChecked value={defaultCualit.id} type="radio"
                                                    onChange={() => handleQualities(defaultCualit.id, defaultCualit.name)}
                                                    className="custom_radio"
                                                    id={"qualits_" + defaultCualit.id}
                                                    label={defaultCualit.name} name="qualities"/>
                                        {cualities.map(({id, name}) => {
                                            return (
                                                <Form.Check value={id} type="radio"
                                                            onChange={() => handleQualities(id, name)}
                                                            className="custom_radio" id={"qualits_" + id}
                                                            label={name} name="qualities"/>
                                            )
                                        })}
                                    </div>
                            }
                        </Form.Group>
                        {
                            showPrice
                                ? <><Form.Group controlId="formRoomPrice">
                                    <Form.Label>Precio m&iacute;nimo por noche</Form.Label>
                                    <br/>
                                    <span className="text-muted">Podr&aacute; cambiar el precio en cualquier momento y configurarlo por meses.</span>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control style={{borderLeft: 0}} value={price}
                                                      onChange={e => setPrice(e.target.value)}
                                                      type="number"/>
                                    </InputGroup>
                                </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Colores</Form.Label>
                                        {
                                            colors && roomColor
                                                ? <div style={{
                                                    border: '1px solid #ced4da',
                                                    borderRadius: 0.25 + 'rem',
                                                    padding: 1 + 'rem'
                                                }}>
                                                    <label>Selecciona color para esta habitaci&oacute;n.</label>
                                                    <label>Usaremos este color para identificarla en el calendario.</label><br/>
                                                    {
                                                        colors.map(({color}, i) => {
                                                            return (
                                                                <label className="room-color">
                                                                    <input
                                                                        onChange={() => handleColorChange(color)}
                                                                        defaultChecked={color === roomColor}
                                                                        name="color_for_new_room" type="radio"
                                                                        value={color}
                                                                        id={"room_color_" + i}
                                                                    />
                                                                    <span style={{marginRight: 4}}>
                                                                    <div style={{
                                                                        width: 70,
                                                                        height: 70,
                                                                        backgroundColor: color,
                                                                        borderRadius: 0.25 + 'rem'
                                                                    }}
                                                                         className="mx-1"/>
                                                               </span>
                                                                </label>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <ul className="mt-2 list-inline">
                                                            <ColorsFromHostalRooms hostal={hostalId}/>
                                                        </ul>
                                                    }
                                                </div>
                                                : <Loader style={{width: 100 + "%"}}/>
                                        }
                                    </Form.Group></>
                                : null
                        }
                        <div className="row fixed-bottom justify-content-center" style={{bottom: 5 + 'rem'}}>
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        {/*<div className="row fixed-bottom justify-content-center pb-4"*/}
                        {/*     style={{backgroundColor: 'white'}}>*/}
                        {/*    <Button variant="primary" type="submit" size="lg">*/}
                        {/*        Guardar*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <div className="submit-button-align-room">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </LayoutOne>
    );
}

export default CrearHabitacion;