import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Row, ListGroup, Form, Modal} from "react-bootstrap";
import {useHistory, useLocation} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {timeInHostal, undefinedState} from "../Utils/Utils"
import SubMenuHostal from "../Utils/SubmenuHostal";
import moment from "moment"
import 'moment/locale/es'
import Loader from '../../Components/Loaders/Reserva/Reserva'
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.min.css"
import es from "date-fns/locale/es"

registerLocale('es', es)

function Listado() {
    const [hostal, setHostal] = useState(null)
    const {state} = useLocation()
    const [reservations, setReservations] = useState(null)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    let history = useHistory()
    const loadData = 'Cargando datos...'
    // const [countries, setCountries] = useState(null)
    const apiUri = useContext(ApiContext)
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false)
    const [dateSelected, setDateSelected] = useState(new Date())
    const hostalsByOwner = window.localStorage.getItem('hostalsByOwner')
    const [titleHeader, setTitleHeader] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        let now = moment()
        if (state && state.selected_month) {
            setDateSelected(new Date(state.selected_month))
            now = moment(state.selected_month)
        }
        setHostal({id: state.hostal_id, name: state.hostal_name, entire_property: state.entire_property})
        const _HD_ = <CommonTitle gearIcon={true} hostalId={state.hostal_id}
                                  goTo={() => hostalsByOwner > 1 ? history.push('/reservas/hostales') : history.push('/')}
                                  title={state.hostal_name}/>
        setTitleHeader(_HD_)
        fetch(apiUri.functions.ownerListReservations(userData.owner_id, userData.owner_hash))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        const tmpArr = []
                        data.reservations.map((reservation) => {
                            if (
                                !reservation.daysBlocked
                                && parseInt(reservation.hostalId) === parseInt(state.hostal_id)
                                && moment(reservation.checkinDate, 'Y-M-D').format('YYYYMMDD') >= now.startOf('month').format('YYYYMMDD')
                            ) {
                                tmpArr.push(reservation)
                            }
                        })
                        setReservations(tmpArr)
                    } else
                        setReservations([])
                }
            }, (error) => {

            })
        // fetch(apiUri.functions.listCountryPhoneCodes)
        //     .then(response => response.json())
        //     .then((data) => {
        //         setCountries(data.country);
        //     })
    }, [state])

    function getCountry(reservation) {
        if (reservation.daysBlocked) {
            return 'Desconocido'
        } else {
            // const country = countries.find(obj => {
            //     return obj.code.toLowerCase() === reservation.guestCountry.toLowerCase()
            // })
            return reservation.guestCountryLanguageText
        }
    }

    const modalMonthSelector = () => {
        setShowModal(true)
    }

    const monthSetter = (date) => {
        handleClose()
        history.push('/reservas/listado', {
            hostal_id: hostal.id,
            hostal_name: hostal.name,
            selected_month: date,
            entire_property: hostal.entire_property
        })
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    function reservationRooms(reservation, withColor = false) {
        // console.log(reservation)
        if (reservation.daysBlocked || !reservation.rooms) {
            return []
        } else {
            let outP = []
            reservation.rooms.map((room) => {
                outP.push(
                    <>
                        <Icon.DoorOpenFill color={withColor && room.color ? room.color : 'currentColor'}
                                            size="14px"
                                            className="mt-n1"/><span style={{fontSize: '1rem'}}
                        className="pt-2 pl-2">{room.name + (parseInt(room.isSuite) ? ' (Suite)' : '')}</span><br/>
                    </>
                )
            })
            return outP
        }
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={titleHeader}>
            <div className="container-fluid">
                {
                    hostal ? <SubMenuHostal hostalId={hostal.id} hostalName={hostal.name}
                                            entireProperty={hostal.entire_property} month={dateSelected}
                                            active="reservas"/> : 'Cargando datos...'
                }
                <ListGroup variant="flush" className="mt-5">
                    <ListGroup.Item className="ListGroupNoBorders wideList mb-3" style={{paddingBottom: 0}}>
                        <Row className="align-items-center">
                            <Col xs={{span: 12}} className="text-center">
                                <ul className="list-inline" style={{marginBottom: 0, marginTop: 0.5 + 'rem'}}>
                                    <li style={{textDecoration: "underline", color: "#0076CC"}} onClick={() => modalMonthSelector()} className="link-li list-unstyled list-inline-item">
                                        <Icon.Clock/> Ver reservas
                                        anteriores
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        reservations
                            ? (reservations.length > 0
                            ? reservations.map((reservation) => {
                                const shortCheckin = moment(reservation.checkinDate, 'Y-M-D')
                                return (
                                    <Row onClick={() => history.push('/reservas/detalles', {
                                        reservation_id: reservation.id,
                                        hostal_id: reservation.hostalId
                                    })}>
                                        <Col xs={{span: 2}} className="mediumIconCol pt-3">
                                            <Button variant=""
                                                    className="btn-outline-dark backgroundIconDefault py-1 px-3"><h5
                                                className="font-weight-bolder mb-n2">{shortCheckin.format('D')}</h5>
                                                <span
                                                    className="font-xsmall text-capitalize">{shortCheckin.format(' MMM ')}</span>
                                            </Button>
                                        </Col>
                                        <Col xs={{span: 10}}>
                                            <ListGroup.Item className="wideList ListGroupNoBorders">
                                                <Row xs={{span: 10}} className="link-li">
                                                    <Col xs={{span: 6}}><span><h5
                                                        className="font-weight-bolder pt-1">{reservation.guestName}</h5></span></Col>
                                                    <Col xs={{span: 6}} className="text-right pt-1">
                                                        {
                                                            reservation
                                                                ? <span style={{
                                                                    backgroundColor: `#${reservation.statusColor}`,
                                                                    borderRadius: 5,
                                                                    padding: 3
                                                                }}
                                                                        className="text-white"><b
                                                                    style={{margin: 3}}> {reservation.statusText}</b></span>
                                                                : loadData
                                                        }
                                                    </Col>
                                                    <Col className="text-muted mt-n1"
                                                         xs={{span: 12}}><span style={{fontSize: '1rem'}}>{getCountry(reservation)}</span></Col>
                                                    <Col xs={{span: 12}}>
                                                        <Icon.UpcScan size="14px" className="mt-n1"/>
                                                        <span className="pt-2 pl-1" style={{fontSize: '1rem'}}> Id de reserva: <strong>{reservation.code}</strong></span>
                                                    </Col>
                                                    <Col xs={{span: 12}}>
                                                        <span className="align-items-center">
                                                            <Icon.Calendar2Week size="14px" className="mt-n1"/><span style={{fontSize: '1rem'}}
                                                            className="pt-2 pl-2">{timeInHostal(reservation)}</span>
                                                        </span>
                                                    </Col>
                                                    <Col xs={{span: 12}}>
                                            <span className="align-items-center">
                                                {reservationRooms(reservation, true)}
                                            </span>
                                                    </Col>
                                                    <Col xs={{span: 12}} className="text-right">
                                                        <ul className="list-unstyled">
                                                            <li className="list-inline-item"><span style={{fontSize: '1rem'}}
                                                                className="text-muted">Total:</span></li>
                                                            <li className="list-inline-item">
                                                                <span style={{fontSize: '1rem'}}>${reservation.totalCost}</span>
                                                            </li>
                                                        </ul>
                                                        <hr/>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>

                                        </Col>
                                    </Row>

                                )
                            })
                            :
                            <div className="col-md-12 wideList"><span className="text-muted">No hay reservaciones</span>
                            </div>)
                            : <>
                                <Loader/>
                                <hr/>
                                {/* <hr/>
                                <hr/> */}
                                <Loader/>
                                <hr/>
                                {/* <hr/>
                                <hr/> */}
                                <Loader/>
                            </>
                    }
                </ListGroup>
            </div>
            <Modal onHide={handleClose} show={showModal} id="month-selector" className="modal-go-back">
                <Modal.Header className="justify-content mb-n2">
                    <h5>Selecciona el mes deseado</h5>
                    <button className="customClose" onClick={handleClose} />
                </Modal.Header>
                <hr style={{margin: 'unset', marginLeft: 16, marginRight: 16}}/>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="month-picker-input">
                            {
                                dateSelected
                                    ? <DatePicker
                                        locale="es"
                                        selected={dateSelected}
                                        onSelect={date => monthSetter(date)}
                                        dateFormat="MM/yyyy" inline
                                        showMonthYearPicker
                                        maxDate={new Date()}
                                        showFourColumnMonthYearPicker/>
                                    : 'Cargando datos...'
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </LayoutOne>
    )

}

export default Listado;