import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import {useHistory} from 'react-router-dom';
import {ApiContext} from "../../State/api-context";
import Image from "react-bootstrap/Image";
import ImageField from "../../Assets/images/avatar.jpg";
import HiddenImageField from "../../Assets/images/avatar_hiden.jpg";
import ImageUploader from "react-images-upload";
import PhotoSettingsLoader from "../Loaders/PhotoSettings";
import {LayoutOne} from "../Layout";
import Loader from '../../Components/Loaders/Line'

function Settings() {

    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [info, setInfo] = useState(null)
    let history = useHistory()
    const [visible, setVisible] = useState(true);
    const [updatePhoto, setUpdatePhoto] = useState(false)

    function onDrop(pictureFile) {
        setUpdatePhoto(true)
        if (pictureFile.length > 0) {
            let fd = new FormData()
            fd.append('fileToUpload', pictureFile[0])
            fetch(apiUri.functions.ownerUpdatePhoto(userData.owner_id, userData.owner_hash), {
                method: 'POST',
                body: fd
            }).then((resp) => resp.json()).then((data) => {
                if (!data.error) {
                    setVisible(false)
                    getInfo()
                }
            }, (error) => {

            })
        }
    }

    const getInfo = () => {
        fetch(apiUri.functions.ownerInfo(userData.owner_id, userData.owner_hash))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    setInfo(data.owner[0])
                    setUpdatePhoto(false)
                }
            }, (error) => {

            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getInfo()
    }, [])

    function printPhoto(info) {
        let tmpUserData = userData
        window.localStorage.setItem('userData', JSON.stringify({
            'owner_hash': tmpUserData.owner_hash,
            'owner_phone_code': tmpUserData.owner_phone_code,
            'owner_phone': tmpUserData.owner_phone,
            'owner_country_code': tmpUserData.owner_country_code,
            'owner_access_code': tmpUserData.owner_access_code,
            'owner_id': tmpUserData.owner_id,
            'owner_name': info.name ? info.name : null,
            'owner_photo': info.photo ? info.photo : null
        }))
        if (info.photo) {
            return (
                <Image style={{objectFit: "cover", borderRadius: 50 + '%'}} width={160} height={160}
                       src={`${apiUri.functions.root}photos/owners/${info.photo}-500.jpg`}/>
            )
        } else {
            return (
                <Image src={(visible) ? ImageField : (HiddenImageField)}/>
            )
        }
    }

    return (
        <LayoutOne showIcon={true}
                   title="Ajustes">
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <ListGroup.Item style={{paddingBottom: 0}} className="ListGroupNoBorders">
                        <Row className="align-items-center text-center">
                            <Col xs={{span: 12}}>
                                <div className="AvatarImgUpload UserSettings">
                                    <div className="AvatarImgDefault">
                                        {
                                            info === null || updatePhoto
                                                ? <PhotoSettingsLoader/>
                                                : printPhoto(info)
                                        }

                                    </div>
                                    <ImageUploader
                                        onChange={onDrop}
                                        withIcon={false}
                                        // withPreview={true}
                                        label=""
                                        buttonText="Añadir nueva foto"
                                        imgExtension={[".jpg", ".gif", ".png", ".jpeg", ".svg"]}
                                        maxFileSize={31457280}
                                        fileSizeError="La foto es muy grande"
                                        singleImage={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item style={{marginTop: -25, marginBottom: -20}} className="ListGroupNoBorders wideList">
                        <h5><b>Mis datos personales</b></h5>
                    </ListGroup.Item>
                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row>
                            <Col xs={{span: 12}} className="text-muted">M&oacute;vil:</Col>
                            <Col xs={{span: 12}}><h5>{
                                info === null
                                    ? <Loader/>
                                    : '+' + info.countryCode + '-' + info.cellPhone
                            }</h5></Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row>
                            <Col xs={{span: 12}} className="text-muted">Nombre:</Col>
                            <Col xs={{span: 12}}><h5>{
                                info === null
                                    ? <Loader/>
                                    : info.name
                            }</h5></Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item className="ListGroupNoBorders wideList">
                        <Row>
                            <Col xs={{span: 12}} className="text-muted">Email:</Col>
                            <Col xs={{span: 12}}><h5>{
                                info === null
                                    ? <Loader/>
                                    : info.email
                            }</h5></Col>
                        </Row>
                    </ListGroup.Item>

                    {
                        info && (!info.name || !info.email) ?
                            <ListGroup.Item className="ListGroupNoBorders wideList">
                                <Row>
                                    <Col xs={{span: 12}} className="text-center">
                                        <span className="text-danger"><h6>Completar datos personales</h6></span>
                                    </Col>
                                </Row>
                            </ListGroup.Item> : ''
                    }
                    <ListGroup.Item className="wideList">
                        <Row>
                            <Col style={{marginTop: -20}}>
                                <li onClick={() => history.push('/ajustes/editar', {
                                    user_info: JSON.stringify(info)
                                })} className="list-unstyled text-info link-li">Actualizar datos
                                </li>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="wideList mt-3">
                        <Row>
                            <Col xs={{span: 12}}>
                                <li style={{textDecoration: 'underline', fontSize: 15}}
                                    onClick={() => history.push('/logout')}
                                    className="list-unstyled text-danger link-li">
                                    Cerrar sesi&oacute;n
                                </li>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default Settings;