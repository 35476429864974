import React, {useContext, useEffect, useState} from "react";
import {ApiContext} from "../../State/api-context";
// import iconHome from "../../Assets/images/icon_home.png";
import iconHome from "../../Assets/images/icons/2x/path_493.png";
import {Col, Image} from "react-bootstrap";
import Loader from '../../Components/Loaders/Photo'

function FirstPhoto({hostalId, smallImage = false, lockView = false}) {
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [photo, setPhoto] = useState(null)

    useEffect(() => {
        fetch(apiUri.functions.ownerListHostalPhotos(userData.owner_id, userData.owner_hash, hostalId))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    data.count > 0 ? setPhoto(data.hostalPhotos) : setPhoto([])
                }
            }, (error) => {

            })
    }, [])

    return photo
        ? (photo.length > 0
            ? <Col xs={smallImage ? {span: 3} : {span: 4}}>
                <Image src={apiUri.functions.root + photo[0].photoSmall}
                       className={smallImage ? "hostalThumbnail-small" : "hostalThumbnail"} rounded/>
            </Col>
            : <Col xs={smallImage ? {span: 3} : {span: 4}}>
                <Image src={iconHome} style={smallImage
                    ? {
                        width: 45, 
                        height: 30, 
                        animation: "forwards", 
                        border: '1px solid #bebebe'
                    }
                    : {
                        width: 100 + "%", 
                        height: 80, 
                        animation: "forwards", 
                        border: '2px solid #bebebe'
                        }} className={smallImage
                        ? "px-2 py-1 px-sm-2 py-sm-1 px-md-2 py-md-1 px-lg-2 py-lg-1 rounded"
                        : (lockView ? 
                            "px-3 py-2 rounded lock-view"
                            : "px-3 py-3 px-sm-5 py-sm-3 px-md-5 py-md-3 px-lg-5 py-lg-3 rounded")}
                />
            </Col>)
        : <Col xs={smallImage ? {span: 3} : {span: 4}}>
            <Loader style={smallImage
                ? {width: 100 + "%", height: 50}
                : {width: 100 + "%", height: 80}} rounded/>
        </Col>
}

export default FirstPhoto