import React, {useContext, useEffect, useState} from 'react';
import 'moment/locale/es'
import Col from 'react-bootstrap/Col'
// import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
// import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button'
import {useHistory, useLocation} from "react-router-dom";
import {ApiContext} from "../../State/api-context";
import ListGroup from "react-bootstrap/ListGroup";
import {LayoutOne} from "../Layout";
// import iconHome from "../../Assets/images/icon_home.png";
import Select from "react-select";
import {ThemeContext} from "../../State/theme-context";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState} from "../Utils/Utils";

function EditarEstanciaMinima() {

    const {state} = useLocation();
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const [minStay, setMinStay] = useState(null)
    let history = useHistory()
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    let customStyles = useContext(ThemeContext);

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalId(state.hostal_id)
        setHostalName(state.hostal_name)
        setMinStay({value: state.min_stay, label: `${state.min_stay} día${state.min_stay > 1 ? 's' : ''}`})
    }, [state])

    function handleSubmit(e) {
        e.preventDefault()
        fetch(apiUri.functions.updateHostalMinStay(userData.owner_id, userData.owner_hash, hostalId, minStay.value))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                        .then((response) => response.json())
                        .then((data) => {
                            if (!data.error) {
                                history.push(`/hostal/habitaciones`, {
                                    hostal: JSON.stringify(data.hostals[0])
                                })
                            }
                        }, (error) => {

                        })
                }
            }, (error) => {

            })
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)}
                                    title={hostalName === null ? 'Cargando datos...' : hostalName}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <Col className='text-center pt-2'>
                        <h5 className="font-weight-bold">Editar estancia m&iacute;nima</h5>
                    </Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label><span>Estancia m&iacute;nima</span></Form.Label>
                            <Select
                                isSearchable={false}
                                styles={customStyles.react_select_style.border_left}
                                value={minStay}
                                onChange={(e) => setMinStay({
                                    value: e.value,
                                    label: `${e.value} día${e.value > 1 ? 's' : ''}`
                                })}
                                options={
                                    Array.from(Array(10), (e, i) => {
                                        return {
                                            value: i + 1,
                                            label: (i + 1) + ' día' + (i > 0 ? 's' : '')
                                        }
                                    })
                                }
                            />
                        </Form.Group>
                        <div className="row justify-content-center">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default EditarEstanciaMinima;