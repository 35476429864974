import React, {useEffect, useState, forwardRef} from "react"
import moment from 'moment'
import 'moment/locale/es'
import Week from "./Week"
import DayNames from "./DayNames"
import {ListGroup, Row} from "react-bootstrap"
import Loader from "../../Loaders/Calendario/Calendar"

const Month = ({month, hostalId, selected, select, dayStyle, roomSelected, hostalRooms, hostalType, allReservations}, ref) => {

    const [reservations, setReservations] = useState(null)

    useEffect(() => {
        if (allReservations.length > 0) {
            const _tmpArr = []
            allReservations.map((reservation) => {
                const checkin = moment(reservation.checkinDate, 'Y-M-D'),
                    checkout = moment(reservation.ckeckoutDate, 'Y-M-D')
                if (checkin.format('YYYYMMDD') <= month.endOf('month').format('YYYYMMDD') && checkout.format('YYYYMMDD') >= month.startOf('month').format('YYYYMMDD')) {
                    if (roomSelected !== 0) {
                        if (reservation.rooms.find(searchRoom => {
                            return searchRoom.id === roomSelected
                        })) {
                            _tmpArr.push(reservation)
                        }
                    } else {
                        _tmpArr.push(reservation)
                    }
                }
            })
            setReservations(_tmpArr)
        } else {
            setReservations([])
        }
    }, [])

    const getRanges = () => {
        let columns = []
        reservations.map(({checkinDate, ckeckoutDate, rooms, hostalId, daysBlocked, iCalendarHost}) => {
            let pos = null
            const checkin = moment(checkinDate, 'Y-M-D'),
                checkout = moment(ckeckoutDate, 'Y-M-D'),
                daysRange = []

            const initialDiff = checkout.diff(checkin, 'day')
            while (checkout.diff(checkin, 'day') >= 0) {
                let dateToPush = checkin.format('Y-M-D')
                if (checkout.diff(checkin, 'day') === initialDiff) {
                    dateToPush = `${dateToPush}_start`
                }
                if (checkout.diff(checkin, 'day') === 0) {
                    dateToPush = `${dateToPush}_end`
                }
                if (daysBlocked) {
                    dateToPush = `${dateToPush}_blocked`
                }
                if (iCalendarHost) {
                    dateToPush = `${dateToPush}_external_${iCalendarHost}`
                }
                daysRange.push(dateToPush)
                checkin.add(1, 'day');
            }
            if (rooms) {
                rooms.map((room) => {
                    if (columns.find((obj, i) => {
                        pos = i
                        return obj.room === room.id && obj.hostal === hostalId
                    })) {
                        if (roomSelected !== 0) {
                            if (room.id === roomSelected) {
                                let _tmp = columns[pos]['days']
                                _tmp = _tmp.concat(daysRange)
                                columns[pos]['days'] = _tmp
                            }
                        } else {
                            let _tmp = columns[pos]['days']
                            _tmp = _tmp.concat(daysRange)
                            columns[pos]['days'] = _tmp
                        }
                    } else {
                        if (roomSelected !== 0) {
                            if (room.id === roomSelected) {
                                columns.push({
                                    // reservationId: id,
                                    hostal: hostalId,
                                    room: room.id,
                                    // roomType: room.isSuite,
                                    days: daysRange,
                                    color: room.color
                                })
                            }
                        } else {
                            columns.push({
                                // reservationId: id,
                                hostal: hostalId,
                                room: room.id,
                                // roomType: room.isSuite,
                                days: daysRange,
                                color: room.color
                            })
                        }
                    }
                })
            } else {
                if (columns.find((obj, i) => {
                    pos = i
                    return obj.hostal === hostalId
                })) {
                    let _tmp = columns[pos]['days']
                    _tmp = _tmp.concat(daysRange)
                    columns[pos]['days'] = _tmp
                } else {
                    columns.push({
                        // reservationId: id,
                        hostal: hostalId,
                        room: null,
                        // roomType: null,
                        days: daysRange,
                        color: '#28a745'
                    })
                }
            }
        })

        if (parseInt(hostalType) !== 1 && columns.length !== (hostalRooms.length - 1) && roomSelected === 0) {
            const reservasVacias = []
            for (let i = 1; i < hostalRooms.length; i++) {
                const ocurrencia = columns.find(obj => {
                    return obj.room === hostalRooms[i].value
                })
                if (!ocurrencia) {
                    reservasVacias.push({
                        hostal: hostalId,
                        room: hostalRooms[i].value,
                        days: [],
                        color: 'white'
                    })
                } else {
                    reservasVacias.push({
                        hostal: ocurrencia.hostal,
                        room: ocurrencia.room,
                        days: ocurrencia.days,
                        color: ocurrencia.color
                    })
                }
            }
            columns = reservasVacias
        }

        return columns.sort((a, b) => a.room - b.room)
    }

    function renderWeeks() {
        let weeks = [];
        let done = false;
        let date = month.clone().startOf("month").add("w" - 1).day("Sunday");
        let count = 0;
        let monthIndex = date.month();
        const reservationDays = getRanges()

        while (!done) {
            weeks.push(
                <Week key={date}
                      date={date.clone()}
                      month={month}
                      select={select}
                      selected={selected}
                      reservations={reservationDays}
                      dayStyle={dayStyle}/>
            );

            date.add(1, "w");

            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }

        return weeks;
    }

    function renderMonthLabel() {
        return <span className="month-label float-left" style={{fontSize: 18}}>{month.format("MMMM YYYY")}</span>;
    }

    return (
        reservations ?
            <ListGroup.Item className="ListGroupNoBorders wideList" ref={ref}>
                <Row className="mx-auto">
                    <section className="calendar">
                        <header className="header">
                            <div className="month-display row">
                                {renderMonthLabel()}
                            </div>
                            <DayNames/>
                        </header>
                        {renderWeeks()}
                    </section>
                </Row>
            </ListGroup.Item> : <Loader style={{maxWidth: 100 + '%'}}/>
    )
}

export default forwardRef(Month)