import React from 'react';
import Button from 'react-bootstrap/Button'
import {useHistory} from 'react-router-dom'
import imageHome from "../../Assets/images/home.png"

function Publicar() {
    let history = useHistory();

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold">Publica tu hostal</h5>
                </div>
                <div className="py-4 col-12 text-center">
                    <img
                        src={imageHome}
                        className="grayscale-img-0"
                        width="150"
                        height="130"
                        alt="Card Bookmark"/>
                </div>
                <h6 className="text-muted  text-center px-5 py-2">Crea tu primer hostal para comenzar a recibir reservas</h6>

            </div>

            <div className="row justify-content-center">
                <Button onClick={() => {
                    history.push('/hostal/crear')
                }} variant="primary" size="lg">
                    Crear hostal
                </Button>
            </div>
        </div>
    );
}

export default Publicar;