import React from "react";
import {LOCALIDAD, COMODIDAD, SERVICIO, TELEFONO} from "./Utils"
import {Link} from 'react-router-dom';

function ContactUs({type}) {
    return (
        <>
            <p style={{textAlign: 'justify'}} className="mt-2 mb-0">
                {
                    type === LOCALIDAD 
                        ? "¿No encuentras tu localidad? Escríbenos y la añadiremos a los destinos disponibles"
                        : (type === COMODIDAD
                            ? "Para añadir alguna comodidad de su hostal que considere debe aparecer en el listado"
                            : (type === SERVICIO
                                ? "Para añadir algún servicio de su hostal que considere debe aparecer en el listado"
                                : (type === TELEFONO
                                    ? "Para modificar su número celular"
                                    : "")))
                }
            </p>
            <Link style={{textDecoration: 'underline'}} to="/contacto">Contáctenos</Link>
        </>
    )
}

export default ContactUs