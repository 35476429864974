import React from "react";
import Day from "./Days";

class Week extends React.Component {
    render() {
        let days = [];
        let {
            date,
        } = this.props;

        const {
            month,
            selected,
            select,
            reservations,
            dayStyle
        } = this.props;

        for (let i = 0; i < 7; i++) {
            let day = {
                name: date.format("dd").substring(0, 1),
                number: date.date(),
                isCurrentMonth: date.month() === month.month(),
                isToday: date.isSame(new Date(), "day"),
                date: date
            }

            days.push(
                <Day day={day}
                     selected={selected}
                     select={select}
                     reservations={reservations}
                     dayStyle={dayStyle}/>
            )

            date = date.clone();
            date.add(1, "day");
        }

        return (
            <div className="row week" key={days[0]}>
                {days}
            </div>
        );
    }

}

export default Week