import React from "react"
import ContentLoader from "react-content-loader"

const InfoLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="95%"
    height="100%"
    backgroundColor="#eef4f9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="70" y="6" rx="3" ry="3" width="100%" height="25" />
    <rect x="70" y="58" rx="3" ry="3" width="80%" height="10" />
    <rect x="70" y="78" rx="3" ry="3" width="80%" height="10" />
    <rect x="70" y="98" rx="3" ry="3" width="80%" height="10" />
    <rect x="14" y="6" rx="3" ry="3" width="36" height="36" />
    <rect x="70" y="130" rx="3" ry="3" width="70" height="20" />
  </ContentLoader>
)

export default InfoLoader
