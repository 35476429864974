import React, {useContext, useEffect, useRef, useState} from "react"
import moment from 'moment'
import 'moment/locale/es'
import '../../../Assets/css/calendar.css'
import {ListGroup, Row, Col, Button, Modal, Form} from "react-bootstrap"
import * as Icon from "react-bootstrap-icons"
import {useHistory, useLocation} from "react-router-dom"
import {LayoutOne} from "../../Layout"
import CommonTitle from "../../Utils/CommonTitle"
import {ThemeContext} from "../../../State/theme-context"
import Month from "./Month"
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.min.css"
import es from "date-fns/locale/es"
import SubMenuHostal from "../../Utils/SubmenuHostal";
import {ApiContext} from "../../../State/api-context";
import Select from "react-select";
import {undefinedState} from "../../Utils/Utils";
import cubeSpinner from "../../../Assets/images/cube-spinner.gif";
import Loader from "../../Loaders/Calendario/Calendar"
import cancelSelection from "../../../Assets/images/icons/1x/group_113.png"

registerLocale('es', es)

function Calendar() {

    const [months, setMonths] = useState(null)
    const [selected, setSelected] = useState([])
    const {state} = useLocation()
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const [entireProperty, setEntireProperty] = useState(null)
    let history = useHistory()
    const [showEdit, setShowEdit] = useState(false)
    const [defaultHeader, setDefaultHeader] = useState(null)
    const [titleHeader, setTitleHeader] = useState(null)
    const customStyles = useContext(ThemeContext)
    const [isFetching, setIsFetching] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false)
    const [dateSelected, setDateSelected] = useState(new Date())
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const defaultRoom = {value: 0, label: 'Todas las habitaciones'}
    const [rooms, setRooms] = useState([defaultRoom])
    const [customStyle, setCustomStyle] = useState(null)
    const [roomSelected, setRoomSelected] = useState(defaultRoom)
    const hostalsByOwner = window.localStorage.getItem('hostalsByOwner')
    const monthsRefs = useRef([])
    const [refered, setRefered] = useState(false)
    const [referenceLoaderShow, setReferenceLoaderShow] = useState(false)
    const [allReservations, setAllReservations] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        let now = moment()
        if (state && state.selected_month) {
            setDateSelected(new Date(state.selected_month))
            now = moment(state.selected_month)
        }
        if (state && state.selected_room) {
            setRoomSelected(JSON.parse(state.selected_room))
        }
        if (state && state.last_month) {
            const diff = moment(state.last_month).startOf("month").diff(now.startOf("month"), "months") < 3 ? 3 : moment(state.last_month).startOf("month").diff(now.startOf("month"), "months")
            const allMonths = [now]
            for (let i = 1; i <= diff; i++) {
                allMonths.push(now.clone().add(i, "M"))
            }
            setMonths(allMonths)
        } else {
            setMonths([now, now.clone().add(1, "M"), now.clone().add(2, "M")])
        }
        setHostalId(state.hostal_id)
        setHostalName(state.hostal_name)
        setEntireProperty(state.entire_property)
        const _HD_ = <CommonTitle gearIcon={true} hostalId={state.hostal_id}
                                  goTo={() => hostalsByOwner > 1 ? history.push('/reservas/calendario') : history.push('/')}
                                  title={state.hostal_name}/>
        setTitleHeader(_HD_)
        setDefaultHeader(_HD_)
        fetch(apiUri.functions.ownerListRooms(userData.owner_id, userData.owner_hash, state.hostal_id))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        data.rooms.map(({id, isSuite, name}) => {
                            const list = rooms
                            list.push({value: id, label: name + (parseInt(isSuite) === 1 ? ' (Suite)' : '')})
                            setRooms(list)
                        })
                        setCustomStyle({
                            margin: 0,
                            height: `${parseInt(state.entire_property) === 1 ? 4 : (state.selected_room && JSON.parse(state.selected_room).value !== 0 ? 4 : (data.rooms.length === 1 ? 4 : (data.rooms.length > 2 ? (data.rooms.length === 3 ? data.rooms.length * 1.7 : data.rooms.length * 1.4) : data.rooms.length * 2.2)))}rem`
                        })
                    }
                }
            }, (error) => {

            })
        fetch(apiUri.functions.ownerListReservations(userData.owner_id, userData.owner_hash, '', `&hostalId=${state.hostal_id}`))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setAllReservations(data.reservations)
                    } else {
                        setAllReservations([])
                    }
                }
            }, (error) => {

            })
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        if (!isFetching || (months && months[months.length - 1].startOf('month').diff(moment().startOf('month'), 'months') > 21)) return
        moreMonths()
    }, [isFetching])

    function handleScroll() {
        if (document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop) > document.documentElement.offsetHeight / 5) return
        setIsFetching(true)
    }

    const addSelectDay = day => {
        const list = selected
        let pos = null
        if (list.find((obj, i) => {
            pos = i
            return day.date.isSame(obj)
        })) {
            list.splice(pos, 1)
        } else {
            list.push(day.date)
        }
        setSelected(list)
        setTitleHeader(list.length > 0
            ?
            <div
                style={{textAlign: "center"}}>
                <h5 style={{color: customStyles.texts_color.title, fontSize: 20}}
                    className="font-weight-bold mt-1">
                    {list.length} d&iacute;a{list.length > 1 ? 's' : ''} seleccionado{list.length > 1 ? 's' : ''}
                    <img src={cancelSelection} width="24" height="24"
                        className="float-right mt-0" alt="Cancel selection"
                        onClick={cleanSelection}/>
                    {/* <Icon.XCircleFill size={24} color={customStyles.texts_color.others} className="float-right mt-1"
                                      onClick={cleanSelection}/> */}
                </h5>
            </div>
            : defaultHeader)
        list.length > 0 ? setShowEdit(true) : setShowEdit(false)
    }

    const cleanSelection = () => {
        setSelected([])
        setTitleHeader(defaultHeader)
        setShowEdit(false)
    }

    const moreMonths = () => {
        const lastMonth = months[months.length - 1]
        setMonths([...months, lastMonth.clone().add(1, "M"), lastMonth.clone().add(2, "M"), lastMonth.clone().add(3, "M")])
        setIsFetching(false)
    }

    const modalMonthSelector = () => {
        setShowModal(true)
    }

    const monthSetter = (date) => {
        handleClose()
        history.push('/reservas/custom/calendar', {
            hostal_id: hostalId,
            hostal_name: hostalName,
            selected_month: date,
            entire_property: entireProperty,
            selected_room: JSON.stringify(roomSelected)
        })
        setTimeout(() => {
            window.location.reload()
        }, 500)

    }

    const selectARoom = selection => {
        history.push('/reservas/custom/calendar', {
            hostal_id: hostalId,
            hostal_name: hostalName,
            selected_month: dateSelected,
            entire_property: entireProperty,
            selected_room: JSON.stringify(selection)
        })
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    useEffect(() => {
        const diff = moment(state.last_month).startOf("month").diff(moment().startOf("month"), "months")
        if (state && state.last_month && !refered && diff >= 0) {
            setReferenceLoaderShow(true)
            const intervalRef = setInterval(() => {
                if(monthsRefs.current.length > 0) {
                    setRefered(true)
                    monthsRefs.current[diff].scrollIntoView({behavior: "smooth"})
                    setReferenceLoaderShow(false)
                    clearInterval(intervalRef)
                }
            }, 250)
        }
    })

    return (
        <LayoutOne title={titleHeader} showIcon={false} showNavBar={false} bG={false}>
            <div className="container-fluid">
                <SubMenuHostal hostalId={hostalId} hostalName={hostalName} entireProperty={entireProperty}
                               active="calendario" month={dateSelected}/>
                <ListGroup variant="flush" className="mt-5">
                    <ListGroup.Item className="ListGroupNoBorders wideList" style={{paddingBottom: 0}}>
                        {
                            entireProperty && parseInt(entireProperty) !== 1
                                ? <Row className="mt-1 mb-3">
                                    <Col xs={{span: 12}}>
                                        <Select
                                            isSearchable={false}
                                            value={rooms[0] !== roomSelected ? roomSelected : rooms[0]}
                                            styles={customStyles.react_select_style.border_left}
                                            options={rooms}
                                            onChange={e => selectARoom(e)}
                                        />
                                    </Col>
                                </Row>
                                : ''
                        }
                        <Row className="align-items-center">
                            <Col xs={{span: 12}} className="text-center">
                                <ul className="list-inline" style={{marginBottom: 0, marginTop: 0.5 + 'rem'}}>
                                    <li style={{textDecoration: "underline", color: "#0076CC"}} onClick={() => modalMonthSelector()}
                                        className="list-unstyled list-inline-item link-li"><Icon.Clock/> Ver meses
                                        anteriores
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {
                        hostalId && months && allReservations ?
                            months.map((month, index) => {
                                return <Month ref={ref => {
                                            monthsRefs.current[index] = ref
                                        }} month={month} hostalId={hostalId} selected={selected}
                                           select={(select) => addSelectDay(select)} dayStyle={customStyle}
                                           roomSelected={roomSelected.value} hostalRooms={rooms}
                                           hostalType={entireProperty} allReservations={allReservations}/>
                            }) : <><Loader style={{maxWidth: 100 + '%'}}/><Loader style={{maxWidth: 100 + '%'}}/><Loader style={{maxWidth: 100 + '%'}}/></>
                    }
                    {showEdit ?
                        <ListGroup.Item className="ListGroupNoBorders wideList">
                            <div className="submit-button-align-calendar">
                                <Button variant="primary" type="submit" size="lg"
                                        onClick={() => history.push('/reservas/habitacion/bloquear', {
                                            dates_selected: JSON.stringify(selected),
                                            hostal_id: hostalId
                                        })}>
                                    Seleccionar fechas <Icon.ArrowRightCircle size={20}/>
                                </Button>
                            </div>
                        </ListGroup.Item>
                        : ''}
                    <div className={referenceLoaderShow ? "visible" : "invisible"} style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: 100 + "vw",
                        height: 100 + "vh",
                        backgroundColor: "#000",
                        opacity: .1,
                        zIndex: 1040,
                        transition: "opacity .15s linear",
                        overflow: "hidden"
                    }}/>
                    <Col style={{
                        position: 'fixed',
                        top: 50 + '%',
                        left: 50 + '%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1050
                    }} className={referenceLoaderShow ? "text-center visible" : "text-center invisible"}>
                        <img alt="referenceLoaderCalendar" src={cubeSpinner}
                             width={document.documentElement.offsetWidth > 600
                                 ? document.documentElement.offsetWidth / 6
                                 : document.documentElement.offsetWidth / 3
                             } height={document.documentElement.offsetWidth > 600
                            ? document.documentElement.offsetWidth / 6
                            : document.documentElement.offsetWidth / 3
                        } className="m-5"/>
                    </Col>
                </ListGroup>
            </div>
            <Modal onHide={handleClose} show={showModal} id="month-selector" className="modal-go-back">
                <Modal.Header className="justify-content mb-n2">
                    <h5>Selecciona el mes deseado</h5>
                    <button className="customClose" onClick={handleClose} />
                </Modal.Header>
                <hr style={{margin: 'unset', marginLeft: 16, marginRight: 16}}/>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="month-picker-input">
                            {
                                dateSelected
                                    ? <DatePicker
                                        locale="es"
                                        selected={dateSelected}
                                        onSelect={date => monthSetter(date)}
                                        dateFormat="MM/yyyy" inline
                                        showMonthYearPicker
                                        maxDate={new Date()}
                                        showFourColumnMonthYearPicker/>
                                    : 'Cargando datos...'
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </LayoutOne>
    )
}

export default Calendar