import React from "react";

export const themes = {
    react_select_style: {
        no_border_left: {
            control: (base, state) => ({
                ...base,
                border: state.isFocused ? '1px solid #ced4da' : '1px solid #ced4da',
                'border-left': state.isFocused ? 0 : 0,
                // This line disable the blue border
                boxShadow: state.isFocused ? 0 : 0,
                "&:hover": {
                    border: state.isFocused ? '1px solid #ced4da' : '1px solid #ced4da',
                    'border-left': state.isFocused ? 0 : 0,
                }
            })
        },
        border_left: {
            control: (base, state) => ({
                ...base,
                border: state.isFocused ? '1px solid #ced4da' : '1px solid #ced4da',
                // This line disable the blue border
                boxShadow: state.isFocused ? 0 : 0,
                "&:hover": {
                    border: state.isFocused ? '1px solid #ced4da' : '1px solid #ced4da',
                }
            })
        }
    },
    texts_color: {
        title: '#3e3e3e',
        others: '#9b9b9b'
    }
};

export const ThemeContext = React.createContext(
    themes
)