import React from 'react';
import Header from '../Components/Header';
import Copy from "./Utils/Copy";
import Container from "react-bootstrap/Container";

const LayoutOne = ({
                       title,
                       children,
                       showNavBar = true,
                       showIcon = true,
                       showCopy = true,
                       bG = true
                   }) =>
    <div>
        <Header title={title} showNavBar={showNavBar} showIcon={showIcon} bG={bG}/>
        {children}
        {
            showCopy ? <Container fluid><Copy/></Container> : ''
        }
    </div>

export {LayoutOne};