import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height={500}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
      /* Month Name: Marzo  */
      <rect x="0" y="20" rx="0" ry="0" width="100" height="20" />

      /* Month Days bar: Dom Lun Mar Mie Jue Vie Sab */
      <rect x="6%" y="63" rx="100" ry="100" width="14" height="14" />
      <rect x="21%" y="63" rx="100" ry="100" width="14" height="14" />
      <rect x="34%" y="63" rx="100" ry="100" width="14" height="14" />
      <rect x="50%" y="63" rx="100" ry="100" width="14" height="14" />
      <rect x="64%" y="63" rx="100" ry="100" width="14" height="14" />
      <rect x="79%" y="63" rx="100" ry="100" width="14" height="14" />
      <rect x="92%" y="63" rx="100" ry="100" width="14" height="14" />

      /*Calendar days: 1 2 3...31*/
      <rect x="14.7%" y="90" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="29.3%" y="90" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="43.9%" y="90" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="58.5%" y="90" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="73.1%" y="90" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="87.6%" y="90" rx="0" ry="0" width="14.2%" height="80" />

      <rect x="0%" y="172" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="14.7%" y="172" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="29.3%" y="172" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="43.9%" y="172" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="58.5%" y="172" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="73.1%" y="172" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="87.6%" y="172" rx="0" ry="0" width="14.2%" height="80" />

      <rect x="0%" y="254" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="14.7%" y="254" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="29.3%" y="254" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="43.9%" y="254" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="58.5%" y="254" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="73.1%" y="254" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="87.6%" y="254" rx="0" ry="0" width="14.2%" height="80" />

      <rect x="0%" y="336" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="14.7%" y="336" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="29.3%" y="336" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="43.9%" y="336" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="58.5%" y="336" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="73.1%" y="336" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="87.6%" y="336" rx="0" ry="0" width="14.2%" height="80" />

      <rect x="0%" y="418" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="14.7%" y="418" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="29.3%" y="418" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="43.9%" y="418" rx="0" ry="0" width="14.2%" height="80" />
      <rect x="58.5%" y="418" rx="0" ry="0" width="14.2%" height="80" />
  </ContentLoader>
)

export default MyLoader

