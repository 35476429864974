import React, {useContext, useEffect, useState} from "react";
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import * as Icon from "react-bootstrap-icons";
import {useHistory, useLocation} from "react-router-dom";
import {Row, Col, ListGroup, Form, Image} from "react-bootstrap";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState, COMODIDAD} from "../Utils/Utils";
import ContactUs from "../Utils/ContactUs"
import LoaderLine from '../../Components/Loaders/Line';

function Amenities() {

    const apiUri = useContext(ApiContext)
    const [listAmenities, setListAmenities] = useState(null)
    let history = useHistory();
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const {state} = useLocation()
    const [hostalAmenities, setHostalAmenities] = useState(null)
    const [busy, setBusy] = useState(false)
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalName(state.hostal_name)
        setHostalId(state.hostal_id)
        getAmenities(state.hostal_id)
    }, [state])

    function getAmenities(id) {
        fetch(apiUri.functions.listHostalAmenities())
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    let comodidades = []
                    if (data.count > 0) {
                        comodidades = data.amenities
                    }
                    fetch(apiUri.functions.listHostalAmenities(`&hostalId=${id}`))
                        .then((response) => response.json())
                        .then((info) => {
                            if (!info.error) {
                                const inHostal = []
                                if (info.count > 0) {
                                    info.amenities.map(({id}) => {
                                        inHostal.push(id)
                                    })                                    
                                }
                                const list = []
                                comodidades.map(({id}) => {
                                    inHostal.includes(id) ? list.push({id: id, checked: true}) : list.push({
                                        id: id,
                                        checked: false
                                    })
                                })

                                // console.log(list)

                                setHostalAmenities(list)
                                setListAmenities(comodidades)

                                setBusy(false)
                            }
                        }, (fail) => {

                        })
                }
            }, (error) => {

            })
    }

    const setStatus = (id) => {
        if (busy) return

        const list = [...hostalAmenities]
        let pos = null;
        list.find((inst, index) => {
            // console.log(inst, index)
            if (parseInt(inst.id) === parseInt(id)){
                pos = index
                return
            }
        })
        list[pos]['checked'] = !list[pos]['checked']
        submitData(list)
    }

    function submitData(list) {
        setBusy(true)
        let amenityList = []
        list.map(({id, checked}) => {
            if (checked) {
                amenityList.push(id)
            }
        })

        fetch(apiUri.functions.updateHostalAmenities(userData.owner_id, userData.owner_hash, hostalId, amenityList.toString()))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    getAmenities(hostalId)
                }
            }, (error) => {

            })
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)} title={hostalName}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center pt-2 pb-2">
                    <h5 className="font-weight-bold">Comodidades</h5>
                </div>
                <ListGroup variant="flush">
                    {
                        listAmenities && hostalAmenities
                            ? listAmenities.map((amenitie, key) => {
                                return (
                                    <ListGroup.Item /*style={
                                        hostalAmenities[key].checked ? {} : {opacity: 0.5}
                                        }*/ onClick={() => setStatus(amenitie.id)}
                                        key={key} className="wideList">
                                        <Row>
                                            <Col xs={{span: 10}} className="text-muted">
                                                {/* <Icon.HandThumbsUp size={20}/>  */}
                                                <Image className="mt-n2" width={35} height={35}
                                                    src={apiUri.functions.root + amenitie.image}/>
                                                {amenitie.name}
                                            </Col>
                                            <Col xs={{span: 2}} className="text-muted">
                                                <Form.Check
                                                    //onClick={() => setStatus(amenitie.id)}
                                                    label=''
                                                    id={`amenitie_${amenitie.id}`}
                                                    type="switch"
                                                    checked={hostalAmenities[key].checked}/>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                )
                            }) : <>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                            </>
                    }
                </ListGroup>
                <div className="mt-2">
                    <ContactUs type={COMODIDAD}/>
                </div>
            </div>
        </LayoutOne>
    )
}

export default Amenities