import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup';
import {useHistory, useLocation} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import Alert from "react-bootstrap/Alert";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState, TELEFONO} from "../Utils/Utils";
import ContactUs from "../Utils/ContactUs"

function Settings() {

    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [email2, setEmail2] = useState(null)
    const [landline, setLandline] = useState(null)
    const [cellPhone, setCellPhone] = useState(null)
    let history = useHistory()
    const {state} = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        const userInfo = JSON.parse(state.user_info)
        setName(userInfo.name !== null ? userInfo.name : '')
        setEmail(userInfo.email !== null ? userInfo.email : '')
        setEmail2(userInfo.email2nd !== null ? userInfo.email2nd : '')
        setLandline(userInfo.landline !== null ? userInfo.landline : '')
        setCellPhone(`+${userInfo.countryCode}-${userInfo.cellPhone}`)
    }, [state])

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    function handleSubmit(e) {
        e.preventDefault()
        let isError = false
        if (name.length === 0) {
            isError = true
            setErrorMessage('Debe especificar su nombre')
            setVariant('warning')
            showAlert()
        } else if (email.length !== 0 || email2.length !== 0) {
            const rE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const dir = email.length !== 0 ? email : email2
            if (!rE.test(String(dir).toLowerCase())) {
                isError = true
                setErrorMessage('Debe insertar una dirección de correo válida')
                setVariant('warning')
                showAlert()
            }
        } else if (email.length === 0) {
            isError = true
            setErrorMessage('Debe especificar su dirección de correo')
            setVariant('warning')
            showAlert()
        }
        if (!isError) {
            fetch(apiUri.functions.ownerUpdateInfo(userData.owner_id, userData.owner_hash, name, email, email2, landline))
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        history.push('/ajustes')
                    } else {
                        console.error('algo mal')
                    }
                }, (error) => {

                })
        }
    }

    return (
        <LayoutOne bG={false} showNavBar={false}
                   title={
                       <CommonTitle goTo={() => history.push('/ajustes')}/>
                   } showIcon={false}>
            <div className="container-fluid">
                <Form onSubmit={handleSubmit}>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="ListGroupNoBorders wideList">
                            <h4><b>Mis datos personales</b></h4>
                        </ListGroup.Item>
                        <ListGroup.Item className="ListGroupNoBorders wideList">
                            <Row>
                                <Col xs={{span: 12}} className="text-muted">Nombre:</Col>
                                <Col xs={{span: 12}}>
                                    <Form.Group controlId="formName" className="mt-1">
                                        <Form.Control
                                            onChange={e => setName(e.target.value)}
                                            value={name === null ? 'Cargando datos...' : name}
                                            type="text"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/*</ListGroup.Item>*/}
                            {/*<ListGroup.Item className="ListGroupNoBorders wideList">*/}
                            <Row>
                                <Col xs={{span: 12}} className="text-muted">Email:</Col>
                                <Col xs={{span: 12}}>
                                    <Form.Group controlId="formEmail" className="mt-1">
                                        <Form.Control onChange={e => setEmail(e.target.value)}
                                                      value={email === null ? 'Cargando datos...' : email}
                                                      type="text"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/*</ListGroup.Item>*/}
                            {/*<ListGroup.Item className="ListGroupNoBorders wideList">*/}
                            {/*    <Row>*/}
                            {/*        <Col xs={{span: 12}} className="text-muted">Email alternativo:</Col>*/}
                            {/*        <Col xs={{span: 12}}>*/}
                            {/*            <Form.Group controlId="formEmail2" className="mt-1">*/}
                            {/*                <Form.Control onChange={e => setEmail2(e.target.value)}*/}
                            {/*                              value={email2 === null ? 'Cargando datos...' : email2}*/}
                            {/*                              type="text"/>*/}
                            {/*            </Form.Group>*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*</ListGroup.Item>*/}
                            {/*<ListGroup.Item className="ListGroupNoBorders wideList">*/}
                            <Row>
                                <Col xs={{span: 12}} className="text-muted">M&oacute;vil:</Col>
                                <Col xs={{span: 12}}>
                                    <Form.Group controlId="formCellPhone" className="mt-1">
                                        <Form.Control disabled
                                                      value={cellPhone === null ? 'Cargando datos...' : cellPhone}
                                                      type="text"/>
                                        <ContactUs type={TELEFONO}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        {/*<ListGroup.Item className="ListGroupNoBorders wideList">*/}
                        {/*    <Row>*/}
                        {/*        <Col xs={{span: 12}} className="text-muted">Teléfono Fijo:</Col>*/}
                        {/*        <Col xs={{span: 12}}>*/}
                        {/*            <Form.Group controlId="formLandline" className="mt-1">*/}
                        {/*                <Form.Control onChange={e => setLandline(e.target.value)}*/}
                        {/*                              value={landline === null ? 'Cargando datos...' : landline}*/}
                        {/*                              type="number"/>*/}
                        {/*            </Form.Group>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</ListGroup.Item>*/}
                        <div className="row justify-content-center">
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row justify-content-center">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </ListGroup>
                </Form>
            </div>
        </LayoutOne>
    );
}

export default Settings;