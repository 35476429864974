import React, {useContext, useEffect, useState} from "react";
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import * as Icon from "react-bootstrap-icons";
import {useHistory, useLocation} from "react-router-dom";
import {Row, Col, ListGroup, Form, Button, Image} from "react-bootstrap";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState, SERVICIO} from "../Utils/Utils";
import ContactUs from "../Utils/ContactUs"
import LoaderLine from '../../Components/Loaders/Line';

function Services() {

    const apiUri = useContext(ApiContext)
    const [listServices, setListServices] = useState(null)
    let history = useHistory();
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const {state} = useLocation()
    const [hostalServices, setHostalServices] = useState(null)
    const [busy, setBusy] = useState(false)
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalName(state.hostal_name)
        setHostalId(state.hostal_id)
        getServices(state.hostal_id)
    }, [state])

    const getServices = (id) => {
        fetch(apiUri.functions.listHostalServices())
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    let servicios = []
                    if (data.count > 0) {
                        servicios = data.services
                    }
                    fetch(apiUri.functions.listHostalServices(`&hostalId=${id}`))
                        .then((response) => response.json())
                        .then((info) => {
                            if (!info.error) {
                                const inHostal = []
                                if (info.count > 0) {
                                    info.services.map((service) => {
                                        inHostal.push(service)
                                    })
                                }
                                const list = []
                                servicios.map(({id}) => {
                                    let checked = false,
                                        free = false
                                    inHostal.find(obj => {
                                        if (obj.id === id) {
                                            checked = true
                                            if (parseInt(obj.free) === 1) {
                                                free = true
                                            }
                                        }
                                    })

                                    list.push({id: id, checked: checked, free: free})
                                })
                                setHostalServices(list)
                                setListServices(servicios)

                                setBusy(false)
                            }
                        }, (fail) => {

                        })
                }
            }, (error) => {

            })
    }

    const setStatus = (id) => {
        if (busy) return

        const list = [...hostalServices]
        let pos = null;
        list.find((inst, index) => {
            if (parseInt(inst.id) === parseInt(id)){
                pos = index
                return
            }
        })
        list[pos]['checked'] = !list[pos]['checked']
        submitData(list)
    }

    const setFreeOrNot = (index) => {
        if (busy) return
        
        const list = [...hostalServices]
        list[index]['free'] = !list[index]['free']
        submitData(list)
    }

    const submitData = (list) => {
        setBusy(true)
        let serviceList = [],
            freeOrNotList = []
        list.map(({id, checked, free}) => {
            if (checked) {
                serviceList.push(id)
                free ? freeOrNotList.push(1) : freeOrNotList.push(0)
            }
        })
        fetch(apiUri.functions.updateHostalServices(userData.owner_id, userData.owner_hash, hostalId, serviceList.toString(), freeOrNotList.toString()))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error) {
                    getServices(hostalId)
                }
            }, (error) => {

            })
    }

    return (
        <LayoutOne showIcon={false} bG={false} showNavBar={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)} title={hostalName}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center pt-2 pb-2">
                    <h5 className="font-weight-bold">Servicios</h5>
                </div>
                <ListGroup variant="flush">
                    {
                        listServices && hostalServices
                            ? listServices.map((service, key) => {
                                return (
                                    <ListGroup.Item /*style={
                                        hostalServices[key].checked ? {} : {opacity: 0.5}
                                    }*/ key={key} className="wideList">
                                        <Row>
                                            <Col onClick={() => setStatus(service.id)} xs={hostalServices[key].checked ? {span: 8} : {span: 10}}
                                                 className="text-muted">
                                                <Image className="mt-n2" height={35} width={35}
                                                    src={apiUri.functions.root + service.image}/>
                                                {service.name}
                                            </Col>
                                            {
                                                hostalServices[key].checked ?
                                                    <Col xs={{span: 2}}>
                                                        <Button style={{
                                                            height: 20,
                                                            width: 50,
                                                            paddingTop: 0,
                                                            fontSize: 12,
                                                            borderTopLeftRadius: 1.5 + 'rem',
                                                            borderTopRightRadius: 1.5 + 'rem',
                                                            borderBottomLeftRadius: 1.5 + 'rem',
                                                            borderBottomRightRadius: 1.5 + 'rem',
                                                        }}
                                                                onClick={() => setFreeOrNot(key)}
                                                                variant="outline-secondary"
                                                                size="sm">
                                                            {hostalServices[key].free ? 'Gratis' : 'Pago'}
                                                        </Button>
                                                    </Col>
                                                    : ''
                                            }
                                            <Col xs={{span: 2}} className="text-muted">
                                                <Form.Check
                                                    onClick={() => setStatus(service.id)}
                                                    label=''
                                                    id={`service_${service.id}`}
                                                    type="switch"
                                                    checked={hostalServices[key].checked}/>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                )
                            }) : <>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                                <div className="col-12 pb-3">
                                    <LoaderLine/>
                                </div>
                            </>
                    }
                </ListGroup>
                <div className="mt-2">
                    <ContactUs type={SERVICIO}/>
                </div>
            </div>
        </LayoutOne>
    )
}

export default Services