import React, {useContext, useEffect, useState} from 'react';
import {LayoutOne} from "./Layout";
import CommonTitle from "./Utils/CommonTitle";
import {Link, useHistory} from "react-router-dom";
import {ApiContext} from "../State/api-context";

function Contact() {
    let history = useHistory()
    const apiUri = useContext(ApiContext)
    const [pageBody, setPageBody] = useState(null)

    useEffect(() => {
        fetch(apiUri.functions.htmlContactHelp)
            .then((resp) => resp.json())
            .then((data) => {
                setPageBody(data.htmlHelp)
            }, (error) => {

            })
    }, [])

    return (
        <LayoutOne bG={false} showNavBar={false} showIcon={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    {
                        pageBody ? <div dangerouslySetInnerHTML={{ __html: pageBody }} /> : null
                    }
                </div>
            </div>
        </LayoutOne>
    );
}

export default Contact;