import React from "react"
import ContentLoader from "react-content-loader"

const RoomsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width="90%"
    height="100%"
    backgroundColor="#eef4f9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="10" rx="3" ry="3" width="70" height="15" />
    <rect x="20" y="44" rx="3" ry="3" width="139" height="15" />
    <rect x="190" y="44" rx="3" ry="3" width="103" height="15" />
    <rect x="20" y="74" rx="3" ry="3" width="147" height="15" />
  </ContentLoader>
)

export default RoomsLoader
