import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import CommonTitle from "../Utils/CommonTitle";
import {LayoutOne} from "../Layout";
import {ListGroup, Col, Row} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import {undefinedState} from "../Utils/Utils";

function RoomSelection() {
    const {state} = useLocation();
    const [rooms, setRooms] = useState(null)
    const [hostal, setHostal] = useState(null)
    let history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostal({id: state.hostal_id, tittle: state.hostal_name})
        setRooms(state.hostal_rooms)
    }, [])

    return (
        <LayoutOne showIcon={false} showCopy={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)}
                                    title={hostal === null ? 'Cargando hostal...' : hostal.tittle}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <Row>
                        <Col className='pt-2' xs={{span: 12}}>
                            <h5 className="font-weight-bold">Calendarios externos</h5>
                        </Col>
                        <Col xs={{span: 12}} className="mt-2 mb-4">
                            <span>Selecciona la habitaci&oacute;n</span>
                        </Col>
                        {
                            rooms
                                ? rooms.map(({isSuite, color, id, name}) => {
                                    return <Col className="link-li" onClick={() => history.push('/external/calendar/import', {
                                        hostal_id: hostal.id,
                                        hostal_name: hostal.tittle,
                                        room: JSON.stringify({id: id, isSuite: isSuite, color: color, name: name})
                                    })}
                                                xs={{span: 12}}>
                                        <Icon.DoorOpenFill className="mt-n2 mr-2" size={20}
                                                           color={color ? color : 'currentColor'}/>
                                        <strong>{name + (parseInt(isSuite) === 1 ? ' (Suite)' : '')}</strong>
                                        <hr/>
                                    </Col>
                                })
                                : 'Cargando datos...'
                        }
                    </Row>
                </ListGroup>
            </div>
        </LayoutOne>
    )
}

export default RoomSelection