import React, {useState, useEffect, useContext} from 'react';
import {DoorOpenFill} from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import {useHistory} from 'react-router-dom';

function BackDoorLogin() {

	const [loginParameters, setLoginParameters] = useState(null)
	const apiUri = useContext(ApiContext)
	let history = useHistory()

	useEffect(() => {
        const queryString = require('query-string')
        const parsed = queryString.parse(window.location.search)
        if (Object.keys(parsed).length === 0) {
        	alert('URL vacía, inserte los parámetros necesarios')
        } else {
        	setLoginParameters(parsed)
        }
    }, [])

    const handleBackDoorLogin = () => {   	

    	const ownerVerifyRoute = apiUri.functions.ownerVerifyCode(
            loginParameters.countryCode,
            loginParameters.phoneNumber,
            loginParameters.accessCode
        )

        fetch(ownerVerifyRoute)
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    window.localStorage.setItem('userData', JSON.stringify({
                        'owner_hash': data.ownerHash,
                        'owner_phone_code': loginParameters.countryCode,
                        'owner_phone': loginParameters.phoneNumber,
                        'owner_access_code': loginParameters.accessCode,
                        'owner_id': data.id
                    }))
                    const hostalListByOwnerRoute = apiUri.functions.ownerListHostals(
                        data.id,
                        data.ownerHash
                    )
                    
                    fetch(hostalListByOwnerRoute)
                        .then((response) => response.json())
                        .then((data) => {
                            if (!data.error) {
                                window.localStorage.setItem('hostalsByOwner', parseInt(data.count))
                                if (data.count > 0) {
                                    history.push(`/dashboard`)
                                } else {
                                    history.push(`/hostal/publicar`)
                                }
                            }
                        }, (error) => {
                            alert('Error inesperado: ' + error)
                        })
                } else {
                    alert(data.errorText)
                }
            }, (error) => {
                alert('Error inesperado: ' + error)
            })
    }

    return (
    	<div style={{textAlign: 'center', marginTop: '50%'}}>
    		<DoorOpenFill size={150} />
    		{
    			loginParameters ? handleBackDoorLogin() : null
    		}
    	</div>
    )

}

export default BackDoorLogin;