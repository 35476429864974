import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useHistory} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../../Components/Loaders/ItemList/ItemList'
import * as Icon from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import FirstPhoto from "../Utils/FirstPhoto";
import CommonTitle from "../Utils/CommonTitle";

function Hostales() {
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [hostals, setHostals] = useState(null)
    let history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash))
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    data.count > 0 ? setHostals(data.hostals) : setHostals([])
                }
            }, (error) => {

            })
    }, [])

    return (
        <LayoutOne title={
            <CommonTitle goTo={() => history.push('/')} whiteImage={true}/>
        } showIcon={false}>
            <div className="container-fluid">
                <div className="row justify-content-center my-1">
                    <h6 className="text-muted">Seleccione un hostal</h6>
                </div>
                <ListGroup variant="flush">
                    {
                        hostals
                            ? hostals.map((hostal) => {
                                return hostal ?
                                    <ListGroup.Item className="list-group-end-border wideList">
                                        <Row className="align-items-center link-li"
                                             onClick={() => history.push('/reservas/listado', {
                                                 hostal_id: hostal.id,
                                                 hostal_name: hostal.tittle,
                                                 entire_property: hostal.entireProperty
                                             })}>
                                            <FirstPhoto hostalId={hostal.id}/>
                                            <Col xs={{span: 8}} className="text-left pl-0">
                                                <h5 className="font-weight-bold">{hostal.tittle}</h5>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    : ''
                            })
                            :
                            <>
                                <Loader/>
                                <Loader/>
                                <Loader/>
                                <Loader/>
                                <Loader/>
                            </>
                    }
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default Hostales;