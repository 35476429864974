import React, {useContext, useEffect, useState} from 'react';
import {Col, Form, Button, Alert, ListGroup} from 'react-bootstrap'
import {useHistory, useLocation} from "react-router-dom";
import {ApiContext} from "../../State/api-context";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState} from "../Utils/Utils";
import TextareaAutosize from 'react-autosize-textarea';

function EditHostalDescription() {

    const {state} = useLocation();
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const [hostalDescription, setHostalDescription] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    let history = useHistory()
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalId(state.hostal_id)
        setHostalName(state.hostal_name)
        setHostalDescription(state.hostal_description !== null ? state.hostal_description : null)
    }, [state])

    function handleSubmit(e) {
        e.preventDefault()
        if (!hostalDescription || hostalDescription.length === 0) {
            setErrorMessage(<>Debe especificar una descripci&oacute;n para su hostal</>)
            setVariant('warning')
            showAlert()
        } else {
            let fd = new FormData()
            fd.append('description', hostalDescription)
            fetch(apiUri.functions.updateHostalDescription(userData.owner_id, userData.owner_hash, hostalId), {
                method: 'POST',
                body: fd
            }).then((response) => response.json()).then((data) => {
                    if (!data.error) {
                        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                            .then((response) => response.json())
                            .then((data) => {
                                if (!data.error) {
                                    history.push(`/hostal/habitaciones`, {
                                        hostal: JSON.stringify(data.hostals[0])
                                    })
                                }
                            }, (error) => {

                            })
                    }
                }, (error) => {

                })
        }
    }

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)} title={hostalName ? hostalName : ''}/>
                   }>
            <div className="container-fluid">
                <ListGroup variant="flush">
                    <Col className='text-center pt-2'>
                        <h5 className="font-weight-bold">Editar descripci&oacute;n</h5>
                    </Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label><span>Descripci&oacute;n del hostal</span></Form.Label>
                            <TextareaAutosize
                                value={hostalDescription}
                                onChange={(e) => setHostalDescription(e.target.value)}
                                className="form-control"
                                rows={3}/>
                        </Form.Group>
                        <div className="row justify-content-center">
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        <div className="row justify-content-center">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </ListGroup>
            </div>
        </LayoutOne>
    );
}

export default EditHostalDescription;