import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Button, Image, Row, Toast, Alert} from 'react-bootstrap'
import {useHistory, useLocation} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {ApiContext} from "../../State/api-context";
import ImageUploader from "react-images-upload";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import uploadIcon from "../../Assets/images/upload_icon.png"
import cubeSpinner from "../../Assets/images/cube-spinner.gif"
import {undefinedState} from "../Utils/Utils";

function Photos() {

    const [photos, setPhotos] = useState(null)
    const [hostalId, setHostalId] = useState(null)
    const [hostalName, setHostalName] = useState(null)
    const {state} = useLocation();
    const [show, setShow] = useState([])
    const apiUri = useContext(ApiContext)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [errorMessage, setErrorMessage] = useState('')
    const [showError, setShowError] = useState(false)
    const [variant, setVariant] = useState('warning')
    let imgUplRef = useRef()
    let history = useHistory()
    const [updatePhoto, setUpdatePhoto] = useState(false)
    const defaultText = <>Cargando im&aacute;genes...</>
    const [imgActionText, setImgActionText] = useState(defaultText)

    function photosLoader(hostalId) {
        fetch(apiUri.functions.ownerListHostalPhotos(userData.owner_id, userData.owner_hash, hostalId))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    if (data.count > 0) {
                        setPhotos(data.hostalPhotos)
                        let _tmp = []
                        data.hostalPhotos.map(({id}) => {
                            _tmp.push({id: id, full: true, published: true, actions: false})
                        })
                        setShow(_tmp)
                    } else {
                        setPhotos([])
                        setShow([])
                    }
                }
            }, (error) => {

            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        photosLoader(state.hostal_id)
        setHostalId(state.hostal_id)
        setHostalName(state.hostal_name)
    }, [state])

    function showManage(id, position) {
        if (show.length > 0) {
            const inst = show.find(ob => {
                return parseInt(ob.id) === parseInt(id)
            })
            if (position === 'full') {
                return inst.full
            } else if (position === 'top') {
                return inst.published
            } else {
                return inst.actions
            }
        }
    }

    function toggleShow(id) {
        const list = [...show]
        let index = null
        show.find((ob, pos) => {
            if (parseInt(ob.id) === parseInt(id)) {
                index = pos
            }
        })
        list[index]['published'] = !list[index]['published']
        list[index]['actions'] = !list[index]['actions']
        setShow(list)
    }

    function deleteImage(id) {
        fetch(apiUri.functions.ownerDeleteHostalPhoto(userData.owner_id, userData.owner_hash, id))
            .then((response) => response.json())
            .then((data) => {
                if (!data.error) {
                    photosLoader(hostalId)
                }
            }, (error) => {

            })
    }

    return (
        <LayoutOne title={
            <CommonTitle goTo={() => history.go(-1)} title={hostalName}/>
        } showIcon={false} showNavBar={false} bG={false}>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h5 className="font-weight-bold">Fotos</h5>
                </div>

                <Row className="d-flex justify-content-center text-center PhotoHostalCloseButton">
                    {
                        photos === null || photos.length !== show.length || updatePhoto
                            ? <Col className="text-center">
                                <div style={{height: 210 + 'px'}}>
                                    <div className="bg-white"
                                         role="progressbar"
                                         style={{width: 100 + '%'}}>
                                        <img src={cubeSpinner} width="100" height="100" className="m-5"/>
                                        <h6>{imgActionText}</h6>
                                    </div>
                                </div>
                            </Col>
                            : photos.map(({id, photoSmall, photoLarge}) => {
                                return (
                                    <div>
                                        {showManage(id, 'full')
                                            ? <Col xs={{span: 12}} className="text-center">
                                                <Toast animation={false} onClose={e => toggleShow(id)}
                                                       className="py-4 hostalImageMime">
                                                    {showManage(id, 'top')
                                                        ? <Toast.Header>
                                                            <strong className="mr-auto"> </strong>
                                                        </Toast.Header>
                                                        : <Toast.Header className="opacity-0">
                                                            <strong className="mr-auto"> </strong>
                                                        </Toast.Header>
                                                    }
                                                    <Toast.Body className="p-0">
                                                        <Image className="uploadedPicture PhotoHostalmargin opacity-04"
                                                               src={apiUri.functions.root + photoLarge} fluid
                                                               rounded/>
                                                        {
                                                            showManage(id, 'bottom')
                                                                ?
                                                                <div
                                                                    className="custom-mt-nbig d-flex justify-content-center opacity-1">
                                                                    <Button onClick={e => deleteImage(id)} variant="danger"
                                                                            className="px-3 mx-3 align-items-center"><Icon.Trash/> Eliminar</Button>
                                                                    <Button onClick={e => toggleShow(id)}
                                                                            variant="secondary"
                                                                            className="px-3 mx-3"><Icon.ArrowCounterclockwise/> Cancelar</Button>
                                                                </div>
                                                                : ''
                                                        }
                                                    </Toast.Body>
                                                </Toast>
                                            </Col>
                                            : null
                                        }
                                    </div>
                                )
                            })
                    }
                </Row>
                <div style={photos === null || photos.length !== show.length || updatePhoto ? {display: "none"} : {}}
                     className="reference-upload-div text-center">
                    <ImageUploader
                        withIcon={false}
                        buttonText='A&ntilde;adir im&aacute;genes'
                        onChange={onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg', '.svg']}
                        maxFileSize={31457280}
                        withLabel={false}
                        ref={imgUplRef}
                    />
                    <img src={uploadIcon} width="70" height="70" className="photoUploadIcon" alt="Upload Icon"/>
                </div>

                <div className="row justify-content-center">
                    <Alert variant={variant} show={showError}>
                        {errorMessage}
                    </Alert>
                </div>
            </div>
        </LayoutOne>
    )

    function onDrop(pictureFiles) {
        setUpdatePhoto(true)
        setImgActionText(<>Subiendo im&aacute;genes. Por favor, espere…</>)
        pictureFiles.map((pict, i) => {
            let fd = new FormData()
            fd.append('fileToUpload', pict)
            fetch(apiUri.functions.ownerInsertHostalPhoto(userData.owner_id, userData.owner_hash, hostalId), {
                method: 'POST',
                body: fd
            })
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        if (i + 1 === pictureFiles.length) {
                            imgUplRef.current.state.files = []
                            imgUplRef.current.state.fileErrors = []
                            imgUplRef.current.state.pictures = []
                            setUpdatePhoto(false)
                            setImgActionText(defaultText)
                        }
                        photosLoader(hostalId)
                    } else {
                        setErrorMessage(`Ha ocurrido un error al guardar el archivo ${pict.name}, por favor intente otra vez`)
                        setVariant('warning')
                        showAlert()
                    }
                }, (error) => {

                })
        })
    }

    function showAlert() {
        setShowError(true)
        setTimeout(() => {
            setShowError(false)
        }, 3000)
    }
}

export default Photos;