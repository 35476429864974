import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../State/theme-context";
import chevronLeftIcon from "../../Assets/images/icons/1x/group_112.png"
import settingsHostalIcon from "../../Assets/images/icons/1x/sliders.png"
// import chevronLeftIconWhite from "../../Assets/images/chevron-arrow-left-circle-background-white.png"
import * as Icon from "react-bootstrap-icons"
import {useHistory} from "react-router-dom";
import {ApiContext} from "../../State/api-context";

function CommonTitle({goTo, title, whiteImage = false, gearIcon = false, hostalId = null}) {
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const customStyles = useContext(ThemeContext)
    const history = useHistory()
    const [hostal, setHostal] = useState(null)
    const apiUri = useContext(ApiContext)

    useEffect(() => {
        if (gearIcon) {
            fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        setHostal(data.hostals[0])
                    }
                }, (error) => {

                })
        }
    }, [])

    return (
        <>
            <div className="d-inline float-left">
                <img src={chevronLeftIcon} width="24" height="24"
                     className="my-2 mr-3 back-btn-header" alt="Go left"
                     onClick={goTo}/>
            </div>
            <div className="d-inline float-left" style={gearIcon ? {maxWidth: 75 + '%'} : {maxWidth: 80 + '%'}}>
                <h5 style={{color: customStyles.texts_color.title, maxWidth: 100 + '%'}}
                    className="font-weight-bold float-left mt-2 text-truncate">{title}</h5>
            </div>
            {
                gearIcon
                    ? <div className="d-inline float-right">
                        {/* <Icon.GearFill color="#3E3E3E" className="my-2" size={24}
                                       onClick={() => history.push('/hostal/habitaciones', {
                                           hostal: JSON.stringify(hostal),
                                           back_on: true
                                       })}
                        /> */}
                        <img src={settingsHostalIcon} width="24" height="24"
                            className="my-2" alt="Hostal settings"
                            onClick={() => history.push('/hostal/habitaciones', {
                                hostal: JSON.stringify(hostal),
                                back_on: true
                            })}/>
                    </div>
                    : ""
            }
        </>
    );
}

export default CommonTitle