import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../State/theme-context";
import {ApiContext} from "../../State/api-context";
import {useHistory, useLocation} from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {Form, Image, Button, Alert} from "react-bootstrap";
import Select from "react-select";
import {LayoutOne} from "../Layout";
import CommonTitle from "../Utils/CommonTitle";
import {undefinedState} from "../Utils/Utils";
import Loader from "../Loaders/Line"

function EditRoom() {
    const customStyles = useContext(ThemeContext)
    const apiUri = useContext(ApiContext)
    const {state} = useLocation()
    const [hostalName, setHostalName] = useState(null)
    const [hostalId, setHostalId] = useState(null)
    const userData = JSON.parse(window.localStorage.getItem('userData'))
    const [roomId, setRoomId] = useState(null)

    //recibe un json con los tipos de camas
    const [bedTypes, setBedTypes] = useState(null)
    //recibe un json con las caracteristicas de la habitacion
    const [cualities, setCualities] = useState(null)

    const [roomType, setRoomType] = useState(null)
    const [guests, setGuests] = useState(null)
    const [bedsList, setBedsList] = useState([])
    const defaultCualit = {id: 0, name: 'Habitación estándar'}
    const [roomCualities, setRoomCualities] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState('warning');
    const [colors, setColors] = useState(null)
    const [roomColor, setRoomColor] = useState(null)
    const [showColor, setShowColor] = useState(null)
    const [colorByRoom, setColorsByRooms] = useState(null)
    const [roomName, setRoomName] = useState(null)

    let history = useHistory()

    //handle radio button change
    const handleRadioChange = (e, index) => {
        const {value} = e.target
        const list = [...bedsList]
        list[index]['number'] = index + 1
        list[index]['type'] = parseInt(value)
        setBedsList(list)
    }

    function handleQualities(id, name) {
        setRoomCualities({id: id, name: name})
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...bedsList];
        list.splice(index, 1);
        setBedsList(list);
    }

    // handle click event of the Add button
    const handleAddClick = (i) => {
        setBedsList([...bedsList, {number: i + 2, type: 1}]);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        undefinedState(state)
        setHostalName(state.hostal_name)
        setHostalId(state.hostal_id)
        const _roomData = JSON.parse(state.room_data)
        setShowColor(!(parseInt(state.hostal_type) === 1))

        setRoomColor(_roomData.color)
        setRoomName(_roomData.name)
        setRoomId(_roomData.id)
        setRoomType(parseInt(_roomData.isSuite) ? {value: 1, label: 'Suite'} : {value: 0, label: 'Habitación normal'})
        setGuests({
            value: parseInt(_roomData.maxGuests),
            label: parseInt(_roomData.maxGuests) > 1 ? `${parseInt(_roomData.maxGuests)} huéspedes` : `${parseInt(_roomData.maxGuests)} huésped`
        })
        let bedsArr = []
        Array.from(Array(_roomData.beds.length), (e, i) => {
            bedsArr.push({number: i + 1, type: _roomData.beds[i]})
        })
        setBedsList(bedsArr)

        fetch(apiUri.functions.listRoomColors)
            .then((resp) => resp.json())
            .then((data) => {
                !data.error && data.count > 0 ? setColors(data.roomColors) : setColors([])
            }, (error) => {

            })

        fetch(apiUri.functions.listRoomsSpecialFeatures)
            .then((response) => response.json())
            .then((data) => {
                if (parseInt(_roomData.specialFeature) > 0) {
                    const qlt = data.roomsSpecialFeatures.find(op => {
                        return parseInt(op.id) === parseInt(_roomData.specialFeature)
                    })
                    setRoomCualities({id: qlt.id, name: qlt.name})
                } else {
                    setRoomCualities(defaultCualit)
                }
                setCualities(data.roomsSpecialFeatures)
            }, (error) => {

            })

        fetch(apiUri.functions.listBedTypes)
            .then((response) => response.json())
            .then((data) => {
                setBedTypes(data.bedTypes)
            }, (error) => {

            })

        fetch(apiUri.functions.ownerListRooms(userData.owner_id, userData.owner_hash, state.hostal_id))
            .then((resp) => resp.json())
            .then((data) => {
                if (!data.error && data.count > 0) {
                    const initState = []
                    data.rooms.map(({id, isSuite, color, name}) => {
                        initState.push({id: id, isSuite: isSuite, color: color, name: name})
                    })
                    setColorsByRooms(initState)
                } else {
                    setColorsByRooms([])
                }
            }, (error) => {

            })
    }, [state])

    function handleSubmit(e) {
        e.preventDefault()
        if (roomName.length === 0) {
            setErrorMessage('Debe especificar el nombre de la habitación')
            setVariant('warning')
            showAlert()
        } else {
            let _beds = ''
            bedsList.map(({number, type}, i) => {
                _beds += type + (i + 1 < bedsList.length ? ',' : '')
            })
            const uri = apiUri.functions.ownerSaveRoom(
                userData.owner_id,
                userData.owner_hash,
                `&roomId=${roomId}`,
                hostalId,
                roomType.value,
                guests.value,
                parseInt(roomCualities.id) === 0 ? '' : roomCualities.id,
                _beds + (showColor ? `&color=${roomColor.split('#')[1]}` : '') + `&name=${roomName}`
            )
            fetch(uri)
                .then((response) => response.json())
                .then((data) => {
                    if (!data.error) {
                        fetch(apiUri.functions.ownerListHostals(userData.owner_id, userData.owner_hash, `&hostalId=${hostalId}`))
                            .then((resp) => resp.json())
                            .then((data) => {
                                if (!data.error) {
                                    if (data.count > 0) {
                                        history.push('/hostal/habitaciones', {
                                            hostal: JSON.stringify(data.hostals[0])
                                        })
                                    }
                                }
                            }, (error) => {

                            })
                    } else {
                        setErrorMessage('Ha ocurrido un error al editar la habitación, intente otra vez')
                        setVariant('danger')
                        showAlert()
                    }
                }, (error) => {

                })
        }
    }

    const handleColorChange = (colorSelected, roomID) => {
        setRoomColor(colorSelected)
        let position = null
        colorByRoom.find((obj, pos) => {
            position = pos
            return obj.id === roomID
        })
        const list = [...colorByRoom]
        list[position]['color'] = colorSelected
        setColorsByRooms(list)
    }

    const colorsRoomsSection = () => {
        return colorByRoom ? colorByRoom.map(({id, color, isSuite, name}) => {
            return <li style={id === roomId ? {textDecoration: "underline"} : null}
                       className="list-unstyled list-inline-item ml-2 mr-3 mb-2">
                <Icon.DoorOpenFill color={color ? color : 'currentColor'} size={20}
                                   className="mr-1"/>
                {name + (parseInt(isSuite) === 1
                    ? ' (Suite)'
                    : '')}
            </li>
        }) : <Loader style={{width: 100 + "%"}}/>
    }

    return (
        <LayoutOne showIcon={false} showNavBar={false} bG={false}
                   title={
                       <CommonTitle goTo={() => history.go(-1)} title={hostalName}/>
                   }>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <h4>Editar habitaci&oacute;n</h4>
                </div>
                <div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formRoomName">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control onChange={e => setRoomName(e.target.value)} value={roomName} type="text"/>
                        </Form.Group>
                        <Form.Group controlId="formRoom">
                            <Form.Label>Tipo</Form.Label>
                            <Select
                                isSearchable={false}
                                styles={customStyles.react_select_style.border_left}
                                value={roomType}
                                onChange={e => setRoomType({
                                    value: e.value, label: e.label
                                })}
                                options={[
                                    {value: 0, label: 'Habitación normal'},
                                    {value: 1, label: 'Suite'}
                                ]}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGuests">
                            <Form.Label>Cantidad de hu&eacute;spedes</Form.Label>
                            <Select
                                isSearchable={false}
                                styles={customStyles.react_select_style.border_left}
                                value={guests}
                                onChange={e => setGuests({
                                    value: e.value, label: e.label
                                })}
                                options={
                                    Array.from(Array(10), (e, i) => {
                                        return {
                                            value: i + 1,
                                            label: (i + 1) + ' huésped' + (i > 0 ? 'es' : '')
                                        }
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="formBeds">
                            <Form.Label>Camas</Form.Label>
                            {
                                bedTypes === null
                                    ? <Loader style={{width: 100 + "%"}}/>
                                    :
                                    <div style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: 0.25 + 'rem',
                                        padding: 1 + 'rem'
                                    }}>
                                        {bedsList.map((x, i) => {
                                            return (
                                                <div style={{marginBottom: 15 + 'px'}} className="box">
                                                    <div className="btn-box">
                                                        <label className="font-weight-bold">Cama {i + 1}</label>
                                                        {(bedsList.length !== 1 && i > 0) && <li
                                                            className="text-info list-unstyled pb-2 float-right"
                                                            onClick={() => handleRemoveClick(i)}><Icon.Trash
                                                            size={18} className="text-danger"
                                                            style={{cursor: 'pointer'}}/>
                                                        </li>}
                                                    </div>
                                                    {
                                                        bedTypes.map(({id, name, imageOver}) => {
                                                            return (
                                                                <label className="bedType">
                                                                    <input onChange={e => handleRadioChange(e, i)}
                                                                           defaultChecked={parseInt(id) === parseInt(x.type)}
                                                                           name={"beds_types_for_bed_" + i} type="radio"
                                                                           value={id}
                                                                           id={"bed_" + i + "_type_" + id}
                                                                    />
                                                                    <span style={{
                                                                        border: '2px solid #66cc99',
                                                                        marginRight: 4,
                                                                        borderRadius: 0.25 + 'rem'
                                                                    }}>
                                                                   <span className="radioLabel">{name}</span>
                                                                    <Image width={96} height={96}
                                                                           src={apiUri.functions.root + imageOver}
                                                                           className="radioImg mx-1"/>
                                                               </span>
                                                                </label>

                                                            )
                                                        })
                                                    }
                                                    <hr/>
                                                    <div className="btn-box">
                                                        {bedsList.length - 1 === i &&
                                                        <li className="text-info list-unstyled link-li"
                                                            onClick={() => handleAddClick(i)}><Icon.PlusCircle size={18}
                                                                                                               className="text-muted"/><span
                                                            className="pl-1">Añadir cama {i + 2}</span>
                                                        </li>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                            }
                        </Form.Group>
                        <Form.Group controlId="formCualities">
                            <Form.Label>Cualidades</Form.Label>
                            {
                                cualities === null
                                    ? <Loader style={{width: 100 + "%"}}/>
                                    :
                                    <div style={{
                                        border: '1px solid #ced4da',
                                        borderRadius: 0.25 + 'rem',
                                        padding: 1 + 'rem'
                                    }}>
                                        <label>Qu&eacute; hace esta habitaci&oacute;n diferente a las
                                            dem&aacute;s?</label>
                                        <label>Seleccione la opci&oacute;n que m&aacute;s destaque.</label>
                                        <Form.Check defaultChecked={
                                            defaultCualit.id === roomCualities.id
                                        } value={defaultCualit.id} type="radio"
                                                    onChange={e => handleQualities(defaultCualit.id, defaultCualit.name)}
                                                    className="custom_radio"
                                                    id={"qualits_" + defaultCualit.id}
                                                    label={defaultCualit.name} name="qualities"/>
                                        {cualities.map(({id, name}) => {
                                            return (
                                                <Form.Check defaultChecked={
                                                    id === roomCualities.id
                                                } value={id} type="radio"
                                                            onChange={e => handleQualities(id, name)}
                                                            className="custom_radio" id={"qualits_" + id}
                                                            label={name} name="qualities"/>
                                            )
                                        })}
                                    </div>
                            }
                        </Form.Group>
                        {
                            showColor ? <Form.Group>
                                <Form.Label>Colores</Form.Label>
                                {
                                    colors
                                        ? <div style={{
                                            border: '1px solid #ced4da',
                                            borderRadius: 0.25 + 'rem',
                                            padding: 1 + 'rem'
                                        }}>
                                            <label>Selecciona color para esta habitaci&oacute;n.</label>
                                            <label>Usaremos este color para identificarla en el calendario.</label><br/>
                                            {
                                                colors.map(({color}, i) => {
                                                    return (
                                                        <label className="room-color">
                                                            <input
                                                                onChange={() => handleColorChange(color, roomId)}
                                                                defaultChecked={color === roomColor}
                                                                name={"color_for_room_" + roomId} type="radio"
                                                                value={color}
                                                                id={"room_" + roomId + "_color_" + i}
                                                            />
                                                            <span style={{marginRight: 4}}>
                                                                    <div style={{
                                                                        width: 70,
                                                                        height: 70,
                                                                        backgroundColor: color,
                                                                        borderRadius: 0.25 + 'rem'
                                                                    }}
                                                                         className="mx-1"/>
                                                               </span>
                                                        </label>
                                                    )
                                                })
                                            }
                                            {
                                                <ul className="mt-2 list-inline">
                                                    {colorsRoomsSection()}
                                                </ul>
                                            }
                                        </div>
                                        : <Loader style={{width: 100 + "%"}}/>
                                }
                            </Form.Group> : null
                        }

                        <div className="row fixed-bottom justify-content-center" style={{bottom: 5 + 'rem'}}>
                            <Alert variant={variant} show={show}>
                                {errorMessage}
                            </Alert>
                        </div>
                        {/*<div className="row fixed-bottom justify-content-center pb-4"*/}
                        {/*     style={{backgroundColor: 'white'}}>*/}
                        {/*    <Button variant="primary" type="submit" size="lg">*/}
                        {/*        Guardar*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <div className="submit-button-align-room">
                            <Button variant="primary" type="submit" size="lg">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </LayoutOne>
    );

    function showAlert() {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }
}

export default EditRoom